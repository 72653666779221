import React, { useEffect } from "react";
import logger from "../Utilities/Logger";

const ProjectModelForm = ({ register, errors, listeModeles, typeTiers, setValue, watch }) => {
  const modelesFiltres = listeModeles.filter((modele) => modele.cible === typeTiers);
  logger.log("ProjectModelForm: 🔍 Liste des modèles filtrés :", modelesFiltres);

  // Vérifie que `watch` est bien défini avant de l'utiliser
  const modeleIdSelectionne = watch ? watch("modeleId") : "";
  logger.log("ProjectModelForm: 🔍 Modèle sélectionné ID :", modeleIdSelectionne);

  // 🔹 Trouver le modèle correspondant dans `modelesFiltres`
  const modeleSelectionne = modelesFiltres.find((modele) => modele._id === modeleIdSelectionne);
  const nomModeleSelectionne = modeleSelectionne ? modeleSelectionne.nom : "Sélectionnez un modèle";
  logger.log("ProjectModelForm: ✅ Nom du modèle sélectionné :", nomModeleSelectionne);

  return (
    <div className="form-field">
      <label htmlFor="modeleId">Modèle de Projet</label>
      <select 
        {...register("modeleId", { required: "Veuillez choisir un modèle" })} 
        value={modeleIdSelectionne} // ✅ Synchronise la valeur avec `watch`
        onChange={(e) => setValue("modeleId", e.target.value)} // ✅ Permet la sélection manuelle
      >
        <option value="">{nomModeleSelectionne}</option> {/* ✅ Affiche le nom du modèle sélectionné */}
        {modelesFiltres.map((modele) => (
          <option key={modele._id} value={modele._id}>
            {modele.nom}
          </option>
        ))}
      </select>
      {errors.modeleId && <p className="errorMessage">{errors.modeleId.message}</p>}
    </div>
  );
};

export default ProjectModelForm;