import customAxios from "./getAxiosToken";
import useGetModeles from "./GetModeles";
import { useGetTiers } from "./GetTiers";

export const useCreateNewProjet = () => {
  const { fetchEtapesFromModeleId } = useGetModeles();
  const { getNameTiersByTiersId } = useGetTiers();

  const createNewProjet = async (
    modeleId,
    typeTiers,
    tiersId,
    userId,
    descriptionProjet,
    accesProjet
  ) => {
    try {
      const nomTemp = (await getNameTiersByTiersId(tiersId)).toUpperCase();
      console.log("🔍 createNewProjet: Nom temporaire récupéré:", nomTemp);

      const proprieteProjetCommune = {
        createur: userId,
        accesProjet: accesProjet,
        etat: "projetOuvert",
        modele: modeleId,
        isValid: true,
        tiers: [tiersId],
        description: descriptionProjet,
        echeancier: [],
        previsionnel: [],
      };

      // 🔎 Récupérer tous les projets existants du même tiers
      // const { data: projetsDuTiers } = await customAxios.get(`/api/projets?tiers=${tiersId}`);

      const { data: projetsDuTiers } = await customAxios.get(
        `/api/projets/All/${tiersId}/true/${userId}/ouvert`
      );
      console.log("📌 createNewProjet: Projets existants pour ce tiers:", projetsDuTiers);

      let nomProjet = nomTemp;
      let index = 1;
      let nomCandidat = nomProjet;

      // 🔄 Vérifie si un projet avec ce nom existe déjà et ajoute un numéro si nécessaire
      while (projetsDuTiers.projects.some(projet => projet.nom.toUpperCase() === nomCandidat)) {
      // while (projetsDuTiers.some((projet) => projet.nom.toUpperCase() === nomCandidat)) {
        index++;
        nomCandidat = `${nomProjet} ${index}`;
      }

      nomProjet = nomCandidat;
      console.log(`✅ createNewProjet: Nom de projet final: ${nomProjet}`);

      // ✅ Créer le projet
      const nouveauProjet = { nom: nomProjet, ...proprieteProjetCommune };
      console.log("📌 createNewProjet: Requête envoyée à /api/projets :", JSON.stringify(nouveauProjet, null, 2));
      const { data: projetCree } = await customAxios.post(`/api/projets`, nouveauProjet);
      console.log("✅ createNewProjet: Projet créé, réponse API :", projetCree);

      if (!projetCree || !projetCree._id) {
        console.error("🚨 Erreur : Le projet n'a pas été créé !");
        return null;
      }

      // ✅ Récupérer les étapes associées au modèle
      const etapes = await fetchEtapesFromModeleId(modeleId);
      console.log("🔄 createNewProjet: Étapes récupérées pour le modèle:", etapes);

      if (Array.isArray(etapes)) {
        const etapesTransformees = etapes.map((idEtape) => ({
          projet: projetCree?._id || null, // Vérifie que le projet a bien un ID
          modeleEtape: idEtape,
          etat: "enCours",
          actionDestinataires: typeTiers !== "CLIENT" && tiersId
            ? [{ tiers: tiersId, etat: "enAttente" }]
            : [],
        }));

        console.log("📌 createNewProjet: Étapes formatées:", etapesTransformees);

        // ✅ Ajouter les étapes à la base de données
        await customAxios.post(`/api/etapes/batch`, etapesTransformees);
        console.log("✅ createNewProjet: Étapes ajoutées avec succès.");
      } else {
        console.error("🚨 Erreur : les étapes ne sont pas sous forme de tableau.");
      }

      return projetCree;
    } catch (error) {
      console.error("🚨 createNewProjet: Erreur lors de la création du projet:", error);
      return null;
    }
  };

  return { createNewProjet };
};