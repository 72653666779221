import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import logger from "../Utilities/Logger";

export const SelectUtilisateurByTiersId = ({ onSelect }) => {
  const { tiersId, handleLogout } = useContext(AuthContext);

  const [utilisateurs, setUtilisateurs] = useState([]); // Liste complète des utilisateurs
  const [filteredUtilisateurs, setFilteredUtilisateurs] = useState([]); // Liste filtrée
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // Texte tapé dans le champ de saisie
  const [selectedUtilisateur, setSelectedUtilisateur] = useState(""); // ID de l'utilisateur sélectionné
  const [showDropdown, setShowDropdown] = useState(false); // Gère la visibilité du menu déroulant

  useEffect(() => {
    const fetchUtilisateurs = async () => {
      try {
        if (tiersId) {
          const responseTiers = await customAxios.get(`/api/tiers/${tiersId}`);
          const accesTiers = responseTiers.data.accesTiers;

          if (accesTiers && accesTiers.length > 0) {
            const utilisateursOuverts = [];

            for (const acces of accesTiers) {
              if (acces.etat === "ouvert") {
                const utilisateurResponse = await customAxios.get(
                  `/api/utilisateurs/${acces.utilisateur}`
                );
                utilisateursOuverts.push(utilisateurResponse.data);
              }
            }

            logger.log("SelectUtilisateurByTiersId: utilisateursOuverts", utilisateursOuverts);
            setUtilisateurs(utilisateursOuverts);
            setFilteredUtilisateurs(utilisateursOuverts); // Initialiser la liste filtrée
          }
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response?.status === 401) {
          handleLogout();
        } else {
          console.error("Erreur lors de la récupération des utilisateurs :", error);
        }
      }
    };

    fetchUtilisateurs();
  }, [tiersId, handleLogout]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setShowDropdown(true); // Ouvre le menu déroulant lorsqu'on tape dans le champ

    // Filtrer les utilisateurs en fonction du texte saisi
    const filtered = utilisateurs.filter((u) =>
      `${u.prenom} ${u.nom}`.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredUtilisateurs(filtered);
  };

  const handleSelectUtilisateur = (utilisateur) => {
    setSearchTerm(`${utilisateur.prenom} ${utilisateur.nom}`); // Afficher le nom dans le champ de saisie
    setSelectedUtilisateur(utilisateur.id); // Mettre à jour l'utilisateur sélectionné
    setShowDropdown(false); // Ferme le menu déroulant

    // Remonter les informations complètes au parent
    if (onSelect) {
      onSelect(utilisateur);
    }
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {isLoading ? (
        <div>Chargement des utilisateurs...</div>
      ) : (
        <>
          {/* Champ de saisie */}
          <input
            type="text"
            placeholder="Rechercher un utilisateur..."
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={() => setShowDropdown(true)} // Affiche le menu lors du focus
            style={{
              padding: "5px",
              width: "100%",
              boxSizing: "border-box",
            }}
          />

          {/* Liste filtrée */}
          {showDropdown && (
            <>
              {filteredUtilisateurs.length > 0 ? (
                <ul
                  style={{
                    listStyleType: "none",
                    margin: 0,
                    padding: 0,
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    right: 0,
                    maxHeight: "200px",
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    zIndex: 1000,
                  }}
                >
               {filteredUtilisateurs.map((u, index) => (
  <li
    key={u.id || `${u.nom}-${u.prenom}-${index}`} // Combinez plusieurs valeurs si nécessaire
    onClick={() => handleSelectUtilisateur(u)}
    style={{
      padding: "10px",
      cursor: "pointer",
      borderBottom: "1px solid #eee",
    }}
  >
    {u.prenom} {u.nom} {u.agence}
  </li>
))}

                </ul>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    right: 0,
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    padding: "10px",
                    zIndex: 1000,
                    textAlign: "center",
                    color: "#666",
                  }}
                >
                  Aucun résultat trouvé
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
