import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";

import { AuthContext } from "../../contexts/AuthProvider";
import { fetchEnsembleAgences } from "../Utilities/utilities";
import customAxios from "../Utilities/getAxiosToken";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import logger from "../Utilities/Logger";

// On récupère tous les projets CLIENT de l'Agence
// A partir des modèles de projets CLIENT de l'Agence

export function ListeAllProjets(props) {
  const { cibleProjets } = props; // CLIENT ou PARTENAIRE
  const [projets, setListeProjets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // État par défaut pour "ouvert"
  const [etatProjet, setEtatProjet] = useState("projetOuvert");
  const [isToggled, setIsToggled] = useState(true); // Toggle pour "actif" ou "archivé"
  const [selectedProjectId, setSelectedProjectId] = useState(null); // État pour suivre le projet sélectionné
  const [hoveredProjetId, setHoveredProjetId] = useState(""); // Initialise à une chaîne vide

  // Fonction de toggle
  const handleToggleChange = () => {
    const nouvelEtat = isToggled ? "projetArchive" : "projetOuvert";
    setIsToggled(!isToggled);
    setEtatProjet(nouvelEtat); // Met à jour l'état local des projets
  };

  const { userId, handleProjetId, agenceId, searchTerm } =
    useContext(AuthContext);

  const handleLabelClick = (projetId) => {
    setSelectedProjectId(projetId);
    logger.log("ListeAllProjets handleLabelClick");
    handleProjetId(projetId);
    logger.log("ListeAllProjets handleLabelClick projetId", projetId);
    logger.log("ListeAllProjets handleLabelClick etapeId NULL");
    // handleEtapeId(null);
  };

  const fetchProjetsAll = useCallback(async () => {
    try {
      // Début du chargement
      setIsLoading(true);

      // Appel à la nouvelle API pour récupérer les projets de l'agence
      logger.log(
        "ListeAllProjets: fetchProjetsAll agenceId",
        agenceId,
        etatProjet
      );
      const response = await customAxios.get(
        `/api/projets/projetsByAgence/${agenceId}/${cibleProjets}/${etatProjet}`
        
      );
      const projetsListe = response.data;

      // Vérification des projets récupérés
      if (projetsListe && projetsListe.length > 0) {
        logger.log("ListeAllProjets - Projets récupérés:", projetsListe);
        setListeProjets(projetsListe); // Mise à jour de l'état avec les projets
      } else {
        console.warn("Aucun projet trouvé pour l'agence spécifiée.");
        setListeProjets([]); // Réinitialiser si aucun projet n'est trouvé
      }
    } catch (error) {
      console.error(
        "ListeAllProjets: fetchProjetsAll: Erreur lors de la récupération des projets :",
        error
      );
      setListeProjets([]); // Réinitialiser en cas d'erreur
    } finally {
      // Fin du chargement
      setIsLoading(false);
    }
  }, [agenceId, etatProjet]);

  useEffect(() => {
    logger.log("ListeAllProjets: useEffect DEBUT etat", etatProjet);
    fetchProjetsAll();
  }, [userId, etatProjet]);

  // Filtrer les projets en fonction de l'état et de la recherche

  const filteredProjets = useMemo(() => {
    // Filtrer les projets par état et par nom
    const projetsFiltrés = projets.filter((projet) =>
      projet.nomProjet.toLowerCase().includes(searchTerm.toLowerCase())
    ); // Filtrer par recherche

    // Trier les projets par nom
    return projetsFiltrés.sort((a, b) =>
      a.nomProjet.localeCompare(b.nomProjet)
    );
  }, [projets, etatProjet, searchTerm]);

  const handleCheckClick = async (projetId, etatActuel) => {
    try {
      // L'état du projet doit être lié au userId
      const nouvelEtat = etatActuel === "enCours" ? "enArchive" : "enCours";
      logger.log("ListeAllProjets: handleCheckClick etatActuel", etatActuel);
      // Mettez à jour l'état du projet sur le serveur
      const response = await customAxios.put(`/api/projets/${projetId}`, {
        etatAccessProjet: nouvelEtat,
      });

      if (response.status === 200) {
        // Retirez le projet de la liste des projets en cours ou archivés en fonction de son état actuel
        setListeProjets((prevProjets) =>
          prevProjets.filter((projet) => projet._id !== projetId)
        );

        handleProjetId(null);
      } else {
        console.error("ListeAllProjets: La mise à jour du projet a échoué.");
      }
    } catch (error) {
      console.error(
        "ListeAllProjets: Erreur lors de la mise à jour du projet:",
        error
      );
    }
  };

  const ToggleSwitch = () => (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isToggled}
        onChange={handleToggleChange}
      />
      <span className="switch-slider round">
        <span className="switch-state">
          {isToggled ? "Actifs" : "En Archive"}
        </span>
      </span>
    </label>
  );

  return (
    <div className="fenetre-liste-projets">
      <div
        className="titre-zone2"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "90%",
        }}
      >
        <h6>ALL PROJETS</h6>
        <ToggleSwitch />
      </div>

      <div className="cadre-zone2">
        {filteredProjets.length > 0 ? (
          <ul className="liste-sans-puces">
            {filteredProjets.map((projet, index) => (
              <li key={projet.projetId}>
                <div className="form-check d-flex ms-2 me-2">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-${projet._id}`}>
                        {"Cliquer sur le projet"}
                      </Tooltip>
                    }
                  >
                    <span>
                          <label
                          className={`form-check-label souris-pointeur ${
                            hoveredProjetId === projet.projetId || selectedProjectId === projet.projetId
                              ? "label-semi-gras"
                              : ""
                          }`}
                          htmlFor={`flexCheckDefault${projet.projetId}`}
                          onClick={(e) => handleLabelClick(projet.projetId)}
                          onMouseEnter={() => setHoveredProjetId(projet.projetId)}
                          onMouseLeave={() => setHoveredProjetId("")}
                        >
                        {projet.nomProjet} ({projet.modele})
                      </label>
                    </span>
                  </OverlayTrigger>

                  
                </div>
              </li>
            ))}
          </ul>
        ) : isLoading ? (
          <div>Chargement en cours...</div>
        ) : (
          <div>Pas de projets à afficher.</div>
        )}
      </div>
    </div>
  );
}
