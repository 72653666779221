import axios from "axios";
import Cookies from "js-cookie";
import logger from "./Logger";

const customAxios = axios.create({
  withCredentials: true, // Nécessaire pour envoyer les cookies avec les requêtes
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

// Intercepteur pour ajouter dynamiquement le token à chaque requête
customAxios.interceptors.request.use(
  (config) => {
    const userTokenAcces = Cookies.get("userTokenAcces");
    if (userTokenAcces) {
      config.headers.Authorization = `Bearer ${userTokenAcces}`;
    } else {
      logger.warn("customAxios: Aucun token trouvé dans les cookies.");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default customAxios;