import React, { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import customAxios from "../Utilities/getAxiosToken";
import { AuthContext } from "../../contexts/AuthProvider";
import { fakerFR as faker } from "@faker-js/faker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function CreerUtilisateur() {
  const { handleAddNewUser, tiersId } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      role: "Utilisateur",
      isActif: true,
      favori: false,
      civilite: "Mr"
    }
  });

  const emailValue = watch("email");

  useEffect(() => {
    setValue("login", emailValue);
  }, [emailValue, setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "reseauxSociaux",
  });



  const onSubmit = async (data) => {
    const formData = {
      ...data,
      password: data.mdp,
      tiersId,
    };
    delete formData.mdp;
  
    try {
      console.log("Utilisateur à créer :", formData);
      const response = await customAxios.post(`/api/utilisateurs`, formData);
      console.log("Utilisateur créé avec succès :", response.data);
      toast.success("Utilisateur créé avec succès.");
      handleAddNewUser(false); // Ferme le formulaire après création
    } catch (error) {
      console.error("Erreur lors de la création de l'utilisateur :", error);
  
      if (error.response?.status === 409) {
        // Erreur spécifique pour email déjà utilisé
        toast.error("Cet email est déjà utilisé. Veuillez en choisir un autre.");
      } else {
        // Erreur générique
        toast.error(error.response?.data?.message || "Une erreur s'est produite.");
      }

    }
  };

  const handleCancel = () => {
    handleAddNewUser(false);
  };

  const handleInsertFakeData = () => {
    const fakeUser = {
      civilite: faker.helpers.arrayElement(["Mr", "Mme"]),
      prenom: faker.person.firstName(),
      nom: faker.person.lastName(),
      telephone: faker.phone.number("06########"),
      email: faker.internet.email(),
      mdp: faker.internet.password(8),
      adresse: faker.location.streetAddress(),
      cp: faker.location.zipCode(),
      ville: faker.location.city(),
      dateNaissance: faker.date.past(30, new Date("2000-01-01")).toISOString().split("T")[0],
    };

    Object.keys(fakeUser).forEach((key) => setValue(key, fakeUser[key]));

    append({ type: "Twitter", url: faker.internet.url() });
    append({ type: "LinkedIn", url: faker.internet.url() });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-utilisateur">
      <h5>Créer un nouvel utilisateur</h5>

      <div className="form-group">
        <label>Civilité</label>
        <select {...register("civilite", { required: "La civilité est requise." })}>
          <option value="Mr">Mr</option>
          <option value="Mme">Mme</option>
        </select>
        {errors.civilite && <p className="error">{errors.civilite.message}</p>}
      </div>

      <div className="form-group">
        <label>Prénom</label>
        <input
          {...register("prenom", { required: "Le prénom est requis.", minLength: 2 })}
        />
        {errors.prenom && <p className="error">{errors.prenom.message}</p>}
      </div>

      <div className="form-group">
        <label>Nom</label>
        <input
          {...register("nom", { required: "Le nom est requis.", minLength: 2 })}
        />
        {errors.nom && <p className="error">{errors.nom.message}</p>}
      </div>

      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          {...register("email", { required: "L'email est requis." })}
        />
        {errors.email && <p className="error">{errors.email.message}</p>}
      </div>



      <div className="form-group">
        <label>Mot de passe</label>
        <input
          type="password"
          {...register("mdp", { required: "Un mot de passe est requis.", minLength: 8 })}
        />
        {errors.mdp && <p className="error">{errors.mdp.message}</p>}
      </div>

      <div className="form-group">
        <label>Téléphone</label>
        <input {...register("telephone")} />
      </div>

      <div className="form-group">
        <label>Adresse</label>
        <input {...register("adresse")} />
      </div>

      <div className="form-group">
        <label>Code Postal</label>
        <input {...register("cp")} />
      </div>

      <div className="form-group">
        <label>Ville</label>
        <input {...register("ville")} />
      </div>

      <div className="form-group">
        <label>Date de naissance</label>
        <input type="date" {...register("dateNaissance")} />
      </div>

      <div className="form-group">
        <label>Réseaux Sociaux</label>
        {fields.map((item, index) => (
          <div key={item.id}>
            <input
              {...register(`reseauxSociaux.${index}.type`)}
              placeholder="Type de réseau"
            />
            <input
              {...register(`reseauxSociaux.${index}.url`)}
              placeholder="URL"
            />
            <button type="button" onClick={() => remove(index)}>Supprimer</button>
          </div>
        ))}
        <button type="button" onClick={() => append({ type: "", url: "" })}>Ajouter un réseau social</button>
      </div>

      <div className="action-buttons" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}> 
        <button type="button" className="btn btn-primary" onClick={handleCancel}>Annuler</button>
        <button type="submit" className="btn btn-primary">Créer Utilisateur</button>
        <button type="button" className="btn btn-primary" onClick={handleInsertFakeData}>Insérer des données fictives</button>
      </div>
    </form>
  );
}