import React, { useState, useContext, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { BsCloudDownload } from "react-icons/bs";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import Modal from "../Modal/Modal";
import logger from "../Utilities/Logger";
import "./css/BoutonDownload.css";


const BoutonDownLoad = ({ etape, style }) => {
  const { userId, userTiersId, isAgence } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedTiersId, setSelectedTiersId] = useState(null);
  const [typeTiers, setTypeTiers] = useState("");
  const [filesPendingUpload, setFilesPendingUpload] = useState([]); // Stocker les fichiers en attente d'upload

  const [isBlinking, setIsBlinking] = useState(false);
  // logger.log("BoutonDownLoad etape", etape);


const fetchTypeTiers = useCallback(async () => {
  try {
    const response = await customAxios.get(`/api/tiers/${userTiersId}`);
    setTypeTiers(response.data.typeTiers);
  } catch (error) {
    logger.error("Erreur lors de la récupération des données initiales :", error);
  } 
}, [userTiersId]);



  useEffect(() => {
    fetchTypeTiers();
  }, [fetchTypeTiers]);

  // Prépare les métadonnées des fichiers
  // logger.log("BoutonDownLoad etape.ownerProjetTiersId", etape.ownerProjetTiersId);
  

  // Prépare les métadonnées des fichiers pour l'upload
  const prepareMetadata = (file, uploadDate, destinataireId) => {
    const ownerProjetTiersId = Array.isArray(etape.ownerProjetTiersId) && etape.ownerProjetTiersId.length > 0
    ? etape.ownerProjetTiersId[0] // Prend la première chaîne du tableau
    : null; // Valeur par défaut si le tableau est vide ou inexistant
    

    return {
      depositUserId: userId,
      ownerFileTiersId: destinataireId || selectedTiersId, // TiersId du destinataire
      etapeId: etape.etapeId,
      projetId: etape.projetId,       // id du Projet
      ownerProjetTiersId,             // id du Tiers propriétaire du Projet  
      actionId: etape.actionId,
      typeEtape: etape.typeEtape,
      rubriqueEtape: etape.rubrique,  // Rubrique de l'étape
      prefixeEtape: etape.prefixe,    // Préfixe de l'étape
      uploadDate,                     // Date d'upload
      lastReadDate: [],
      // accesFileClient: typeTiers === "CLIENT",
      accesFileClient: false,
      originalFileName: file.name,
      accesFichier: []              // Liste des Tiers ayant accès au fichier, { tiersId: destinataireId, etat: "non lu" ou "lu" }
    };
  };



  // Gère l'upload des fichiers
  const handleFileUpload = async (file, metadata) => {
    const prefixedFileName = `${metadata.rubriqueEtape}_${formatDateForFileName(
      metadata.uploadDate
    )}`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", prefixedFileName);
    formData.append("metadata", JSON.stringify(metadata));

    try {
      const response = await customAxios.post(`/api/files/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      logger.log("Fichier uploadé avec succès :", response.data);
    } catch (error) {
      logger.error("Erreur lors de l'upload du fichier :", error);
      alert("Erreur lors de l'upload du fichier.");
    }
  };

  // Gestion du dépôt de fichiers
  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsBlinking(true); // Déclencher le clignotement
      setTimeout(() => setIsBlinking(false), 1000); // Arrêter après 1 seconde
  
      // (Votre logique existante pour gérer les fichiers déposés)
      if (typeTiers === "AGENCE" || typeTiers === "CLIENT") {
        const destinataires = etape.destinatairesEtape || [];
        if (destinataires.length === 1) {
          const destinataireId = destinataires[0].tiers;
          acceptedFiles.forEach((file) => {
            const uploadDate = new Date();
            const metadata = prepareMetadata(file, uploadDate, destinataireId);
            // logger.log("BoutonDownLoad metadata", metadata);
            handleFileUpload(file, metadata);
          });
        } else if (destinataires.length > 1) {
          setFilesPendingUpload(acceptedFiles);
          setShowModal(true);
        } else {
          alert("Aucun destinataire trouvé.");
        }
      } else {
        acceptedFiles.forEach((file) => {
          const uploadDate = new Date();
          const metadata = prepareMetadata(file, uploadDate, userTiersId);
          // logger.log("BoutonDownLoad metadata", metadata);
          handleFileUpload(file, metadata);
        });
      }
    },
    [typeTiers, etape.destinatairesEtape, userTiersId, prepareMetadata]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Gestion du clic sur "Confirmer" dans le modal
  const handleModalConfirm = () => {
    if (!selectedTiersId) {
      alert("Veuillez sélectionner un destinataire.");
      return;
    }

    // Upload des fichiers en attente après sélection du destinataire
    filesPendingUpload.forEach((file) => {
      const uploadDate = new Date();
      const metadata = prepareMetadata(file, uploadDate, selectedTiersId);
      logger.log("BoutonDownLoad metadata", metadata);
      handleFileUpload(file, metadata);
    });

    setFilesPendingUpload([]); // Réinitialiser la liste des fichiers en attente
    setShowModal(false); // Fermer le modal
  };

  // Formate une date pour le nom de fichier
  const formatDateForFileName = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const hour = `${d.getHours()}`.padStart(2, "0");
    const minute = `${d.getMinutes()}`.padStart(2, "0");
    const second = `${d.getSeconds()}`.padStart(2, "0");
    return `${year}${month}${day}_${hour}:${minute}:${second}`;
  };



  const content = isAgence 
  ? <span></span>
  : isDragActive 
  ? <p>Déposez vos fichiers ici...</p>
  : <p>Glissez et déposez vos fichiers ici, ou cliquez pour sélectionner</p>;

  return (
    <>
     
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{
          overlay: {
            zIndex: 9999, // Assure que la fenêtre est au premier plan
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Ajoute un fond semi-transparent
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <h4>Sélectionner un destinataire</h4>
        <ul className="liste-sans-puces" style={{ margin: "20px" }}>
          {etape.destinatairesEtape?.map((dest, index) => (
            <li key={index}>
              <label style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  name="destinataire"
                  value={dest.tiers}
                  onChange={(e) => setSelectedTiersId(e.target.value)}
                  checked={selectedTiersId === dest.tiers}
                  style={{ marginRight: "10px" }}
                />
                {dest.nom}
              </label>
            </li>
          ))}
        </ul>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button className="btn" onClick={() => setShowModal(false)}>
            Annuler
          </button>
          <button className="btn" onClick={handleModalConfirm}>
            Confirmer
          </button>
        </div>
      </Modal>

      <div
  {...getRootProps()}
  className={`${isBlinking ? "blinking" : ""} ${isDragActive ? "drag-active" : ""}`}
  style={{
    border: typeTiers !== "AGENCE" ? "1px dashed gray" : "none",
    textAlign: "center",
    cursor: "pointer",
  }}


>
  <input {...getInputProps()} />
  {content}
  <BsCloudDownload style={style} />
</div>


    </>
  );
};

export default BoutonDownLoad;
