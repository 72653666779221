import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthProvider";
import { useProjet } from "../../contexts/ProjetContext";
import {
  FormHeader,
  SirenField,
  SubmitButton,
} from "./CreateNewTiersComponents";
import ContactInfoForm from "./ContactInfoForm";
import CompanyInfoForm from "./CompanyInfoForm";
import ProjectModelForm from "./ProjectModelForm";
import { useTiersForm } from "../Utilities/TiersForm";
import { useFetchCategories } from "../Utilities/fetchCategories";
import useGetModeles from "../Utilities/GetModeles";
import { useValidateSiren } from "../Utilities/ValidateSiren";
import { fetchCompanyInfoBySiren } from "../Utilities/fetchCompanyInfo";
import "./css/CreateNewTiers.css";
import logger from "../Utilities/Logger";

export function CreateNewTiers({ typeTiers }) {
  const { userId, agenceId, closeCreateNewTiers } = useContext(AuthContext);
  const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();
  const { refreshProjects } = useProjet();
  const { listeModeles, fetchModeles } = useGetModeles();

  // ✅ Synchroniser `typeClient` avec react-hook-form
  const typeClient = watch("typeClient", typeTiers === "PARTENAIRE" ? "Entreprise" : "Particulier");
  const [isEntreprise, setIsEntreprise] = useState(false);
  const [isSirenValid, setIsSirenValid] = useState(false);

  const shouldShowDetails = isSirenValid || (typeTiers === "CLIENT" && typeClient === "Particulier");

  // ✅ Honeypot (sécurité anti-bot)
  const [honeypot, setHoneypot] = useState("");
  const handleHoneypotChange = (e) => setHoneypot(e.target.value);

  const { 
    sirenValid, 
    sirenExists, 
    validateSiren, 
    setSirenValid,  
    error, 
    setError, 
    setCompanyInfo 
  } = useValidateSiren(setValue, fetchCompanyInfoBySiren); // ✅ Ajout de `fetchCompanyInfoBySiren` en paramètre de l'appel

  const { categories, fetchCategories } = useFetchCategories();
  const { onSubmit, remplirChampsAvecDonneesFictives, isModified } = useTiersForm({
    agenceId,
    typeTiers, 
    userId, 
    setValue, 
    refreshProjects, 
    closeCreateNewTiers, 
    listeModeles, 
    fetchCompanyInfoBySiren, 
    setSirenValid, 
    setError, 
    setCompanyInfo,
    isClientParticulier: typeClient === "Particulier", 
    watch
  });


  useEffect(() => {
    setIsEntreprise(typeTiers === "PARTENAIRE" || (typeTiers === "CLIENT" && typeClient === "Entreprise"));
  }, [typeTiers, typeClient]);


  useEffect(() => {
    if (typeTiers === "PARTENAIRE") {
      setValue("typeClient", "Entreprise");
    } else if (typeTiers === "CLIENT" && !watch("typeClient")) {
      setValue("typeClient", "Particulier"); // ✅ Ne change que si non défini
    }
  }, [typeTiers, setValue, watch]);

  
  useEffect(() => {
    reset();
    fetchModeles(agenceId);
    if (typeTiers === "AGENCE") fetchCategories();
  }, [typeTiers]);

  useEffect(() => {
    const siren = watch("siren");
    if (siren && siren.length === 9) {
      const timer = setTimeout(() => validateSiren(siren), 200);
      return () => clearTimeout(timer);
    }
  }, [watch("siren")]);

  useEffect(() => {
    setIsSirenValid(sirenValid && !sirenExists);
  }, [sirenValid, sirenExists]);



  return (
    <div className="titre-zone2">
      <FormHeader typeTiers={typeTiers} />

      <form 
        onSubmit={(event) => {
          console.log("✅ Formulaire soumis !");
          console.log("🚀 Erreurs du formulaire :", errors);

          // 🚨 Vérification du honeypot
          if (honeypot) {
            console.warn("🚨 Bot détecté ! Soumission bloquée.");
            return;
          }

          handleSubmit((data) => {
            console.log("📌 handleSubmit a validé les données :", data);
            onSubmit(data);
          })(event);
        }}
      >

        {/* ✅ Afficher uniquement le SIREN pour les entreprises AVANT les autres champs */}
        {isEntreprise && (
            <SirenField 
              register={register} 
              errors={errors} 
              sirenValid={sirenValid} 
              sirenExists={sirenExists} 
              watch={watch} 
              setValue={setValue} // ✅ Ajout de `setValue`
              onBlur={() => validateSiren(watch("siren"), setSirenValid, setError, setCompanyInfo, setValue)}
            />
          )}

        {/* ✅ Afficher les autres champs si le SIREN est valide OU si c'est un CLIENT */}
          {shouldShowDetails && (
          <>
            {/* ✅ Sélection Client Particulier ou Entreprise UNIQUEMENT pour un client */}

            {typeTiers === "CLIENT" && (
                <div className="form-field">
                  <label>Type de Client :</label>
                  <select 
                    {...register("typeClient")} 
                    onChange={(e) => setValue("typeClient", e.target.value)} // ✅ Mise à jour correcte
                    value={typeClient} // ✅ Garde la valeur correcte
                    disabled={typeTiers === "PARTENAIRE"} // ✅ Désactive si PARTENAIRE
                  >
                    <option value="Particulier">Particulier</option>
                    <option value="Entreprise">Entreprise</option>
                  </select>
                </div>
              )}


            {/* ✅ Afficher ContactInfoForm uniquement pour les CLIENTS PARTICULIERS */}
            {typeTiers === "CLIENT" && typeClient === "Particulier" && (
              <ContactInfoForm register={register} errors={errors}  />
            )}

            {/* ✅ Afficher CompanyInfoForm pour toutes les ENTREPRISES */}
            {isEntreprise && (
                <>
                  <CompanyInfoForm register={register} errors={errors} watch={watch} />

                  {/* ✅ Champs de contact pour Partenaires et Entreprises */}
                  <div className="form-field">
                    <label htmlFor="nomContact">Nom du Contact</label>
                    <input {...register("nomContact", { required: "Veuillez renseigner le nom du contact." })} type="text" placeholder="Nom du Contact" />
                    {errors.nomContact && <p className="errorMessage">{errors.nomContact.message}</p>}
                  </div>

                  <div className="form-field">
                    <label htmlFor="prenomContact">Prénom du Contact</label>
                    <input {...register("prenomContact", { required: "Veuillez renseigner le prénom du contact." })} type="text" placeholder="Prénom du Contact" />
                    {errors.prenomContact && <p className="errorMessage">{errors.prenomContact.message}</p>}
                  </div>

                  <div className="form-field">
                    <label htmlFor="emailContact">Email du Contact</label>
                    <input 
                      {...register("emailContact", { 
                        required: "Veuillez renseigner l'email du contact.",
                        pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Veuillez entrer un email valide." }
                      })} 
                      type="email" 
                      placeholder="Email du Contact" 
                    />
                    {errors.emailContact && <p className="errorMessage">{errors.emailContact.message}</p>}
                  </div>
                </>
              )}

            {/* ✅ Modèles de Projet (si disponibles) */}
            {listeModeles.length > 0 && <ProjectModelForm register={register} errors={errors} listeModeles={listeModeles} typeTiers={typeTiers} watch={watch}/>}

            {/* 🔒 Honeypot field for bot detection */}
            <input 
              type="text" 
              name="company" 
              value={honeypot} 
              onChange={handleHoneypotChange} 
              style={{ display: "none" }} 
              tabIndex="-1" 
              autoComplete="off" 
            />

            <SubmitButton isModified={isModified} typeTiers={typeTiers} watch={watch} />
          </>
        )}

        <div className="fake-data-button">
          <button type="button" onClick={remplirChampsAvecDonneesFictives} className="btn btn-primary">
            Remplir avec des Données Fictives
          </button>
        </div>
      </form>
    </div>
  );
}