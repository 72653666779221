import React from "react";
import PropTypes from "prop-types";


/**
 * UnreadFileCounter - Un composant pour compter les fichiers non lus.
 *
 * @param {Array} files - Liste des fichiers à analyser.
 * @param {string} userId - Identifiant de l'utilisateur actuel.
 * @returns {JSX.Element} - Nombre de fichiers non lus affiché.
 */
const UnreadFileCounter = ({ files, userId }) => {
  // Fonction pour compter les fichiers non lus
  const countUnreadFiles = (files) => {
    return files.filter(
      (file) =>
        !file.metadata.lastReadDate ||
        !file.metadata.lastReadDate.some(
          (entry) => entry.utilisateur === userId
        )
    ).length;
  };

  const unreadCount = countUnreadFiles(files);

  logger.log("UnreadFileCounter unreadCount", unreadCount);
  return (
    <span className="unread-counter">
      {unreadCount} fichier{unreadCount !== 1 ? "s" : ""} non lu{unreadCount !== 1 ? "s" : ""}
    </span>
  );
};

// Proptypes pour valider les props
UnreadFileCounter.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      metadata: PropTypes.shape({
        lastReadDate: PropTypes.arrayOf(
          PropTypes.shape({
            utilisateur: PropTypes.string.isRequired,
            dateLecture: PropTypes.string,
          })
        ),
      }).isRequired,
    }).isRequired
  ).isRequired,
  userId: PropTypes.string.isRequired,
};

export default UnreadFileCounter;