import React, { useContext, useEffect } from "react";

// Composants
import { ListeAllProjets } from "./Projets/ListeAllProjets";
import { ListeProjetsByUserId } from "./Projets/ListeProjetsByUserId";
import { ListeModeles } from "./Modeles/ListeModeles";
import { ListeTiersByUserIdAndTypeTiers } from "./Tiers/ListeTiersByUserIdAndTypeTiers";
import { ListeUtilisateurs } from "./Utilisateurs/ListeUtilisateurs";
import { GestionCorpsDeMétiers } from "./CorpsDeMétiers/GestionCorpsDeMétiers";
import { GestionActions } from "./Actions/GestionActions";

import { ListeAdminAgences } from "./Tiers/ListeAdminAgences";
import { ListeAdminPartenaires } from "./Tiers/ListeAdminPartenaires";

import { ListeCatégories } from "./Catégories/ListeCatégories";
import { CreateNewTiers } from "./Tiers/CreateNewTiers";
import { CreateNewModele } from "./Modeles/CreateNewModele";
import { ListeAttentesAgences } from "./Attentes/ListeAttentesAgences";
import { ListeAttentesPartenaires } from "./Attentes/ListeAttentesPartenaires";

import { ListeFichiersProjet } from "./Fichiers/ListeFichiersProjet";
import { ListeFichiersPartenaires } from "./Fichiers/ListeFichiersPartenaires";
import { ListeFichiersClient } from "./Fichiers/ListeFichiersClient";

import { ListeAllTiersByAgence } from "./Tiers/ListeAllTiersByAgence";
import { DépôtExpress } from "./Fichiers/DépôtExpress";
import {ListeRolesPermissions} from "./RolesPermissions/ListeRolesPermissions";
import { AuthContext } from "../contexts/AuthProvider";

import "./css/Global.css";
import "./css/Zone2.css";
import { RequêteExpress } from "./Fichiers/RequêteExpress";

export function Zone2() {
  const {
    selectedMenu,
    debugAffichage,
    userId,
    isManager,
    isAgence,
    isPrestataire,

  } = useContext(AuthContext);
  logger.log("Zone2 selectedMenu", selectedMenu);


  useEffect(() => {
    console.log("Zone2 monté");
    return () => {
      console.log("Zone2 démonté");
    };
  }, []);
  const renderContent = () => {
    // logger.log("Zone 2 renderContent", selectedMenu);
    switch (selectedMenu) {


      case "NEW PARTENAIRE":
        return userId && (isAgence || isPrestataire) ? (
          <CreateNewTiers typeTiers="PARTENAIRE" />
        ) : null;

      case "NEW PRESTATAIRE":
        return userId && isManager ? (
          <CreateNewTiers typeTiers="PRESTATAIRE" />
        ) : null;

      case "NEW AGENCE":
        return <CreateNewTiers typeTiers="AGENCE" /> ;

      case "NEW MODÈLE":
        return <CreateNewModele /> ;
 

      case "ATTENTES":
        return   <ListeAttentesPartenaires AllAgences={false} />;


      case "DÉPÔT EXPRESS":
        return <DépôtExpress />;

      case "REQUÊTES EXPRESS":     
          return <RequêteExpress />;


      // Affichage des attentes des Projets en Cours
      case "REQUÊTES": 
        return <ListeAttentesAgences AllAgences={false} />;

      case "ALL DOCUMENTS":
        return  <ListeFichiersProjet AllAgences={true} />;


      case "MES PROJETS":    
        return     <ListeFichiersPartenaires />;

      case "MES DOCUMENTS":    
        return     <ListeFichiersClient />;

      case "ALL PROJETS":  
        // On récupère tous les projets l'Agence
        return <ListeAllProjets cibleProjets={"CLIENT"} />;


      case "ALL CLIENTS":
        return <ListeAllTiersByAgence typeTiers="CLIENT"  titre="ALL CLIENTS" />;


        case "ALL PARTENAIRES":
          return <ListeAllTiersByAgence typeTiers="PARTENAIRE" titre="ALL PARTENAIRES"/>;


        case "ALL PRESTATAIRES":
          return <ListeAllTiersByAgence typeTiers="PRESTATAIRE" titre="ALL PRESTATAIRES"/>;

      case "ALL REQ":
        return <ListeAttentesAgences AllAgences={true} />;

      case "PROJETS CLIENTS":
        // On liste tous les projets en Cours de userId
        return <ListeProjetsByUserId cibleProjets={"CLIENT"}/>;

      case "PROJETS PARTENAIRES":
        // On liste tous les projets en Cours de userId
        return <ListeProjetsByUserId cibleProjets={"PARTENAIRE"}/>;

      case "PROJETS FOURNISSEURS":
        // On liste tous les projets en Cours de userId
        return <ListeProjetsByUserId cibleProjets={"FOURNISSEUR"}/>;

      case "PROJETS PRESTATAIRES":
          // On liste tous les projets en Cours de userId
          return <ListeProjetsByUserId cibleProjets={"PRESTATAIRE"}/>;

      case "PROJET AGENCE":
        // On liste tous les projets en Cours de userId
        return <ListeProjetsByUserId cibleProjets={"AGENCE"}/>;

      case "UTILISATEURS":
        return <ListeUtilisateurs />;

      case "SYNTHÈSES":
        // Affichage Complet ou Léger
        // Complet c'est pour le DashBoard
        // Léger c'est pour les Synthèses
        return <ListeModeles AllAgences={false} Affichage={"Léger"} />;

      case "ALL SYNTHÈSES":
        // Affichage Complet ou Léger
        return <ListeModeles AllAgences={true} Affichage={"Léger"} />;

      case "PARTENAIRES":
        return (
            <ListeTiersByUserIdAndTypeTiers
              typeTiers="PARTENAIRE"  titre="PARTENAIRES"
            />
        );


      case "CLIENTS":
        return (
          <>
            <ListeTiersByUserIdAndTypeTiers
              typeTiers="CLIENT" titre="CLIENTS"  
            />
          </>
        );


      case "ADMIN PARTENAIRES":
        return (
          <>
            <h5 className="titre-zone2">ALL PARTENAIRES</h5> 
            <ListeAdminPartenaires typeTiers="PARTENAIRE" />
          </>
        );
  

      case "ADMIN MÉTIERS":
        return <GestionCorpsDeMétiers />;

      case "ADMIN ACTIONS":
        return <GestionActions type="ADMINISTRATION" />;



      case "ADMIN CATÉGORIES":
        return (
          <ListeCatégories
            AllAgences={true}
          />
        );

      case "ADMIN AGENCES":
        return <ListeAdminAgences typeTiers="AGENCE" />;

      case "ADMIN GÉNÉRAL":
        return <ListeAdminAgences typeTiers="ADMINISTRATEUR" />;


      case "ADMIN MODÈLES":
        // Affichage Complet ou Léger
        return (
          <ListeModeles
            AllAgences={true}
            Affichage={"Complet"}
            ModeGestion={"modeAdministration"}
          />
        );

        // Gestion des Rôles et Permissions
        case "ADMIN RÔLES":
          return (
            <ListeRolesPermissions/>
          );

      default:
        return null;
    }
  };

  return (
    // <div className="zone-2 marge-zones cadre-zone2">

    <div className="zone-2">
      {/* Juste pour savoir où sont les zones */}
      {/* {debugAffichage && <h4 className="zone-title">Zone 2</h4>} */}
      {renderContent()}
    </div>
  );
}
