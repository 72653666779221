import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { useWebSocket } from "../../contexts/WebSocketProvider";

//<CountListeTiers typeTiers="PARTENAIRE" AllAgences={false} />
// typeTiers = "AGENCE" "PARTENAIRE", "PRESTATAIRE"
// AllAgences = true/false Compteur de tous les tiers rattaché au userId ou l'agence: donc tous les utilisateurs de l'agence
export function CountListeTiers(props) {
  const [countListeTiers, setCountListeTiers] = useState([]);
   // etatDuTiers = "ouvert" ou "archivé"  pour filtrer les tiers par utilisateur et par état
  const { typeTiers, AllAgences, context, etatDuTiers = "ouvert"  } = props;
  const { userId, agenceId, isVueManager } = useContext(AuthContext);

  const fetchListeTiers = useCallback(async () => {
    logger.log("CountListeTiers: fetchListeTiers AllAgences",AllAgences,"typeTiers",typeTiers);
        
    try {
      // const etat = "ouvert";

      if (AllAgences) {
        // On récupère tous les typeTiers de l'agenceId

        try {
          const response = await customAxios.post(
            `/api/tiers/getTiersByAgenceIdAndTypeTiers`,
            {
              agenceId: agenceId,
              typeTiers: typeTiers,
            }
          );
          logger.log("CountListeTiers: fetchListeTiers AllAgences",AllAgences,"typeTiers",typeTiers,"taille réponse", response.data.tiers.length, "response", response.data);
          setCountListeTiers(response.data.tiers.length);
        } catch (error) {
          console.error("Erreur lors de la récupération des tiers:", error);
        }
      
      } else {
        // Si AllAgences est à false alors On récupère les info sur le userId courant
        if (typeTiers && userId) {
          const response = await customAxios.get(
            `/api/tiers/count/${userId}/${typeTiers}?etatDuTiers=${etatDuTiers}`
          );

          logger.log("CountListeTiers: fetchListeTiers AllAgences",AllAgences, "typeTiers",typeTiers,"response", response.data);

          setCountListeTiers(response.data.nbTiers);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [userId, isVueManager, agenceId, typeTiers, AllAgences, etatDuTiers]);


   const {socket} = useWebSocket();

  // useEffect(() => {
  //   // Vérifie que le socket est disponible
  //   if (!socket) {
  //     logger.warn("CountListeTiers: Socket non initialisé.");
  //     return;
  //   }
  
  //   fetchListeTiers();
  
  //   // Gestion des mises à jour via WebSocket
  //   const handleUpdateTiers = (data) => {
  //     logger.log("CountListeTiers: WebSocket updateTiers reçu", data);
  //     fetchListeTiers();
  //   };
  
  //   socket.on("updateTiers", handleUpdateTiers);
  
  //   // Nettoyage des écouteurs à la fermeture du composant
  //   return () => {
  //     logger.log("CountListeTiers: Nettoyage des écouteurs WebSocket");
  //     socket.off("updateTiers", handleUpdateTiers);
  //   };
  // }, [socket, userId]);

  useEffect(() => {
    if (!socket || typeof socket.on !== "function") {
      logger.warn("CountListeTiers: Socket non initialisé ou invalide.");
      return;
    }
  
    fetchListeTiers();
  
    const handleUpdateTiers = (data) => {
      logger.log("CountListeTiers: WebSocket updateTiers reçu", data);
      fetchListeTiers();
    };
  
    socket.on("updateTiers", handleUpdateTiers);
  
    return () => {
      logger.log("CountListeTiers: Nettoyage des écouteurs WebSocket");
      socket.off("updateTiers", handleUpdateTiers);
    };
  }, [socket, fetchListeTiers]);

  return (
    <>
      {context === "bandeau" ? (
        <span>{countListeTiers}</span>
      ) : (
        <span className="badge badge-info compteur">{countListeTiers}</span>
      )}
    </>
  );
}
