import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeProjets.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider"; // Contexte pour récupérer agenceId
import customAxios from "../Utilities/getAxiosToken";

export default function CheckPreviEcheancierProjets(props) {
  const { AllAgences } = props; // ID de l'agence récupéré depuis les props
  const { agenceId, user } = useContext(AuthContext); // ID de l'agence récupéré depuis le contexte
  const [data, setData] = useState(null); // Données récupérées depuis l'API
  const [loading, setLoading] = useState(false); // Indicateur de chargement
  const [error, setError] = useState(null); // Gestion des erreurs
  const [toggle, setToggle] = useState(false); // Indicateur pour afficher/masquer les détails

  // Fonction pour récupérer les données depuis l'API
  useEffect(() => {
    const fetchAgenceData = async () => {
      if (!agenceId) {
        setError("Aucun ID d'agence n'est disponible.");
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await customAxios.get(
          `/api/projets/checkPreviEcheancier/${agenceId}`
        );
        let resultData = response.data;

        logger.log("CheckPreviEcheancierProjets resultData", resultData);
        // Filtrer les projets si AllAgences est false
        if (!AllAgences) {
          resultData = {
            ...resultData,
            modeles: resultData.modeles.map((modele) => ({
              ...modele,
              projets: modele.projets.filter((projet) =>
                projet.accesProjet?.some(
                  (acces) => acces.utilisateur === user.userId
                )
              ),
            })),
          };
        }

        setData(resultData); // Met à jour les données récupérées
      } catch (err) {
        console.error(err);
        setError("Erreur lors de la récupération des données.");
      } finally {
        setLoading(false);
      }
    };

    fetchAgenceData();
  }, [agenceId, user]);

  // Vérifie si des projets contiennent des erreurs
  const hasErrors = data?.modeles.some((modele) =>
    modele.projets.some((projet) => projet.manque.length > 0)
  );

  // Calcul du nombre total de projets en erreur
  const totalProjetsAvecErreurs = data
    ? data.modeles.reduce(
        (count, modele) =>
          count +
          modele.projets.filter((projet) => projet.manque.length > 0).length,
        0
      )
    : 0;

  // Rendu des projets
  const renderProjets = (projets) =>
    projets.map((projet, index) => (
      <li
        key={index}
        style={{
          listStyleType: "none", // Supprime les puces par défaut
          textAlign: "left", // Alignement à gauche
          borderRadius: "5px", // Arrondi des coins
          padding: "0px", // Espacement interne
        }}
      >
        <span
          style={{
            display: "inline-flex",
            alignItems: "center", // Alignement vertical de la puce et du texte
            gap: "10px", // Espacement entre la puce et le texte
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          {/* Puce circulaire */}
          <span
            style={{
              display: "inline-block",
              width: "5px",
              height: "5px",
              borderRadius: "50%", // Forme circulaire
              backgroundColor: "white", // Rouge doux pour la puce
            }}
          ></span>
          {projet.nomProjet}{" - "}
          <span style={{ fontWeight: "normal", fontSize: "0.8rem" }}>
            {projet.createurPrenom.charAt(0)}
            {projet.createurNom.charAt(0)}
          </span>
        </span>
        <div
          style={{
            fontSize: "0.9rem",
            marginTop: "5px", // Espacement au-dessus des manques
          }}
        >
          {projet.manque.map((manque, idx) => (
            <span
              key={idx}
              style={{
                display: "block",
                marginLeft: "20px", // Décalage pour une meilleure lecture
              }}
            >
              {manque}
            </span>
          ))}
        </div>
      </li>
    ));

  // Rendu des modèles
  const renderModeles = () =>
    data?.modeles.map((modele, index) => (
      <div
        key={index}
        style={{
          padding: "10px",
          borderRadius: "8px",
          textAlign: "left", // Alignement du contenu à gauche
        }}
      >
        <p
          style={{
            textTransform: "uppercase",
            textDecoration: "underline", // Soulignement
          }}
        >
          {modele.nomModele}
        </p>
        {modele.projets.length > 0 ? (
          <ul style={{ paddingLeft: "0px" }}>
            {renderProjets(modele.projets)}
          </ul>
        ) : (
          <p style={{ color: "#555" }}>
            Aucun projet problématique pour ce modèle.
          </p>
        )}
      </div>
    ));

  return (
    <>
      {/* Bouton principal */}
      <button className="bouton-commun" onClick={() => setToggle(!toggle)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center", // Aligne verticalement les éléments
              margin: "8px 0",
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                marginRight: "8px",
                borderRadius: "50%", // Forme circulaire
                backgroundColor: hasErrors ? "red" : "green", // Couleur de fond
                boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.3)`, // Ombre pour le volume
                position: "relative", // Nécessaire pour positionner le reflet
              }}
            />

            <h6 style={{ margin: 0, lineHeight: "1", fontSize: "1rem" , fontWeight: "400"}}>
              VERIFICATEUR
            </h6>
          </div>

          {/* Contenu détaillé */}
          {toggle && (
            <>
              <div style={{ fontSize: "0.8rem" }}>
                {hasErrors
                  ? `${totalProjetsAvecErreurs} projet(s) avec des erreurs`
                  : "Aucune erreur détectée"}
              </div>
              <div className="mt-3 mx-3">
                {loading && <p>Chargement des données...</p>}
                {error && <div className="alert alert-danger">{error}</div>}
                {!loading && !error && data && renderModeles()}
              </div>
            </>
          )}
        </div>
      </button>
    </>
  );
}
