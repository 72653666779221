import React, { useEffect, useState, useContext, useCallback } from "react";
import "../css/Compteurs.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { useWebSocket } from "../../contexts/WebSocketProvider";

export function CountProjetsTiersByUserId({ cibleProjets, context }) {
  const { userId } = useContext(AuthContext);
  const [projets, setListeProjets] = useState([]);
  const [etatProjet] = useState("ouvert"); // Valeur fixe pour le moment
  const [error, setError] = useState(null); // État pour gérer les erreurs
   const {socket} = useWebSocket();

  /**
   * Récupération des projets et modèles pour l'utilisateur.
   */
  const fetchListeProjetsEtModeles = useCallback(async () => {
    if (!userId || !cibleProjets) return;

    try {
      const response = await customAxios.get(
        `/api/projets/projetsEtModeles/byUser/${userId}/${etatProjet}/${cibleProjets}`
      );
      setListeProjets(response.data || []);
      setError(null); // Réinitialise les erreurs en cas de succès
    } catch (err) {
      console.error("Erreur lors de la récupération des projets et modèles :", err);
      setListeProjets([]);
      setError("Impossible de charger les projets.");
    }
  }, [userId, etatProjet, cibleProjets]);

  /**
   * Configuration des écouteurs WebSocket pour les mises à jour.
   */
  useEffect(() => {
    if (!socket || typeof socket.on !== "function") {
      console.warn("Socket non initialisé ou invalide.");
      return;
    }

    fetchListeProjetsEtModeles();


    const handleUpdateEtape = (data) => {
      console.log("CountProjetsTiersByUserId: Événement WebSocket: updateEtape reçu", data);
      fetchListeProjetsEtModeles(); // Rafraîchit les étapes
    };
  
    const handleUpdateProjet = (data) => {
      console.log("CountProjetsTiersByUserId: Événement WebSocket: updateProjet reçu", data);
      fetchListeProjetsEtModeles(); // Rafraîchit le projet
    };

    const handleNouveauProjet = (data) => {
      console.log("CountProjetsTiersByUserId: Événement WebSocket: nouveauProjet reçu", data);
      fetchListeProjetsEtModeles(); // Rafraîchit le projet
    };

    const handleUpdateModele = (data) => {
      console.log("CountProjetsTiersByUserId: Événement WebSocket: updateModèle reçu", data);
      fetchListeProjetsEtModeles(); // Rafraîchit le projet
    };
  
    socket.on("nouveauProjet", handleNouveauProjet);
    socket.on("updateEtape", handleUpdateEtape);
    socket.on("updateProjet", handleUpdateProjet);
    socket.on("updateModèle", handleUpdateModele);


    // Nettoyage des écouteurs WebSocket
    return () => {
      console.log("Nettoyage des écouteurs WebSocket.");
      socket.off("nouveauProjet", handleNouveauProjet);
      socket.off("updateProjet", handleUpdateProjet);
      socket.off("updateEtape", handleUpdateEtape);
      socket.off("updateModèle", handleUpdateModele);
    };
  }, [socket, fetchListeProjetsEtModeles]);

  if (error) {
    return <span className="text-danger">{error}</span>;
  }

  return (
    <>
      {context === "bandeau" ? (
        <span>{projets.length}</span>
      ) : (
        <span className="badge badge-info compteur">{projets.length}</span>
      )}
    </>
  );
}