import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";

import { AuthProvider } from "./contexts/AuthProvider";
import { ProjetProvider } from "./contexts/ProjetContext"; 
import { WebSocketProvider } from "./contexts/WebSocketProvider";

import ReactModal from 'react-modal';
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactModal.setAppElement('#root'); // Définit l'élément racine pour ReactModal
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <WebSocketProvider>
      <AuthProvider>
        <ProjetProvider>

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ToastContainer
            position="bottom-left" // Exemple : position différente
            autoClose={3000} // Ferme automatiquement après 3 secondes
            hideProgressBar
            theme="colored" // Applique un thème coloré
          />
          <App />
        </ProjetProvider>
      </AuthProvider>
    </WebSocketProvider>
  // </React.StrictMode>
);

// Mesurer les performances de l'application
reportWebVitals();