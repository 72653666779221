import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { iconRendering } from "../Utilities/iconRendering"; // Accès aux Picto en fonction du type daction

import {
  BsLightning,
  BsClock,
  BsListCheck,
  BsPeople,
  BsFileEarmarkPerson,
  BsHouseGear,
} from "react-icons/bs";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "../css/Global.css";
import { useWebSocket } from "../../contexts/WebSocketProvider";

import {
  getFontColor,
  getBackgroundClass,
  formatDate,
  calculerDifferenceJours,
  getTooltipTypeActionContent,
  cleanString,
} from "../Utilities/attentesEtapes";

export const ListeAttentesPartenaires = React.memo(({ AllAgences }) => {
  const [etapesEnAttente, setEtapesEnAttente] = useState([]);
  const {
    userId,
    tiersId,
    modeleId,
    handleProjetId,
    handleSelectedMenu,
    searchTerm,
  } = useContext(AuthContext);

  const [typeTiers, setTypeTiers] = useState("");

  const [sortConfig, setSortConfig] = useState({
    key: "nomProjet",
    direction: "ascending",
  });
  const [selectedDestinataire, setSelectedDestinataire] = useState(null);
  const [selectedEtapeId, setSelectedEtapeId] = useState(null);
  const [editingArchive, setEditingArchive] = useState(null);
  const [editingEcheance, setEditingEcheance] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

   const {socket} = useWebSocket();
  useEffect(() => {
    const fetchAllEtapesEnAttente = async () => {
      try {
        logger.log(
          "ListeAttentesPartenaires: fetchAllEtapesEnAttente: tiersId",
          tiersId,
          typeTiers
        );
        if (!tiersId || !typeTiers) {
          logger.warn("ListeAttentesPartenaires: tiersId ou typeTiers manquant.");
          return;
        }
  
        let attentes = [];
        const requestBody = { tiersId };
  
        if (typeTiers === "CLIENT") {
          const response = await customAxios.post(
            `/api/projets/attentesByClientId`,
            requestBody
          );
          attentes = response.data;
        } else {
          const response = await customAxios.post(
            `/api/projets/attentesByTiersId`,
            requestBody
          );
          attentes = response.data;
        }
  
        logger.log("ListeAttentesPartenaires: Étapes en attente:", attentes);
        setEtapesEnAttente(attentes || []);
      } catch (error) {
        console.error(
          "ListeAttentesPartenaires: Erreur lors de la récupération des étapes en attente",
          error
        );
      }
    };
  
    const fetchData = async () => {
      try {
        const response = await customAxios.get(`/api/tiers/${tiersId}`);
        setTypeTiers(response.data.typeTiers);
        await fetchAllEtapesEnAttente();
      } catch (error) {
        console.error("Erreur lors de la récupération des données initiales", error);
      }
    };
  
    fetchData();
  

    setSelectedDestinataire(null);
  
    if (!socket) {
      logger.warn("ListeAttentesPartenaires: Socket non initialisé.");
      return;
    }
  
    // Gérer les événements WebSocket
    const handleUpdate = () => {
      fetchAllEtapesEnAttente();
    };
  
    socket.on("updateEtape", handleUpdate);
    socket.on("updateProjet", handleUpdate);
  
    return () => {
      logger.log("ListeAttentesPartenaires: Nettoyage des abonnements WebSocket.");
      socket.off("updateEtape", handleUpdate);
      socket.off("updateProjet", handleUpdate);
    };
  }, [
    userId,
    typeTiers,
    modeleId,
    AllAgences,
    editingEcheance,
    editingArchive,
    tiersId,

  ]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    logger.log(`Sorting by ${key} in ${direction} order`);
    setSortConfig({ key, direction });
  };

  const getStatus = (destinataires) => {
    const destinataireCorrespondant = destinataires?.find(
      (destinataire) => destinataire.tiers === tiersId
    );
    return destinataireCorrespondant
      ? destinataireCorrespondant.etat
      : "nonTrouvé";
  };

  const filteredEtapesEnAttente = etapesEnAttente.filter((etape) => {
    const matchesEmetteursEtape = etape.emetteursEtape.some((emetteur) =>
      `${emetteur.nom} ${emetteur.prenom}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    const matchesDescriptionProjet = etape.descriptionProjet
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesDescriptionEtape = etape.descriptionEtape
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesNomProjet = etape.nomProjet
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesDestinataires = etape.destinatairesEtape.some((destinataire) =>
      destinataire.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    let matchesEcheance = false;
    if (etape.echeanceEtape) {
      const date = new Date(etape.echeanceEtape);
      const dateString = date.toLocaleDateString("fr-FR");
      matchesEcheance = dateString.includes(searchTerm);
    }

    const projet = etape.projet || {};
    const matchesAdresse = `${projet.adresse || ""} ${projet.cp || ""} ${
      projet.ville || ""
    }`
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    return (
      matchesEmetteursEtape ||
      matchesDescriptionEtape ||
      matchesDescriptionProjet ||
      matchesNomProjet ||
      matchesDestinataires ||
      matchesEcheance ||
      matchesAdresse
    );
  });

  const sortedEtapes = React.useMemo(() => {
    logger.log("Before sorting:", filteredEtapesEnAttente);

    let sortableItems = filteredEtapesEnAttente.filter((etape) => {
      return (
        etape.etapeEtat === "enAttente" &&
        etape.reponseAction.includes(typeTiers)
      );
    });

    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === "statut") {
          const aStatut = getStatus(a.destinatairesEtape);
          const bStatut = getStatus(b.destinatairesEtape);
          logger.log(`Comparing ${aStatut} and ${bStatut}`);
          if (aStatut < bStatut) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aStatut > bStatut) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }

        if (sortConfig.key === "emetteursEtape") {
          const aEmetteur = a.emetteursEtape[0]?.nom.toLowerCase() || "";
          const bEmetteur = b.emetteursEtape[0]?.nom.toLowerCase() || "";
          if (aEmetteur < bEmetteur) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aEmetteur > bEmetteur) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }

        if (
          sortConfig.key === "descriptionProjet" ||
          sortConfig.key === "descriptionEtape"
        ) {
          if (
            a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()
          ) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (
            a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()
          ) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }

        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    logger.log("After sorting:", sortableItems);
    return sortableItems;
  }, [filteredEtapesEnAttente, sortConfig, tiersId]);

  const handleLabelClick = (projetId) => {
    logger.log("ListeAttentesPartenaires: handleLabelClick", projetId);
    handleProjetId(projetId);
    handleSelectedMenu("PROJETS CLIENTS");
  };

  
  const renderDescriptions = (descriptionEtape, destinataires, tiersId) => {
    const destinataireCorrespondant = destinataires?.find(
      (destinataire) => destinataire.tiers === tiersId
    );
    const etatDuPrestataire = destinataireCorrespondant
      ? destinataireCorrespondant.etat
      : "nonTrouvé";
  
    const classEtatDuPrestataire =
      etatDuPrestataire === "enAttente"
        ? "destinataire-enAttente"
        : etatDuPrestataire === "aRépondu"
        ? "destinataire-aRépondu"
        : "destinataire-nonTrouvé";
  
    const tooltipContent =
      etatDuPrestataire === "enAttente"
        ? "Document en attente d'envoi"
        : etatDuPrestataire === "aRépondu"
        ? "Document transmis"
        : "Information non disponible";
  
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id={`infoDescription-${descriptionEtape}`}>
            {tooltipContent}
          </Tooltip>
        }
      >
        <span
          style={{
            borderRadius: "var(--border-radius-destinataires)",
            marginRight: "2px",
            padding: "1px 8px",
          }}
          className={classEtatDuPrestataire}
        >
          {descriptionEtape}
        </span>
      </OverlayTrigger>
    );
  };

  const renderDestinataires = (destinataires, etape) => {
    // logger.log(
    //   "ListeAttentesAgences: renderDestinataires",
    //   destinataires,
    //   etape
    // );
    const sortedDestinataires = (destinataires || []) // Vérification pour destinataires
      .filter((destinataire) => destinataire && destinataire.nom) // Vérification pour chaque destinataire
      .sort((a, b) => {
        // Priorité à "enAttente" et tri alphabétique ensuite
        if (a.etat === "aRépondu" && b.etat !== "aRépondu") return -1;
        if (a.etat !== "aRépondu" && b.etat === "aRépondu") return 1;
        return (a.nom || "").localeCompare(b.nom || "");
      });

    const retour = sortedDestinataires.map(
      (destinataire, destinataireIndex) => {
        const key = `${destinataire.nom || ""}-${destinataireIndex}-`;
        // logger.log("renderDestinataires key", key)
        return (
          <React.Fragment key={key}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  {destinataire.etat === "enAttente"
                    ? "Document en Attente"
                    : destinataire.etat === "aRépondu"
                    ? "Document Reçu"
                    : ""}
                </Tooltip>
              }
            >
              <span
                style={{
                  cursor:
                    destinataire.etat === "aRépondu" ? "pointer" : "default", // Curseur interactif pour "aRépondu"
                  borderRadius: "var(--border-radius-destinataires)",
                  padding: "1px 10px",
                }}
                className={
                  destinataire.etat === "enAttente"
                    ? "destinataire-enAttente"
                    : destinataire.etat === "aRépondu"
                    ? "destinataire-aRépondu"
                    : ""
                }
              >
                {`${destinataire.nom || ""}`}
              </span>
            </OverlayTrigger>
          </React.Fragment>
        );
      }
    );

    // logger.log("renderDestinataires retour", retour);
    return retour;
  };

  const renderEmetteurs = (emetteurs) => {
    logger.log("ListeAttentesPartenaires: renderEmetteurs", emetteurs);
    const sortedEmetteurs = (emetteurs || [])
      .filter((emetteur) => emetteur && emetteur.nom)
      .sort((a, b) => (a.nom || "").localeCompare(b.nom || ""));

    const retour = sortedEmetteurs.map((emetteur, emetteurIndex) => {
      const key = `${emetteur.nom || ""}-${emetteurIndex}-`;
      return (
        <React.Fragment key={key}>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Gestionnaire du projet</Tooltip>}
          >
            <span
              style={{
                borderRadius: "var(--border-radius-destinataires)",
                marginRight: "2px",
                padding: "1px 8px",
              }}
            >
              {`${emetteur.prenom || ""} ${emetteur.nom || ""}`}
            </span>
          </OverlayTrigger>
        </React.Fragment>
      );
    });

    return retour;
  };

  const renderStatut = (destinataires) => {
    const destinataireCorrespondant = destinataires?.find(
      (destinataire) => destinataire.tiers === tiersId
    );
    const etatDuPrestataire = destinataireCorrespondant
      ? destinataireCorrespondant.etat
      : "nonTrouvé";

    const classStatut =
      etatDuPrestataire === "enAttente"
        ? "destinataire-enAttente"
        : etatDuPrestataire === "aRépondu"
        ? "destinataire-aRépondu"
        : "destinataire-nonTrouvé";

    const statut = etatDuPrestataire === "aRépondu" ? "TRANSMIS" : "EN ATTENTE";
    return <span className={classStatut}>{statut}</span>;
  };

  return (
    <div
      style={{
        maxHeight: "600px",
        marginBottom: "15px",
      }}
    >
      <table
        style={{
          width: "100%",
          tableLayout: "fixed",
          borderCollapse: "collapse",
        }}
      >
        <thead className="table-header-requetes">
          <tr style={{ backgroundColor: "var(--bg-zone)" }}>
            {typeTiers === "PARTENAIRE" && (
              <th
                style={{ width: "10%", textAlign: "center" }}
                className="sortable-header"
                onClick={() => requestSort("nomProjet")}
              >
                <span style={{ fontSize: "1.3rem", transform: "scale(1.1)" }}>
                  <BsFileEarmarkPerson />
                </span>
              </th>
            )}
            <th
              style={{ width: "10%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("descriptionProjet")}
            >
                <BsHouseGear style={{ fontSize: "1.3rem"}}/>
            </th>
            <th
              style={{ width: "20%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("descriptionEtape")}
            > 
                <BsListCheck style={{ fontSize: "1.5rem"}}/>
            </th>
            {typeTiers === "CLIENT" && (
              <th
                style={{ width: "12%", textAlign: "center" }}
                className="sortable-header"
                onClick={() => requestSort("destinatairesEtape")}
              >
                ENTREPRISES
              </th>
            )}
            <th
              style={{ width: "10%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("statut")}
            >
              STATUT
            </th>
            <th
              style={{ width: "20%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("emetteursEtape")}
            >
                <BsPeople style={{ fontSize: "1.3rem"}}/>
            </th>
            <th
              style={{ width: "21%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("typeEtape")}
            >
                <BsLightning style={{ fontSize: "1.3rem"}} />
            </th>

            {typeTiers !== "CLIENT" && (
            <th
              style={{ width: "7%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("echeanceEtape")}
            >
                <BsClock style={{ fontSize: "1.3rem" }}/>
            </th> )}
            
          </tr>
        </thead>

        </table>

      <div style={{ maxHeight: "550px", overflowY: "auto" }}>
        <table
          style={{
            width: "100%",
            tableLayout: "fixed",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
          {sortedEtapes.map((etape, index) => {
            const joursRestants = etape.echeanceEtape
              ? calculerDifferenceJours(etape.echeanceEtape)
              : "N/A";
            const classeBackground = getBackgroundClass(joursRestants);
            const fontColor = getFontColor(classeBackground);
            const rowClass = index % 2 === 0 ? "bg-custom" : "";
            const key = etape.etapeId;

            return (
              <tr
                key={key}
                className={`${rowClass} ${
                  selectedEtapeId === key && !AllAgences
                    ? "selected-row"
                    : "annuler-selected-row"
                } ${
                  selectedEtapeId !== key && !AllAgences
                    ? "annuler-selected-row"
                    : ""
                }`}
                style={{
                  height: "40px",
                }}
              >
  
                {typeTiers === "PARTENAIRE" && (
                  <td
                    style={{
                      width: "12%",
                      paddingLeft: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Détails du projet</Tooltip>}
                    >
                      <div onClick={() => handleLabelClick(etape.projetId)}>
                        {etape.nomProjet}
                      </div>
                    </OverlayTrigger>
                  </td>
                )}

                <td
                  className="selection-description-col"
                  style={{ width: "10%", paddingLeft: "6px" }}
                >
                  {etape.descriptionProjet}
                </td>

                <td
                  className="selection-description-col"
                  style={{ width: "20%", paddingLeft: "6px" }}
                >
                  {renderDescriptions(
                    etape.descriptionEtape,
                    etape.destinatairesEtape
                  )}
                </td>

                {typeTiers === "CLIENT" && (
                  <td
                    style={{
                      width: "12%",
                      paddingLeft: "8px",
                    }}
                  >
                    <span className="destination-col">
                      {renderDestinataires(etape.destinatairesEtape, etape)}
                    </span>
                  </td>
                )}

                <td
                  style={{
                    width: "10%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {renderStatut(etape.destinatairesEtape)}
                </td>

                <td
                  style={{
                    width: "20%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {etape.afficheDestinataires &&
                    renderEmetteurs(etape.emetteursEtape)}
                </td>

                <td style={{ width: "21%", textAlign: "center" }}>
                  {etape.echeanceEtape ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          {getTooltipTypeActionContent(etape.typeEtape)}
                        </Tooltip>
                      }
                    >
                      <div className="action-col">{iconRendering(etape)}</div>
                    </OverlayTrigger>
                  ) : (
                    <div className="action-col">{iconRendering(etape)}</div>
                  )}

                </td>

                {typeTiers !== "CLIENT" && (
                <td
                  className={`echeance-col ${classeBackground}`}
                  style={{
                    cursor: "pointer",
                    width: "7%",
                    color: fontColor,
                    textAlign: "center",
                  }}
                >
                  {etape.echeanceEtape ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Échéance attendue</Tooltip>}
                    >
                      <div>{formatDate(etape.echeanceEtape)}</div>
                    </OverlayTrigger>
                  ) : (
                    <div>{formatDate(etape.echeanceEtape)}</div>
                  )}
                </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    </div>
  );
});
