import React, { useContext, useState, useEffect } from "react";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";

//
// utilisateur attaché à un Tiers via accesTiers
// On vérifie que l'état est ouvert
export function UtilisateursByTiersId() {
  const { handleLogout, tiersId } = useContext(AuthContext);

  const [gestionnaire, setGestionnaire] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUtilisateursByTiersId = async () => {
    try {
      if (tiersId) {
        const responseTiers = await customAxios.get(`/api/tiers/${tiersId}`);
        const accesTiers = responseTiers.data.accesTiers;

        if (accesTiers && accesTiers.length > 0) {
          const utilisateurs = [];

          for (const acces of accesTiers) {
            const utilisateurId = acces.utilisateur;
            const etat = acces.etat;

            // Vérifier si l'état est "ouvert"
            if (etat === "ouvert") {
            //   Récupérer les informations utilisateur
              const responseUtilisateur = await customAxios.get(
                `/api/utilisateurs/${utilisateurId}`
              );

              // Recherche de l'agence
              const responseAgence = await customAxios.get(
                `/api/utilisateurs/in-agence/AGENCE/${utilisateurId}`
              );

              utilisateurs.push({
                ...responseUtilisateur.data,
                etat,
                agence: responseAgence.data.nomAgence,
              });
            }
          }

          setGestionnaire(utilisateurs);
        } else {
          logger.log("fetchUtilisateursByTiersId: Aucun utilisateur trouvé.");
        }

        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logger.log("fetchUtilisateursByTiersId: erreur 401, token expiré.");
        handleLogout();
      } else {
        console.error("fetchUtilisateursByTiersId: Erreur :", error);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUtilisateursByTiersId();
  }, [tiersId]);

  return (
    <div className="cadre-zone4 center-content">
      {!isLoading &&
        gestionnaire.map((g, index) => (
          <div key={index}>
            {g.prenom} {g.nom} ({g.agence})
          </div>
        ))}
    </div>
  );
}