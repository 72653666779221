import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeTiers.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";

const ToggleSwitch = ({ isToggled, onToggle }) => (
  <label className="toggle-switch">
    <input type="checkbox" checked={isToggled} onChange={onToggle} />
    <span className="switch-slider round">
      <span className="switch-state">{isToggled ? "Actifs" : "En Archive"}</span>
    </span>
  </label>
);

export function ListeAllTiersByAgence({ typeTiers, titre }) {
  const [listeTiers, setListeTiers] = useState([]);
  const [isToggled, setIsToggled] = useState(true); // Toggle state
  const { userId, handleTiersId, agenceId , handleToggleCreateNewTiers, isCreateNewTiersOpen} = useContext(AuthContext);
  const [selectedTiersId, setSelectedTiersId] = useState(null); // Suivre le tiers sélectionné
  const [hoveredTiersId, setHoveredTiersId] = useState(""); // Initialise à une chaîne vide


  useEffect(() => {
    console.log("ListeAllTiersByAgence: Modification de l'état isCreateNewTiersOpen :", isCreateNewTiersOpen);
  }, [isCreateNewTiersOpen]);

  useEffect(() => {
    const fetchListeTiers = async () => {
      try {
        if (typeTiers && userId && agenceId) {
          const responsedutiers = await customAxios.get(
            `/api/tiers/byTiersIdAndTypeTiers/${agenceId}/${typeTiers}`
          );

          if (Array.isArray(responsedutiers.data.tiersAttaches)) {
            const listeTiersTries = responsedutiers.data.tiersAttaches.sort((a, b) =>
              a.nom.localeCompare(b.nom)
            );
            setListeTiers(listeTiersTries);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchListeTiers();
  }, [userId, typeTiers, agenceId,isCreateNewTiersOpen]);

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };


  const handleLabelClick = (tiersId) => {
    setSelectedTiersId((prevId) => (prevId === tiersId ? null : tiersId)); // Désélectionner si déjà sélectionné
    handleTiersId(tiersId);
  };

  const filteredTiers =
    typeTiers === "CLIENT"
      ? listeTiers.filter((tiers) =>
          isToggled ? tiers.etat === "tiersClientOuvert" : tiers.etat === "tiersClientArchive"
        )
      : listeTiers;

  const nbRecords = filteredTiers.length;

  return (
    <div className="fenetre-liste-tiers cadre-zone2">
      {agenceId ? (
        <div>

          <div
              className="d-flex align-items-center w-100 p-2"
              style={{ borderBottom: "2px solid #ddd", gap: "10px" }} // ✅ Ajoute un espacement uniforme
            >
              {/* Titre (centré avec flex-grow) */}
              <h6 className="mb-0 flex-grow-1 text-start">{titre}</h6> {/* ✅ Remplacé `text-center` par `text-start` */}

              {/* Toggle */}
              {typeTiers === "CLIENT" && (
                <ToggleSwitch />
              )}


              {/* Icône + (poussée à droite) */}
              {typeTiers === "PRESTATAIRE" && (
                <BsPlusCircle 
                size="1.3em"
                className="ms-auto me-2"
                onClick={() => handleToggleCreateNewTiers(typeTiers)}
                /> 
              )}
            </div>
   
            <div className="cadre-zone2">
                  {nbRecords > 0 ? (
                    <ul className="liste-sans-puces">
                      {filteredTiers.map((monTiers) => (
                        <li key={monTiers._id} style={{ listStyleType: "none" }}>
                          <div className="form-check d-flex ms-2">

                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-${monTiers._id}`}>
                                  Cliquez pour avoir les détails
                                </Tooltip>
                              }
                            >
                              <span>
                                <label
                                  className={`form-check-label souris-pointeur ${
                                    hoveredTiersId === monTiers._id || selectedTiersId === monTiers._id
                                      ? "label-semi-gras"
                                      : ""
                                  }`}
                                  htmlFor={`flexCheckDefault${monTiers._id}`}
                                  onClick={(e) => handleLabelClick(monTiers._id, e)}
                                  onMouseEnter={() => setHoveredTiersId(monTiers._id)}
                                  onMouseLeave={() => setHoveredTiersId("")}
                                >
                                  {typeTiers === "CLIENT"
                                    ? `${monTiers.nom} ${monTiers.prenom}`
                                    : monTiers.nom}
                                </label>
                              </span>
                            </OverlayTrigger>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>Aucun tiers à afficher.</p>
                  )}
          </div>
        </div>
      ) : (
        <p>Pas Connecté</p>
      )}
    </div>
  );
}