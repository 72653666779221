import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";
import { BsArrowDown, BsArrowUp, BsPencil, BsTrash, BsCheck, BsX, BsFiles } from "react-icons/bs";

export function GestionActions({ type }) {
  const { newAction, handleAddNewAction, searchTerm, modeleId } = useContext(AuthContext);

  const [listeActions, setListeActions] = useState([]);
  const [sortField, setSortField] = useState("inModel");
  const [sortOrder, setSortOrder] = useState("desc");
  const [editModeIndex, setEditModeIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [loadingActions, setLoadingActions] = useState(new Set());
  const inputRef = useRef(null);

  const optionsVue = ["AGENCE", "CLIENT", "PARTENAIRE", "FOURNISSEUR", "PRESTATAIRE"];

  const fetchlisteActions = useCallback(async () => {
    try {
      const response = await customAxios.get("/api/actions");
      if (!Array.isArray(response.data)) {
        console.error("GestionActions: La réponse API n'est pas un tableau :", response.data);
        return;
      }

      const actionsWithStats = await Promise.all(
        response.data.map(async (action) => {
          const statsResponse = await customAxios.get(`/api/actions/action-stats/${action._id}`, {
            params: { modeleId },
          });
          return {
            ...action,
            modelesCount: statsResponse.data.modelesCount || 0,
            projetsCount: statsResponse.data.projetsCount || 0,
            inModel: statsResponse.data.inModel || false,
          };
        })
      );

      // Tri initial par `inModel`
      actionsWithStats.sort((a, b) => b.inModel - a.inModel);
      setListeActions(actionsWithStats);
    } catch (error) {
      console.error("Erreur lors de la récupération des actions :", error);
    }
  }, [modeleId]);

  useEffect(() => {
    fetchlisteActions();
  }, [fetchlisteActions]);

  const handleSort = (field) => {
    const newSortOrder = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);

    const sortedActions = [...listeActions].sort((a, b) => {
      const valA = field === "inModel" ? Number(a[field]) : a[field] || "";
      const valB = field === "inModel" ? Number(b[field]) : b[field] || "";

      if (newSortOrder === "asc") return valA > valB ? 1 : valA < valB ? -1 : 0;
      return valA < valB ? 1 : valA > valB ? -1 : 0;
    });

    setListeActions(sortedActions);
  };

  const handleCheckboxChange = async (actionId, checked) => {
    setLoadingActions((prev) => new Set(prev).add(actionId));
    try {
      if (checked) {
        await customAxios.patch(`/api/modeles/${modeleId}/addTacheAuModele`, { actionId });
      } else {
        await customAxios.patch(`/api/modeles/${modeleId}/removeTacheDuModele`, { actionId });
      }
      fetchlisteActions();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'association au modèle :", error);
      alert("Une erreur est survenue lors de la mise à jour. Veuillez réessayer.");
    } finally {
      setLoadingActions((prev) => {
        const updated = new Set(prev);
        updated.delete(actionId);
        return updated;
      });
    }
  };

  const handleEditClick = (index, action) => {
    setEditModeIndex(index);
    setEditFormData(action);
    inputRef.current?.focus();
  };

  const handleSaveClick = async () => {
    try {
      if (editFormData._id) {
        await customAxios.put(`/api/actions/${editFormData._id}`, editFormData);
      } else {
        await customAxios.post("/api/actions/add", editFormData);
      }
      fetchlisteActions();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'action :", error);
    }
    setEditModeIndex(null);
    setEditFormData({});
  };

  const handleCancelClick = () => {
    setEditModeIndex(null);
    setEditFormData({});
  };

  const handleDuplicateClick = (index, action) => {
    const duplicatedAction = {
      ...action,
      _id: undefined,
      description: `${action.description} (Copie)`,
    };

    setListeActions((prevActions) => [
      ...prevActions.slice(0, index + 1),
      duplicatedAction,
      ...prevActions.slice(index + 1),
    ]);

    setEditModeIndex(index + 1);
    setEditFormData(duplicatedAction);
  };

  const handleDeleteClick = async (action) => {
    if (action.modelesCount > 0) {
      alert("Impossible de supprimer l'action car elle est utilisée dans un ou plusieurs modèles.");
      return;
    }

    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette action ?")) {
      try {
        await customAxios.delete(`/api/actions/${action._id}`);
        fetchlisteActions();
      } catch (error) {
        console.error("Erreur lors de la suppression de l'action :", error);
      }
    }
  };

  const filteredActions = useMemo(() => {
    return listeActions.filter((action) =>
      action.description?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [listeActions, searchTerm]);

  const getBadgeClass = (count) => {
    if (count > 10) return "badge bg-danger"; // Rouge
    if (count > 5) return "badge bg-warning"; // Jaune
    return "badge bg-success"; // Vert
  };

  const SortableTableHeader = ({ field, children }) => (
    <th onClick={() => handleSort(field)} style={{ cursor: "pointer", textAlign: "center" }}>
      {children} {sortField === field && (sortOrder === "asc" ? <BsArrowUp /> : <BsArrowDown />)}
    </th>
  );

  return (
    <div>
      <div className="titre-zone2">
        <h5>SÉLECTION DES ACTIONS</h5>
      </div>
      {/* <table className="table table-bordered"> */}
      <table style={{ width: "100%" }}>
        {/* <thead className="table-header"> */}
        <thead >
          <tr>
            {type === "ADMIN MODÉLES" && <SortableTableHeader field="inModel">INCLURE</SortableTableHeader>}
            <SortableTableHeader field="description">DESCRIPTION</SortableTableHeader>
            <SortableTableHeader field="type">TYPE</SortableTableHeader>
            <SortableTableHeader field="reponseAction">QUI PEUT RÉPONDRE</SortableTableHeader>
            <SortableTableHeader field="accesReponseAction">PARTAGE AVEC QUI</SortableTableHeader>
            <SortableTableHeader field="modelesCount">MODÈLES</SortableTableHeader>
            <SortableTableHeader field="projetsCount">PROJETS</SortableTableHeader>
            {type !== "ADMIN MODÉLES" && <th style={{ textAlign: "center" }}>ACTIONS</th>}
          </tr>
        </thead>
        <tbody>
          {filteredActions.map((action, index) => (
            <tr 
            className={index % 2 === 0 ? "bg-custom" : ""}
            
            key={action._id}>
               {/* {console.log(`Action ID: ${action._id}, Index: ${index}, Classe: ${index % 2 === 0 ? "bg-custom" : "null"}`)} */}
              {type === "ADMIN MODÉLES" && (
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    checked={action.inModel || false}
                    disabled={loadingActions.has(action._id)}
                    onChange={(e) => handleCheckboxChange(action._id, e.target.checked)}
                  />
                </td>
              )}
              <td>
                {editModeIndex === index ? (
                  <input
                    ref={inputRef}
                    type="text"
                    value={editFormData.description || ""}
                    onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })}
                  />
                ) : (
                  action.description
                )}
              </td>
              <td>{action.type}</td>
              <td>{action.reponseAction?.join(", ")}</td>
              <td>{action.accesReponseAction?.join(", ")}</td>
              <td style={{ textAlign: "center" }}>
                <span className={getBadgeClass(action.modelesCount)}>{action.modelesCount || 0}</span>
              </td>
              <td style={{ textAlign: "center" }}>
                <span className={getBadgeClass(action.projetsCount)}>{action.projetsCount || 0}</span>
              </td>
              {type !== "ADMIN MODÉLES" && (
                <td style={{ textAlign: "center" }}>
                  {editModeIndex === index ? (
                    <>
                      <BsCheck onClick={handleSaveClick} style={{ fontSize: "1.4em", cursor: "pointer", margin: "0 5px" }} />
                      <BsX onClick={handleCancelClick} style={{ fontSize: "1.4em", cursor: "pointer", margin: "0 5px" }} />
                    </>
                  ) : (
                    <>
                      <BsPencil
                        onClick={() => handleEditClick(index, action)}
                        style={{ cursor: "pointer", margin: "0 5px" }}
                      />
                      <BsFiles
                        onClick={() => handleDuplicateClick(index, action)}
                        style={{ cursor: "pointer", margin: "0 5px" }}
                      />
                      <BsTrash
                        onClick={() => handleDeleteClick(action)}
                        style={{ cursor: "pointer", margin: "0 5px" }}
                      />
                    </>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}