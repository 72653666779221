import React, { useEffect, useState, useContext } from "react";
import customAxios from "../Utilities/getAxiosToken";
import { AuthContext } from "../../contexts/AuthProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./css/Bandeau.css";
import { BandeauPreviEcheancier } from "./BandeauPreviEcheancier";
import { CountAllProjetsClients } from "../Projets/CountAllProjetsClients";
import { CountListeTiers } from "../Tiers/CountListeTiers";
import { CountProjetsTiersByUserId } from "../Projets/CountProjetsTiersByUserId";
import { CountAttentesTiers } from "../Attentes/CountAttentesTiers";
import { CountEtapesAttentes } from "../Etapes/CountEtapesAttentes";
import { useWebSocket } from "../../contexts/WebSocketProvider";

function BandeauCard({
  title,
  tooltipText,
  badgeContent,
  badgeClass,
  onClick,
}) {
  return (
    <div
      className="card card-margin text-white text-center bandeau"
      onClick={onClick}
    >
      <div className="card-header bandeauBorder">{title}</div>
      <div className="bandeau-card-body">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip className="custom-tooltip">{tooltipText}</Tooltip>}
        >
          <span className={` badge ${badgeClass} compteur`}>
            {badgeContent}
          </span>
        </OverlayTrigger>
      </div>
    </div>
  );
}

function bandeauUtilisateurClient(
  nouveauxDocuments,
  attentes,
  handleSelectedMenu
) {
  return (
    <div className="d-flex justify-content-center my-2">
      <BandeauCard
        title="NOUVEAUX DOCUMENTS"
        tooltipText="Nouveaux documents reçus"
        badgeContent={nouveauxDocuments}
        badgeClass="bg-rouge bg-gradient"
        onClick={() => handleSelectedMenu("MES DOCUMENTS")}
      />

      <BandeauCard
        title="ATTENTES"
        tooltipText="Attentes en cours"
        badgeContent={attentes}
        badgeClass="bg-enAttente"
        onClick={() => handleSelectedMenu("ATTENTES")}
      />
    </div>
  );
}
function bandeauUtilisateurPartenaire(
  nouveauxDocuments,
  attentes,
  handleSelectedMenu
) {
  return (
    <div className="d-flex justify-content-center my-2">
      <BandeauCard
        title="MES PROJETS"
        tooltipText="Mes Projets"
        badgeContent={nouveauxDocuments}
        badgeClass="bg-rouge bg-gradient"
        onClick={() => handleSelectedMenu("MES PROJETS")}
      />

      <BandeauCard
        title="ATTENTES"
        tooltipText="Attentes en cours"
        badgeContent={<CountAttentesTiers typeTiers={"PARTENAIRE"} />}
        onClick={() => handleSelectedMenu("ATTENTES")}
      />

      <BandeauCard
        title="DÉPÔT EXPRESS"
        tooltipText="Dépôt de documents"
        badgeContent={<span>GO</span>} // Texte personnalisé "GO"
        badgeClass="bg-violet bg-gradient" // Optionnel, ajoutez une classe CSS si besoin
        onClick={() => handleSelectedMenu("DÉPÔT EXPRESS")}
      />
    </div>
  );
}

function bandeauUtilisateurAgence(user, attentes, recus, handleSelectedMenu) {
  return (
    <div className="d-flex justify-content-center my-2">
      <BandeauCard
        title="CLIENTS"
        tooltipText="Mes Clients"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountListeTiers
              typeTiers="CLIENT"
              AllAgences={false}
              context="bandeau"
            />
          )
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("CLIENTS")}
      />

      <BandeauCard
        title="PARTENAIRES"
        tooltipText="Les Partenaires"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountListeTiers
              typeTiers="PARTENAIRE"
              AllAgences={false}
              context="bandeau"
            />
          )
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("PARTENAIRES")}
      />

      <BandeauCard
        title="PROJETS"
        tooltipText="Mes Projets"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountProjetsTiersByUserId
              cibleProjets="CLIENT"
              context="bandeau"
            />
          )
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("PROJETS CLIENTS")}
      />

      <BandeauCard
        title="ATTENTES"
        tooltipText="Attentes en cours"
        badgeContent={attentes}
        badgeClass="bg-enAttente"
        onClick={() => handleSelectedMenu("REQUÊTES")}
      />

      <BandeauCard
        title="REÇUS"
        tooltipText="À traiter"
        badgeContent={recus}
        badgeClass="bg-aRépondu"
        onClick={() => handleSelectedMenu("REQUÊTES")}
      />

      <BandeauCard
        title="REQUÊTES"
        tooltipText="Tâches urgentes"
        badgeContent={<CountEtapesAttentes AllAgences={false} />}
        onClick={() => handleSelectedMenu("REQUÊTES")}
      />

      <BandeauCard
        title="PREVISIONNELS"
        tooltipText="Perspectives de vente"
        badgeContent={
          <BandeauPreviEcheancier
            typeSynthese="PRÉVISIONNELS"
            AllAgences={false}
          />
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("SYNTHÈSES")}
      />

      <BandeauCard
        title="ÉCHÉANCIER"
        tooltipText="Facturation planifiée"
        badgeContent={
          <BandeauPreviEcheancier
            typeSynthese="ÉCHÉANCIERS"
            AllAgences={false}
          />
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("SYNTHÈSES")}
      />
    </div>
  );
}
function bandeauManagerAgence(user,handleSelectedMenu) {
  logger.log("Bandeau Manager Agence", user);
  return (
    <div className="d-flex justify-content-center mb-4">
      <BandeauCard
        title="ALL CLIENTS"
        tooltipText="Tous les Clients"
        badgeContent={
            <CountListeTiers
              typeTiers="CLIENT"
              AllAgences={true}
              context="bandeau"
            />
        }

        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ALL CLIENTS")}
      />

      <BandeauCard
        title="ALL PARTENAIRES"
        tooltipText="Tous les Partenaires"
        badgeContent={
            <CountListeTiers
              typeTiers="PARTENAIRE"
              AllAgences={false}
              context="bandeau"
            />
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ALL PARTENAIRES")}
      />

      <BandeauCard
        title="ALL PROJETS"
        tooltipText="Tous les Projets"
        badgeContent={
            <CountAllProjetsClients cibleProjets={"CLIENT"} />
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ALL PROJETS")}
      />

      <BandeauCard
        title="ALL PRESTATAIRES"
        tooltipText="Tous les Prestataires"
        badgeContent={
            <CountListeTiers
              typeTiers="PRESTATAIRE"
              AllAgences={false}
              context="bandeau"
            />
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ALL PRESTATAIRES")}
      />

      <BandeauCard
        title="ALL SYNTHÈSES"
        tooltipText="Pour accéder aux projets de l'agence"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ALL SYNTHÈSES")}
      />

      <BandeauCard
        title="ALL REQUÊTES"
        tooltipText="Toutes les attentes"
        badgeContent={<CountEtapesAttentes AllAgences={true} />}
        badgeClass="badge-warning"
        onClick={() => handleSelectedMenu("ALL REQ")}
      />

      <BandeauCard
        title="ALL PREVISIONNELS"
        tooltipText="Perspectives de vente"
        badgeContent={
          <BandeauPreviEcheancier
            typeSynthese="PRÉVISIONNELS"
            AllAgences={true}
          />
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ALL SYNTHÈSES")}
      />

      <BandeauCard
        title="ALL ÉCHÉANCIER"
        tooltipText="Facturation planifiée"
        badgeContent={
          <BandeauPreviEcheancier
            typeSynthese="ÉCHÉANCIERS"
            AllAgences={true}
          />
        }
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ALL SYNTHÈSES")}
      />
    </div>
  );
}

function bandeauAdmin(handleSelectedMenu) {
  return (
    <div className="d-flex justify-content-center mb-4">
      <BandeauCard
        title="ADMIN PARTENAIRES"
        tooltipText="Tous les Clients"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ADMIN PARTENAIRES")}
      />

      <BandeauCard
        title="ADMIN MODÈLES"
        tooltipText="Tous les Partenaires"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ADMIN MODÈLES")}
      />

      <BandeauCard
        title="ADMIN MÉTIERS"
        tooltipText="Tous les Prestataires"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ADMIN MÉTIERS")}
      />

      <BandeauCard
        title="ADMIN ACTIONS"
        tooltipText="Tous les Projets"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ADMIN ACTIONS")}
      />

      <BandeauCard
        title="ADMIN CATÉGORIES"
        tooltipText="Tous les Projets"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ADMIN CATÉGORIES")}
      />

      <BandeauCard
        title="ADMIN AGENCES"
        tooltipText="Pour accéder aux projets de l'agence"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ADMIN AGENCES")}
      />

      <BandeauCard
        title="ADMIN GÉNÉRAL"
        tooltipText="Pour accéder aux projets de l'agence"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ADMIN GÉNÉRAL")}
      />

      <BandeauCard
        title="ADMIN RÔLES"
        tooltipText="Toutes les attentes"
        badgeContent={"GO"}
        badgeClass="bg-violet bg-gradient"
        onClick={() => handleSelectedMenu("ADMIN RÔLES")}
      />
    </div>
  );
}

export function Bandeau() {
  const [attentes, setAttentes] = useState(0);
  const [recus, setRecus] = useState(0);
  const [nouveauxDocuments, setNouveauxDocuments] = useState(0);
  const { user, handleSelectedMenu, isVueManager } = useContext(AuthContext);

  // logger.log("Bandeau user", user);

  useEffect(() => {
    console.log("Bandeau monté");
    return () => {
      console.log("Bandeau démonté");
    };
  }, []);

  const { socket } = useWebSocket();

  useEffect(() => {
    logger.log("Bandeau: Utilisateur connecté");

    if (!user?.userId) {
      logger.warn("Bandeau: Utilisateur non connecté");
      return;
    }

    // Fonction pour récupérer les attentes
    const fetchAttentesCounts = async () => {
      try {
        let response;
        if (user.userTypeUtilisateur === "ADMINISTRATEUR") {
          response = await customAxios.get("/api/etapes/statusCountsGeneral/");
        } else {
          const cibleProjets =
            user.userTypeUtilisateur === "AGENCE" ? "CLIENT" : "PARTENAIRE";
          response = await customAxios.get(
            `/api/etapes/statusCounts/${user.userId}/${cibleProjets}`
          );
        }
        const { enAttenteCount, aReponduCount } = response.data;
        logger.log(
          "Bandeau fetchAttentesCounts",
          enAttenteCount,
          aReponduCount
        );

        setAttentes(enAttenteCount);
        setRecus(aReponduCount);
      } catch (error) {
        console.error("Erreur lors de la récupération des attentes :", error);
      }
    };

    // Fonction pour récupérer les nouveaux documents
    const fetchNouveauxDocuments = async () => {
      try {
        const accesFileClient = user.userTypeUtilisateur === "CLIENT";
        const response = await customAxios.get(
          `/api/files/unreadCount/${user.userId}/${accesFileClient}`
        );
        const unreadData = response.data;

        logger.log("Bandeau fetchNouveauxDocuments", unreadData);

        if (Array.isArray(unreadData)) {
          const totalUnreadCount = unreadData.reduce(
            (total, item) => total + (item.unreadCount || 0),
            0
          );
          setNouveauxDocuments(totalUnreadCount);
        } else if (
          typeof unreadData === "object" &&
          unreadData.unreadCount !== undefined
        ) {
          setNouveauxDocuments(unreadData.unreadCount);
        } else {
          console.error("Unexpected unread data format:", unreadData);
          setNouveauxDocuments(0);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des nouveaux documents :",
          error
        );
        setNouveauxDocuments(0);
      }
    };

    // Appels initiaux
    fetchAttentesCounts();
    fetchNouveauxDocuments();

    // Gestion des événements WebSocket
    const handleUpdateEtape = () => {
      fetchAttentesCounts();
    };

    const handleUpdateProjet = () => {
      fetchAttentesCounts();
    };

    const handleUpdateFile = () => {
      fetchNouveauxDocuments();
    };

    if (socket) {
      socket.on("updateEtape", handleUpdateEtape);
      socket.on("updateProjet", handleUpdateProjet);
      socket.on("updateFile", handleUpdateFile);
    } else {
      logger.warn("CountAttentesTiers: Socket non initialisé");
    }

    // Nettoyage des écouteurs WebSocket
    return () => {
      logger.log("CountAttentesTiers: Nettoyage des écouteurs WebSocket");
      if (socket) {
        socket.off("updateEtape", handleUpdateEtape);
        socket.off("updateProjet", handleUpdateProjet);
        socket.off("updateFile", handleUpdateFile);
      }
    };
  }, [user?.userId, user?.userTypeUtilisateur]);

  return (
    <>
      <div className={"mb-4"}>
        {user.userTypeUtilisateur === "CLIENT"
          ? bandeauUtilisateurClient(
              nouveauxDocuments,
              attentes,
              handleSelectedMenu
            )
          : user.userTypeUtilisateur === "PARTENAIRE"
          ? bandeauUtilisateurPartenaire(
              nouveauxDocuments,
              attentes,
              handleSelectedMenu
            )
          : user.userTypeUtilisateur === "ADMINISTRATEUR"
          ? bandeauAdmin(handleSelectedMenu)
          : isVueManager && user.userTypeUtilisateur === "AGENCE"
          ? bandeauManagerAgence(user, handleSelectedMenu)
          : bandeauUtilisateurAgence(user, attentes, recus, handleSelectedMenu)}
      </div>
    </>
  );
}
