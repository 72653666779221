import React, { useState, useEffect, useContext } from "react";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import { useWebSocket } from "../../contexts/WebSocketProvider";

import customAxios from "../Utilities/getAxiosToken";
import { AuthContext } from "../../contexts/AuthProvider";
import logger from "../Utilities/Logger";
import useHandleFileClick from "../Utilities/GetFiles";

import FilePreviewModal from "./FilePreviewModal";

export const ListeFichiersPartenaires = () => {
  const { downloadFile } = useHandleFileClick();
  const { userId, tiersId } = useContext(AuthContext); // Utilisateur actuel
  const [fichiers, setFichiers] = useState([]);
  const [activeProjets, setActiveProjets] = useState({});
  const [activeRubriques, setActiveRubriques] = useState({});
  const [selectedFiles, setSelectedFiles] = useState(new Set());
  const [filePreviewId, setFilePreviewId] = useState(null);

  const handlePreviewFile = async (fileId) => {
    await markFileAsRead(fileId); // Marque comme lu
    setFilePreviewId(fileId); // Ouvre la modal
  };

  const closeModal = () => setFilePreviewId(null);

  const markFileAsRead = async (fileId) => {
    try {
      await customAxios.patch(`/api/files/markAsRead/${fileId}`, {
        userId,
        readDate: new Date().toISOString(),
      });
      fetchFichiers(); // Rafraîchit la liste
    } catch (error) {
      console.error("Erreur lors de la mise à jour de lastReadDate :", error);
    }
  };

  const handleCheckboxChange = (fileId) => {
    setSelectedFiles((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(fileId)) {
        newSet.delete(fileId);
      } else {
        newSet.add(fileId);
      }
      return newSet;
    });
  };

  const fetchFichiers = async () => {
    try {
      const response = await customAxios.get(`/api/files/partenaire/${tiersId}`);
      logger.log("ListeFichiersPartenaire fetchFichiers", response.data);
      setFichiers(groupByProjet(response.data));
    } catch (error) {
      console.error("Erreur lors de la récupération des fichiers :", error);
      setFichiers([]);
    }
  };

  const groupByProjet = (data) => {
    // Regrouper par nomProjet
    const grouped = data.reduce((acc, fichier) => {
      const nomProjet = fichier.nomProjet || "Projet sans nom";
      acc[nomProjet] = acc[nomProjet] || {};
      const rubrique = fichier.rubrique || "Rubrique non définie";
      acc[nomProjet][rubrique] = acc[nomProjet][rubrique] || [];
      acc[nomProjet][rubrique].push(fichier);
      return acc;
    }, {});

    // Trier les fichiers dans chaque rubrique par date
    Object.keys(grouped).forEach((projet) => {
      Object.keys(grouped[projet]).forEach((rubrique) => {
        grouped[projet][rubrique].sort(
          (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
        );
      });
    });

    return grouped;
  };

  const toggleProjet = (projet) => {
    setActiveProjets((prev) => ({ ...prev, [projet]: !prev[projet] }));
  };

  const toggleRubrique = (projet, rubrique) => {
    setActiveRubriques((prev) => ({
      ...prev,
      [projet]: { ...prev[projet], [rubrique]: !prev[projet]?.[rubrique] },
    }));
  };

    const {socket} = useWebSocket();
   useEffect(() => {
    if (!tiersId) {
      logger.warn("ListeFichiers: tiersId non défini, sortie anticipée.");
      return;
    }
  
    const handleSocketUpdate = () => {
      logger.log("ListeFichiers: Événement WebSocket reçu, mise à jour des fichiers.");
      fetchFichiers();
    };
  
    const initialize = async () => {
      try {
        logger.log("ListeFichiers: Initialisation - Chargement des fichiers.");
        await fetchFichiers();
      } catch (error) {
        console.error("ListeFichiers: Erreur lors du chargement des fichiers :", error);
      }
    };
  
    initialize();
  
    // Configuration des abonnements WebSocket
    socket.on("updateEtape", handleSocketUpdate);
    socket.on("updateProjet", handleSocketUpdate);
    socket.on("updateFichier", handleSocketUpdate);
  
    logger.log("ListeFichiers: Abonnements WebSocket configurés.");
  
    // Nettoyage des abonnements WebSocket
    return () => {
      logger.log("ListeFichiers: Nettoyage des abonnements WebSocket.");
      socket.off("updateEtape", handleSocketUpdate);
      socket.off("updateProjet", handleSocketUpdate);
      socket.off("updateFichier", handleSocketUpdate);
    };
  }, [tiersId, fetchFichiers]);

  if (!tiersId) {
    return <h5 className="titre-zone3">Aucun tiers sélectionné</h5>;
  }

  return (
    <div className="cadre-zone3-note" style={{ marginBottom: "40px" }}>
      <h6
        style={{
          textAlign: "center",
          color: "var(--texte-zone)",
          marginBottom: "15px",
        }}
      >
        DOCUMENTS DU PARTENAIRE
      </h6>
      {Object.keys(fichiers).length > 0 ? (
        Object.entries(fichiers).map(([projet, rubriques]) => (
          <div
            key={projet}
            style={{
              cursor: "pointer",
              fontWeight: "500",
              paddingTop: "10px",
            }}
          >
            <div
              onClick={() => toggleProjet(projet)}
              style={{ display: "flex", alignItems: "center" }}
            >
              {activeProjets[projet] ? <FiChevronDown /> : <FiChevronRight />}
              <span style={{ marginLeft: "5px" }}>{projet}</span>
            </div>
            {activeProjets[projet] &&
              Object.entries(rubriques).map(([rubrique, fichiers]) => (
                <div key={rubrique} style={{ paddingLeft: "20px" }}>
                  <div
                    onClick={() => toggleRubrique(projet, rubrique)}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {activeRubriques[projet]?.[rubrique] ? (
                      <FiChevronDown />
                    ) : (
                      <FiChevronRight />
                    )}
                    <span style={{ marginLeft: "5px" }}>{rubrique}</span>
                  </div>
                  {activeRubriques[projet]?.[rubrique] &&
                    fichiers.map((fichier, index) => {
                      const isUnread =
                        !fichier.metadata.lastReadDate ||
                        !fichier.metadata.lastReadDate.some(
                          (entry) => entry.utilisateur === userId
                        );

                      return (
                        <div
                          key={`${fichier._id}-${index}`}
                          style={{
                            paddingLeft: "40px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedFiles.has(fichier._id)}
                              onChange={() =>
                                handleCheckboxChange(fichier._id)
                              }
                              style={{ marginRight: "6px" }}
                              // onClick={(e) => e.stopPropagation()}
                            />
                            <span
                              onClick={() => handlePreviewFile(fichier._id)}
                              style={{
                                fontWeight: isUnread ? "bold" : "normal",
                              }}
                            >
                              {fichier.prefixe}{" "}
                              {new Date(fichier.uploadDate).toLocaleString()}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "10px",
                            }}
                          >
                            <BsDownload
                              size="1.2em"
                              onClick={() => {
                                downloadFile(fichier._id);
                                markFileAsRead(fichier._id);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              ))}
          </div>
        ))
      ) : (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <p>Aucun document trouvé pour ce partenaire.</p>
        </div>
      )}
      {filePreviewId && (
        <FilePreviewModal fileId={filePreviewId} closeModal={closeModal} />
      )}
    </div>
  );
};