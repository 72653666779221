import React, { useState, useEffect } from "react";
import customAxios from "../Utilities/getAxiosToken";


export function CheckProxyStatus() {
  const [proxyStatus, setProxyStatus] = useState({ state: "unknown", isLoading: false });

  const fetchProxyStatus = async () => {
    setProxyStatus((prev) => ({ ...prev, isLoading: true }));
    try {
      // logger.log(`[INFO] fetchProxyStatus Checking proxy status at /proxy/alive`);
      const response = await customAxios.get(`/proxy/alive`);
      // logger.log(`[INFO] fetchProxyStatus Proxy response:`, response.data);
      setProxyStatus({ state: "alive", isLoading: false });
    } catch (error) {
      console.error("[ERROR] Erreur lors de la requête au proxy :", error);
      if (error.response) {
        console.error(`[ERROR] Response Data:`, error.response.data);
        console.error(`[ERROR] Status Code:`, error.response.status);
      }
      setProxyStatus({ state: "offline", isLoading: false });
    }
  };

  useEffect(() => {
    fetchProxyStatus();

    const fetchInterval = proxyStatus.state === "offline" ? 30000 : 10000;
    const intervalId = setInterval(() => {
      fetchProxyStatus();
    }, fetchInterval);

    return () => clearInterval(intervalId);
  }, [proxyStatus.state]);

  const getStatusColor = () => {
    if (proxyStatus.state === "alive") return "green";
    if (proxyStatus.state === "offline") return "red";
    return "gray"; // Pour l'état "unknown"
  };

  const getStatusMessage = () => {
    if (proxyStatus.isLoading) return "Checking proxy status...";
    if (proxyStatus.state === "alive") return "Proxy server is alive";
    if (proxyStatus.state === "offline") return "Proxy server is offline";
    return "Status unknown";
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "12px",
          height: "12px",
          marginRight: "5px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(),
        }}
        title={getStatusMessage()}
      />
      {/* <span>{getStatusMessage()}</span> */}
    </div>
  );
}