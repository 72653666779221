

import React, { useEffect, useState, useContext, useCallback } from "react";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import useGetModeles from "../Utilities/GetModeles";
import {
  getFontColor,
  getBackgroundClass,
  calculerDifferenceJours,
  getCategory
} from "../Utilities/attentesEtapes";
import { useWebSocket } from "../../contexts/WebSocketProvider";

export const CountEtapesAttentes = ({ AllAgences }) => {
  const { fetchModeles }  = useGetModeles(); 
  
  const [etapesEnAttente, setEtapesEnAttente] = useState([]);
  const { userId, agenceId } = useContext(AuthContext);
 
  const [attentesCounter, setAttentesCounter] = useState({
    success: 0,
    warning: 0,
    danger: 0,
  });



  const fetchEtapesEnAttente = useCallback(async () => {
    try {
      if (!userId) return;
  
      let response, attentes = [];
  
      if (AllAgences) {
        let listeModeleIds = await fetchModeles(agenceId);
        if (!listeModeleIds) return;
  
        // logger.log("CountEtapesAttentes fetchEtapesEnAttente listeModeleIds", listeModeleIds);
        for (let modeleIdLocal of listeModeleIds) {
          // const payload = { modeleIds: modeleIdLocal.modeleId };
          const payload = { modeleIds: modeleIdLocal._id };
          // logger.log("CountEtapesAttentes fetchEtapesEnAttente payload", payload);
  
          try {
            response = await customAxios.post(`/api/projets/attentesByModeleIds`, payload);
            // logger.log("CountEtapesAttentes fetchEtapesEnAttente Réponse de la requête POST:", response.data);

            attentes = [...attentes, ...response.data];
          } catch (postError) {
            console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la requête POST:", postError);
          }
        }
      } else {
        try {
          // logger.log("CountEtapesAttentes fetchEtapesEnAttente /api/projets/attentesByUserId");
          const requestBody = {
            userId: userId,
          };
          const response = await customAxios.post(
            `/api/projets/attentesByUserId`,
            requestBody
          );
          attentes = response.data;
          // logger.log("CountEtapesAttentes fetchEtapesEnAttente Réponse de la requête POST:", response.data);
        } catch (getError) {
          console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la requête POST:", getError);
        }
      }
  
      setEtapesEnAttente(attentes || []);
    } catch (error) {
      console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la récupération des étapes en attente", error);
    }
  }, [userId, AllAgences, agenceId]);
  
  
   const {socket} = useWebSocket();

  useEffect(() => {
    if (!socket) {
      console.warn("CountEtapesAttentes: Socket non initialisé.");
      return;
    }
  
    // Charger les étapes en attente initialement
    fetchEtapesEnAttente();


    // Juste pour avoir un message qui distingue les sources de du message
    const handleUpdateEtape = (data) => {
      console.log("CountEtapesAttentes: Événement WebSocket: updateEtape reçu", data);
      fetchEtapesEnAttente(); // Rafraîchit les étapes
    };
  
    const handleUpdateProjet = (data) => {
      console.log("CountEtapesAttentes: Événement WebSocket: updateProjet reçu", data);
      fetchEtapesEnAttente(); // Rafraîchit le projet
    };
  
    socket.on("updateEtape", handleUpdateEtape);
    socket.on("updateProjet", handleUpdateProjet);

  
    // Gestion des erreurs de connexion
    socket.on("connect_error", (err) => {
      console.error("Erreur de connexion WebSocket:", err);
      logger.log("Message d'erreur:", err.message);
      logger.log("Description:", err.description);
      logger.log("Contexte:", err.context);
    });
  
    // Nettoyage à la fermeture du composant
    return () => {
      logger.log("CountEtapesAttentes: Nettoyage des écouteurs WebSocket.");
      socket.off('updateEtape', handleUpdateEtape);
      socket.off('updateProjet', handleUpdateProjet);
  
      // Ne pas fermer la connexion WebSocket si elle est partagée globalement
      // socket.disconnect();
    };
  }, [socket, fetchEtapesEnAttente]);

  useEffect(() => {
    const newAttentesCounter = etapesEnAttente.reduce(
      (acc, etape) => {
        const joursRestants = calculerDifferenceJours(etape.echeanceEtape);
        const category = getCategory(joursRestants); // Utilisation de getCategory
  
        if (acc[category] !== undefined) {
          acc[category] += 1;
        }
        return acc;
      },
      { success: 0, warning: 0, danger: 0 } // Initialisation du compteur
    );
  
    setAttentesCounter(newAttentesCounter);
  }, [etapesEnAttente]);


  const badgeCategories = [
    { key: "success", className: "bg-vert bg-gradient" },
    { key: "warning", className: "bg-jaune bg-gradient" },
    { key: "danger", className: "bg-rouge bg-gradient" },
  ];
  
  return (
    <>
      {badgeCategories.map((category) => (
        <div
          key={category.key}
          className={`badge ${category.className}`}
          style={{ color: getFontColor(category.className) }}
        >
          {attentesCounter[category.key]}
        </div>
      ))}
    </>
  );


};
