import customAxios from "./getAxiosToken";

/**
 * Vérifie si un projet est ouvert ou archivé pour un utilisateur donné.
 * 
 * @param {string} userId - ID de l'utilisateur.
 * @param {string} projetId - ID du projet.
 * @param {number} [retryCount=0] - Nombre de tentatives en cas d'échec.
 * @returns {Promise<{acces?: string, dateArchivage?: string} | null>} - L'état d'accès (ouvert/archivé), la date d'archivage ou `null` si projetId est absent.
 * @throws {Error} - Si une erreur critique survient (404, 403 ou autre).
 */
export async function checkProjetAcces(userId, projetId, retryCount = 0) {
  // Valider les entrées
  if (!userId || typeof userId !== "string") {
    logger.warn("checkProjetAcces: userId non défini ou invalide.");
    throw new Error("Identifiant utilisateur invalide.");
  }

  if (!projetId || typeof projetId !== "string") {
    logger.warn("checkProjetAcces: projetId non défini ou invalide.");
    return null; // Retourne null ou une valeur par défaut si projetId n'est pas fourni
  }

  const API_URL = `/api/utilisateurs/${encodeURIComponent(userId)}/projets/${encodeURIComponent(projetId)}/acces`;

  try {
    const response = await customAxios.get(API_URL);
    logger.log("Accès au projet récupéré avec succès:", response.data);

    // Retourner les données de l'API
    return {
      acces: response.data.acces, // "ouvert" ou "archivé"
      dateArchivage: response.data.dateArchivage, // Optionnel
    };

  } catch (error) {
    logger.error("checkProjetAcces: Erreur lors de l'accès au projet:", error);

    // Gestion des erreurs spécifiques
    if (error.response) {
      switch (error.response.status) {
        case 404:
          throw new Error("Projet introuvable.");
        case 403:
          throw new Error("Utilisateur non autorisé pour ce projet.");
        case 500:
          logger.warn("Erreur interne du serveur, tentative de retry.");
          break; // Autorise le retry pour les erreurs 500
        default:
          throw new Error(`Erreur inattendue : ${error.response.status}`);
      }
    }

    // Tentative de retry pour les erreurs réseau (ENOTFOUND, ECONNRESET)
    if (!error.response && retryCount < 3) {
      logger.warn(`checkProjetAcces: Réessai n°${retryCount + 1} après échec réseau.`);
      return checkProjetAcces(userId, projetId, retryCount + 1);
    }

    throw new Error("Erreur réseau ou serveur persistante.");
  }
}
