import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeProjets.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  HiOutlineArchiveBoxArrowDown,
  HiOutlineArchiveBoxXMark,
} from "react-icons/hi2";

// On affiche les projets en fonction du typeTiers qui cibleProjets

export function ListeProjetsByUserId(props) {
  const { cibleProjets } = props; // Cible CLIENT ou PARTENAIRE ou FOURNISSEUR ou AGENCE

  logger.log("ListeProjetsByUserId: ", cibleProjets);

  const [projets, setListeProjets] = useState([]);
  const [hoveredProjetId, setHoveredProjetId] = useState("");
  const [isToggled, setIsToggled] = useState(true); // Pour Afficher les Projets Clients ou autres
  const [etatProjet, setEtatProjet] = useState("ouvert"); // État local pour gérer l'état des projets
  const [selectedProjectId, setSelectedProjectId] = useState(null); // État pour suivre le projet sélectionné

  const { handleProjetId, userId, handleEtapeId, handleTiersId } =
    useContext(AuthContext);

  const handleToggleChange = () => {
    const newEtatProjet = isToggled ? "archivé" : "ouvert";
    setIsToggled(!isToggled);
    setEtatProjet(newEtatProjet);
  };

  const handleLabelClick = (projetId) => {
    setSelectedProjectId(projetId);
    // logger.log("ListeProjetsByUserId: handleLabelClick", projetId);
    handleProjetId(projetId);
    // logger.log("ListeProjetsByUserId: handleLabelClick etapeId NULL");
    handleEtapeId(null);
  };

  useEffect(() => {
    handleTiersId(null);
    setIsToggled(true);
    // handleProjetId(null);
  }, [cibleProjets]);

  const ToggleSwitch = () => (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isToggled}
        onChange={handleToggleChange}
      />
      <span className="switch-slider round">
        <span className="switch-state">
          {isToggled ? "Actifs" : "En Archive"}
        </span>
      </span>
    </label>
  );

  // Déclaration de la fonction fetchListeProjetsByUserId en dehors du useEffect
  const fetchListeProjetsByUserId = useCallback(async () => {
    try {
      // On recherche tous les projets dont le modèle est actif
      const response = await customAxios.get(
        `/api/projets/projetsEtModeles/byUser/${userId}/${etatProjet}/${cibleProjets}`
      );
      let projetsAvecDetails = response.data;
      // Trier les projets par nom (ou un autre critère)
      projetsAvecDetails = projetsAvecDetails.sort((a, b) =>
        a.nom.localeCompare(b.nom)
      );

      logger.log(
        "ListeProjetsByUserId: fetchListeProjetsByUserId projetsAvecDetails",
        userId,
        etatProjet,
        cibleProjets,
        projetsAvecDetails
      );

      setListeProjets(projetsAvecDetails);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des projets et des modèles :",
        error
      );
    }
  }, [userId, etatProjet, cibleProjets]);

  useEffect(() => {
    if (cibleProjets && userId) {
      fetchListeProjetsByUserId();
    }
  }, [cibleProjets, userId, etatProjet, fetchListeProjetsByUserId]);

  /**
   * Gère l'archivage ou la réactivation d'un projet.
   * Vérifie et ajuste également l'état du tiers parent en fonction des projets qui lui sont liés.
   *
   * Fonctionnalités principales :
   * 1. Archive ou réactive un projet en basculant son état entre "ouvert" et "archivé".
   * 2. Si le projet est archivé et qu'il est le dernier projet actif pour son tiers parent,
   *    le tiers est également archivé.
   * 3. Si un projet archivé est réactivé et que le tiers parent est archivé,
   *    le tiers est réactivé pour l'utilisateur actuel (userId).
   * 4. Si le projet appartient à un CLIENT et est archivé,
   *    ses étapes associées sont également archivées.
   *
   * @param {string} projetId - L'identifiant unique du projet sélectionné.
   *
   * Prérequis :
   * - Le projet doit contenir un champ `tiers`, qui identifie le tiers parent.
   * - Le tiers doit avoir une structure `accesTiers`, qui contient les états d'accès
   *   par utilisateur (avec les champs `utilisateur` et `etat`).
   *
   * Exemple :
   * handleArchiveClick("12345abcd");
   */
  const handleArchiveClick = async (projetId) => {
    try {
      const projet = projets.find((projet) => projet._id === projetId);

      if (!projet) {
        console.error("Projet introuvable");
        return;
      }

      const tiersId = projet.tiers;

      const accesProjet = projet.accesProjet.find(
        (acces) => acces.utilisateur === userId
      );

      if (!accesProjet) {
        console.error("Accès projet pour l'utilisateur introuvable");
        return;
      }

      const nouvelEtat = accesProjet.etat === "ouvert" ? "archivé" : "ouvert";

      const projetResponse = await customAxios.put(`/api/projets/${projetId}`, {
        userId,
        etatAccessProjet: nouvelEtat,
      });

      if (projetResponse.data && projetResponse.data._id) {
        // Si le projet est réactivé, réinitialisez `selectedProjectId`
        if (nouvelEtat === "ouvert" && selectedProjectId === projetId) {
          setSelectedProjectId(null);
        }

        setListeProjets((prevProjets) =>
          prevProjets.filter((projet) => projet._id !== projetId)
        );

        logger.log(
          "ListeProjetsByUserId: handleArchiveClick projetId",
          projetId
        );

        handleProjetId(null);
        handleEtapeId(null);

        if (cibleProjets === "CLIENT" && nouvelEtat === "archivé") {
          const response = await customAxios.patch(
            `/api/projets/projet/${projetId}/archiverEtapes`
          );
          logger.log(
            "ListeProjetsByUserId: handleArchiveClick archiverEtapes response",
            response
          );
        }

        const responseProjets = await customAxios.get(
          `/api/projets/All/${tiersId}/true/${userId}/ouvert`
        );
        const projetsAssocies = responseProjets.data.projects;

        if (nouvelEtat === "archivé") {
          if (projetsAssocies.length === 0) {
            const tiersResponse = await customAxios.get(
              `/api/tiers/${tiersId}`
            );
            const tiers = tiersResponse.data;

            const accesTiers = tiers.accesTiers.find(
              (acces) => acces.utilisateur === userId
            );

            if (accesTiers && accesTiers.etat === "ouvert") {
              await customAxios.put(`/api/tiers/${tiersId}`, {
                userId,
                etatAccessTiers: "archivé",
              });
            }
          }
        } else if (nouvelEtat === "ouvert") {
          const tiersResponse = await customAxios.get(`/api/tiers/${tiersId}`);
          const tiers = tiersResponse.data;

          const accesTiers = tiers.accesTiers.find(
            (acces) => acces.utilisateur === userId
          );

          if (accesTiers && accesTiers.etat === "archivé") {
            await customAxios.put(`/api/tiers/${tiersId}`, {
              userId,
              etatAccessTiers: "ouvert",
            });
          }
        }
      } else {
        console.error("La mise à jour du projet a échoué.");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du projet :", error);
    }
  };

  return (
    <div>
      {/* On affiche ici le toggle les projets des cibles Clients ou les autres */}

      <div
        className="titre-zone2"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
        }}
      >
        <h6>PROJETS {cibleProjets}S</h6>
      </div>

      <div>
        <div className="center-toggle-switch">
          <ToggleSwitch />
        </div>
        <div className="cadre-zone2">
          {projets.length > 0 ? (
            <ul className="liste-sans-puces">
              {projets.map((projet, index) => (
                <li key={projet._id}>
                  <div
                    className="form-check d-flex ms-1 me-2"
                    onMouseEnter={() => setHoveredProjetId(projet._id)}
                    onMouseLeave={() => setHoveredProjetId("")}
                  >
                    <label
                      className={`ms-3 form-check-label ${
                        projet._id === selectedProjectId ? "label-gras" : ""
                      } ${
                        projet._id === hoveredProjetId ? "label-semi-gras" : ""
                      }`}
                      htmlFor={`checkbox-${projet._id}`}
                      onClick={() => handleLabelClick(projet._id)}

                      style={{ cursor: "pointer" }}
                    >
                      {projet.nom} ({projet.modeleName || "Chargement..."})
                    </label>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${projet._id}`}>
                          {isToggled
                            ? "Archiver le projet"
                            : "Réactiver le projet"}
                        </Tooltip>
                      }
                    >
                      <span>
                        {isToggled ? (
                          <HiOutlineArchiveBoxArrowDown
                            size="1.2em"
                            color="var(--bg-gris-clair)"
                            onClick={() => handleArchiveClick(projet._id)}
                          />
                        ) : (
                          <HiOutlineArchiveBoxXMark
                            size="1.2em"
                            color="var(--bg-gris-clair)"
                            onClick={() => handleArchiveClick(projet._id)}
                          />
                        )}
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucun projet trouvé.</p>
          )}
        </div>
      </div>
    </div>
  );
}
