import React, { useContext, useCallback, useState, useEffect } from "react";
import { CountProjetsByUserId } from "./Projets/CountProjetsByUserId";
import { CountModeles } from "./Modeles/CountModeles";
import { CountCatégories } from "./Catégories/CountCatégories";
import customAxios from "./Utilities/getAxiosToken";

import { CountAllProjetsClients } from "./Projets/CountAllProjetsClients";
import { CountAdminAgences } from "./Tiers/CountAdminAgences";
import { CountAdminPartenaires } from "./Tiers/CountAdminPartenaires";
import { CountListeTiers } from "./Tiers/CountListeTiers";
import { CountActions } from "./Actions/CountActions";
import { CountEtapesAttentes } from "./Etapes/CountEtapesAttentes";
import { CountAttentesTiers } from "./Attentes/CountAttentesTiers";

import { CountProjetsTiersByUserId } from "./Projets/CountProjetsTiersByUserId";
import { CountCorpsDeMétiers } from "./CorpsDeMétiers/CountCorpsDeMétiers";
import { BsStack    } from "react-icons/bs";
import { FaUserCog , FaChevronRight} from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import "./css/Zone1.css";
import "./css/Global.css";
import { AuthContext } from "../contexts/AuthProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ListItem({ icon, text, countComponent, onClick, tooltipContent }) {
  return (
    <OverlayTrigger
      placement="bottom"
      // overlay={<Tooltip id={`tooltip-${text}`}>{tooltipContent}</Tooltip>}
      overlay={<Tooltip >{tooltipContent}</Tooltip>}
    >
      <li
        onClick={onClick}
        className="ligne-espacée d-flex align-items-center souris-pointeur"
      >
        {icon}
        <span className="ms-2 me-1">{text}</span>
        {countComponent}
      </li>
    </OverlayTrigger>
  );
}

// Affiche les menus de la sone1 en fonction du role de l'utilsateur connecté

export function Zone1() {
  const {
    permissions,
    userId,
    userRole,
    handleProjetId,
    handleModeleId,
    debugAffichage,
    handleSelectedMenu,
  } = useContext(AuthContext);

  useEffect(() => {
    console.log("Zone1 monté");
    return () => {
      console.log("Zone1 démonté");
    };
  }, []);


  // Vérifie si un menu est accessible en fonction des permissions
  // const hasPermission = (menu) => permissions.includes(menu);

  const hasPermission = (menu) => {
    // logger.log("Zone 1 : Checking permission for menu:", menu);
    // logger.log("Zone 1 : Current permissions:", permissions);
    return permissions.includes(menu);
};

  const [showSubMenu, setShowSubMenu] = useState(true);

  // Là on récupère les projets de l'utilisateur en cours qui sont invalides
  //
  const checkInvalidProjects = useCallback(async () => {
    try {
      // Là on récupère tous les projets invalides

      // logger.log("Zone1 checkInvalidProjects de userid",userId );
      const invalidProjets = await customAxios.post(`/api/projets/InvalidProjetByUserid`,  {userId});
      // logger.log("Zone1 checkInvalidProjects invalidProjets ",invalidProjets );
      if (invalidProjets.data.length > 0) {
        const invalidProjects = invalidProjets.data.map(project => 
          `Projet ${project.nomProjet} : ${project.invalidSteps.join(', ')}`
        ).join('\n');
        alert(`Les projets suivants ne sont pas valides :\n${invalidProjects}`);
      }
    } catch (error) {
      console.error("Erreur lors de la vérification des projets invalides:", error);
    }
  }, []);

  const createClickHandler = useCallback(
    (menu) => async () => {
      await checkInvalidProjects();
      handleSelectedMenu(menu);
 
      handleProjetId(null); // réinitialiser le projetId ici
      handleModeleId(null);
    },
    [handleProjetId, handleModeleId, handleSelectedMenu, checkInvalidProjects]
  );


  // logger.log("Zone1 DEBUT isAgence", isAgence, "isPrestataire", isPrestataire);

  return (
    <div className="zone-1 marge-zones">
      {debugAffichage && <h4 className="zone-title">Zone 1 {userRole} </h4>}

      <ul className="liste-sans-puces">
        {/* PARTIE AGENCE */}

        {hasPermission("REQUÊTES") &&     <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="REQUÊTES"
              countComponent={<CountEtapesAttentes AllAgences={false} />}
              onClick={createClickHandler("REQUÊTES")}
              tooltipId="tooltip-requetes"
              tooltipContent="Voir toutes les requêtes"
            />
            }
          
          {hasPermission("REQUÊTES EXPRESS") && 
          <ListItem
              icon={<BsStack className ="couleur-icon double-size" />}
              text="REQUÊTES EXPRESS"
              onClick={createClickHandler("REQUÊTES EXPRESS")}
              tooltipId="tooltip-réponses-partenaires"
              tooltipContent="Déposer un nouveau document"
            />}
            


            {hasPermission("ATTENTES") &&  <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="ATTENTES"
              // countComponent={<CountEtapesAttentesTiers  typeTiers={"PARTENAIRE"}/>}
              countComponent={<CountAttentesTiers  typeTiers={"PARTENAIRE"}/>}
              onClick={createClickHandler("ATTENTES")}
              tooltipId="tooltip-attentes"
              tooltipContent="Voir les attentes"
            />}
            
           {hasPermission("PROJETS") && (
          <>
        
              <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="PROJETS"
              // On récupère @userId les projets en cours de ce userId
              countComponent={<CountProjetsTiersByUserId cibleProjets={"CLIENT"} />}
              onClick={() => setShowSubMenu(!showSubMenu)}
              tooltipId="tooltip-projets"
              tooltipContent="Voir tous les projets en cours"
            />
            {showSubMenu && (
                <>
                {hasPermission("PROJETS CLIENTS") &&
                  <ListItem
                    icon={<FaChevronRight style={{ marginLeft: '12px' }} />}
                    text="CLIENTS"
                    countComponent={<CountProjetsTiersByUserId cibleProjets={"CLIENT"} />}
                    onClick={createClickHandler("PROJETS CLIENTS")}
                    tooltipId="tooltip-projets-client"
                    tooltipContent="Voir tous les projets clients en cours"
                  />}
                {hasPermission("PROJETS PARTENAIRES") &&
                  <ListItem
                    icon={<FaChevronRight style={{ marginLeft: '12px' }} />}
                    text="PARTENAIRES"
                    countComponent={<CountProjetsTiersByUserId  cibleProjets={"PARTENAIRE"} />}
                    onClick={createClickHandler("PROJETS PARTENAIRES")}
                    tooltipId="tooltip-projets-partenaires"
                    tooltipContent="Voir tous les projets partenaires en cours"
                  />}
                {hasPermission("PROJETS AGENCE") &&
                  <ListItem
                    icon={<FaChevronRight style={{ marginLeft: '12px' }} />}
                    text="AGENCE"
                    countComponent={<CountProjetsTiersByUserId cibleProjets={"AGENCE"} />}
                    onClick={createClickHandler("PROJET AGENCE")}
                    tooltipId="tooltip-projets-partenaires"
                    tooltipContent="Voir tous les projets de l'agence en cours"
                  />}
                  
                  {hasPermission("PROJETS PRESTATAIRES") &&
                  <ListItem
                    icon={<FaChevronRight style={{ marginLeft: '12px' }} />}
                    text="PRESTATAIRES"
                    countComponent={<CountProjetsTiersByUserId cibleProjets={"PRESTATAIRE"} />}
                    onClick={createClickHandler("PROJETS PRESTATAIRES")}
                    tooltipId="tooltip-projets-partenaires"
                    tooltipContent="Voir tous les projets de l'agence en cours"
                  />}
                {hasPermission("PROJETS FOURNISSEURS") &&
                       <ListItem
                    icon={<FaChevronRight style={{ marginLeft: '12px' }} />}
                    text="FOURNISSEURS"
                    countComponent={<CountProjetsTiersByUserId cibleProjets={"FOURNISSEURS"} />}
                    onClick={createClickHandler("PROJETS FOURNISSEURS")}
                    tooltipId="tooltip-projets-forunisseurs"
                    tooltipContent="Voir tous les projets fournisseurs en cours"
                  />}
                </>
              )}
          </>)}

          {hasPermission("CLIENTS") && 
            <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="CLIENTS"
              countComponent={<CountListeTiers typeTiers="CLIENT" AllAgences={false} />}
              onClick={createClickHandler("CLIENTS")}
              tooltipId="tooltip-clients"
              tooltipContent="Voir tous les clients"
            />}

          {hasPermission("PARTENAIRES") &&
            <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="PARTENAIRES"
              countComponent={<CountListeTiers typeTiers="PARTENAIRE" etatDuTiers="ouvert"  AllAgences={false} />}
              onClick={createClickHandler("PARTENAIRES")}
              tooltipId="tooltip-partenaires"
              tooltipContent="Voir tous les partenaires"
            />}

          {hasPermission("SYNTHÈSES") &&
            <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="SYNTHÈSES"
              onClick={createClickHandler("SYNTHÈSES")}
              tooltipId="tooltip-syntheses"
              tooltipContent="Voir les synthèses"
            />}

 


        {/* PARTIE PARTENAIRE ou CLIENT */}
  

  
  
        {hasPermission("DÉPÔT EXPRESS") && 
          <ListItem
              icon={<BsStack className ="couleur-icon double-size" />}
              text="DÉPÔT EXPRESS"
              onClick={createClickHandler("DÉPÔT EXPRESS")}
              tooltipId="tooltip-réponses-partenaires"
              tooltipContent="Déposer un nouveau document"
            />}
  
      
   
        {/* PARTIE MANAGER D'AGENCE */}

        {hasPermission("ALL REQ") && (
            <>
              <hr />
                <ListItem
                  icon={<FaUserCog className="couleur-icon double-size" />}
                  text="ALL REQ"
                  countComponent={<CountEtapesAttentes AllAgences={true} />}
                  onClick={createClickHandler("ALL REQ")}
                  tooltipId="tooltip-all-req"
                  tooltipContent="Voir toutes les requêtes"
                  />
                </>
        )}

        {hasPermission("ALL SYNTHÈSES") &&
              <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL SYNTHÈSES"
              onClick={createClickHandler("ALL SYNTHÈSES")}
              tooltipId="tooltip-all-dashboard"
              tooltipContent="Voir le tableau de bord"
            />}

        {hasPermission("ALL PROJETS") &&
          <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL PROJETS"
              countComponent={<CountAllProjetsClients  cibleProjets={"CLIENT"} />}
              onClick={createClickHandler("ALL PROJETS")}
              tooltipId="tooltip-all-projets"
              tooltipContent="Voir tous les projets en cours"
            />}
        {hasPermission("ALL CLIENTS") &&
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL CLIENTS"
              countComponent={<CountListeTiers typeTiers="CLIENT" AllAgences={true} />}
              onClick={createClickHandler("ALL CLIENTS")}
              tooltipId="tooltip-all-projets"
              tooltipContent="Voir tous les clients en cours"
            />}

        {hasPermission("ALL PARTENAIRES") &&
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL PARTENAIRES"
              countComponent={<CountListeTiers typeTiers="PARTENAIRE"  AllAgences={true} />}
              onClick={createClickHandler("ALL PARTENAIRES")}
              tooltipId="tooltip-all-projets"
              tooltipContent="Voir tous les partenaires en cours"
            />}

        {hasPermission("ALL DOCUMENTS") &&
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL DOCUMENTS"
              onClick={createClickHandler("ALL DOCUMENTS")}
              tooltipId="tooltip-all-documents"
              tooltipContent="Voir tous les documents"
            />}


          {/* Liste tous les fichiers du partenaire des projets en cours et des projets terminés */}
          {hasPermission("MES PROJETS") &&
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="MES PROJETS"
              onClick={createClickHandler("MES PROJETS")}
              tooltipId="tooltip-all-documents"
              tooltipContent="Voir tous les documents"
            />}
            
          {hasPermission("MES DOCUMENTS") &&
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="MES DOCUMENTS"
              onClick={createClickHandler("MES DOCUMENTS")}
              tooltipId="tooltip-all-documents"
              tooltipContent="Voir tous les documents"
            />}

        {hasPermission("ALL PRESTATAIRES") &&
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL PRESTATAIRES"
              countComponent={<CountListeTiers typeTiers="PRESTATAIRE" AllAgences={true} />}
              onClick={createClickHandler("ALL PRESTATAIRES")}
              tooltipId="tooltip-all-prestataires"
              tooltipContent="Voir tous les prestataires"
            />}


        {/* PARTIE ADMINISTRATEUR */}
        {hasPermission("ADMIN PARTENAIRES") &&
              <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN PARTENAIRES"
              countComponent={<CountAdminPartenaires typeTiers="PARTENAIRE" />}
              onClick={createClickHandler("ADMIN PARTENAIRES")}
              tooltipId="tooltip-admin-agences-admin"
              tooltipContent="Voir l'administration des partenaires"
              />}
        {hasPermission("ADMIN MODÈLES") &&
              <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN MODÈLES"
              countComponent={<CountModeles />}
              onClick={createClickHandler("ADMIN MODÈLES")}
              tooltipId="tooltip-admin-modeles-admin"
              tooltipContent="Voir l'administration des modèles"
              />}
        {hasPermission("ADMIN MÉTIERS") &&
              <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN MÉTIERS"
              countComponent={<CountCorpsDeMétiers />}
              onClick={createClickHandler("ADMIN MÉTIERS")}
              tooltipId="tooltip-admin-metiers-admin"
              tooltipContent="Voir l'administration des métiers"
              />}
        {hasPermission("ADMIN ACTIONS") &&
              <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN ACTIONS"
              countComponent={<CountActions />}
              onClick={createClickHandler("ADMIN ACTIONS")}
              tooltipId="tooltip-admin-actions-admin"
              tooltipContent="Voir l'administration des actions"
              />}
        {hasPermission("ADMIN CATÉGORIES") &&
              <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN CATÉGORIES"
              countComponent={<CountCatégories />}
              onClick={createClickHandler("ADMIN CATÉGORIES")}
              tooltipId="tooltip-admin-categories"
              tooltipContent="Voir l'administration des catégories"
              />}
        {hasPermission("ADMIN AGENCES") &&
              <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN AGENCES"
              countComponent={<CountAdminAgences typeTiers="AGENCE" />}
              onClick={createClickHandler("ADMIN AGENCES")}
              tooltipId="tooltip-admin-agences-admin"
              tooltipContent="Voir l'administration des agences"
              />}
        {hasPermission("ADMIN RÔLES") &&
              <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN RÔLES"
              countComponent={<CountActions />}
              onClick={createClickHandler("ADMIN RÔLES")}
              tooltipId="tooltip-admin-roles"
              tooltipContent="Voir l'administration des rôles et permissions"
              />}

      </ul>
    </div>
  );
}
