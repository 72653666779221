import React, { useContext, useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import "./css/ProjetNomByProjetId.css";
import { useWebSocket } from "../../contexts/WebSocketProvider";

import { useProjet } from "../../contexts/ProjetContext";
import { AuthContext } from "../../contexts/AuthProvider";
import { ListeEtapes } from "../Etapes/ListeEtapes";
import { ListeFichiersProjet } from "../Fichiers/ListeFichiersProjet";
import { ProjetNote } from "../Projets/ProjetNote";
import UnreadFileCounter from "../Fichiers/UnreadFileCounter"; // Importer le composant
import customAxios from "../Utilities/getAxiosToken";
import logger from "../Utilities/Logger";

export function ProjetNomByProjetId() {
  const { projetData } = useProjet();
  const { userId, handleVueDocument } = useContext(AuthContext);
  const [showDocuments, setShowDocuments] = useState(false);
  const [files, setFiles] = useState([]); // Stocker les fichiers du projet
  const { socket } = useWebSocket();
  console.log("ProjetNomByProjetId userId", userId, projetData);

  const accesUtilisateur = projetData?.accesProjet?.find(
    (acces) => acces.utilisateur === userId
  );

  const isArchived = accesUtilisateur?.etat === "archivé";

  // Fonction pour basculer entre les vues et enregistrer le changement
  const toggleView = () => {
    const nextView = showDocuments ? "VUE PROJET" : "VUE DOCUMENTS";
    handleVueDocument(nextView); // Enregistrer le changement de vue dans AuthContext
    setShowDocuments((prev) => !prev);
  };


  const fetchFiles = useCallback(async () => {
    if (!projetData || !projetData._id) {
      logger.log("ProjetNomByProjetId fetchFiles: ProjetData non défini");
      return;
    }
  
    try {
      const response = await customAxios.get(
        `/api/files/projet/${projetData._id}`
      );
      logger.log("ProjetNomByProjetId fetchFiles response", response);
      setFiles(response.data); // Stocker les fichiers récupérés
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Gérer l'erreur 404 en mettant un tableau vide ou un autre indicateur
        logger.warn("Aucun fichier trouvé pour ce projet.");
        setFiles([]); // Ou utilisez `setFiles(0)` si vous attendez un nombre
      } else {
        // Gérer d'autres types d'erreurs
        console.error("Erreur lors de la récupération des fichiers :", error);
      }
    }
  }, [projetData]);



    useEffect(() => {
      if (!projetData || !projetData._id) return;
  
      const handleSocketUpdate = () => fetchFiles();
      socket.on("updateFile", handleSocketUpdate);
  
      return () => {
        logger.log("ListeAttentesAgences: Nettoyage des écouteurs WebSocket");
        socket.off("updateFile", handleSocketUpdate);
      };
  
    }, [socket, fetchFiles, projetData?._id]);

  // Réinitialiser la vue à "VUE PROJET" lorsque projetData change
  useEffect(() => {
    if (projetData) {
      fetchFiles();
      handleVueDocument("VUE PROJET");
      setShowDocuments(false); // Réinitialise toujours la vue à "VUE PROJET"
    }
  }, [projetData]);

  if (!projetData || !projetData._id || !projetData.nom) {
    return <h5 className="titre-zone3">Nom du projet indisponible</h5>;
  }

  return (
    <div className="projet-nom-container">
      <div className="d-flex justify-content-between align-items-center">
        <h5
          className="titre-zone3"
          style={{ textAlign: "center", margin: "20px" }}
        >
          {showDocuments ? "DOCUMENTS" : projetData.nom}{" "}
          {isArchived && (
            <span
              style={{
                fontSize: "0.9em",
                color: "red",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              [Archivé]
            </span>
          )}
        </h5>
        <button
          className="custom-toggle-button btn btn-outline-primary btn-sm"
          onClick={toggleView}
        >
          {showDocuments ? (
            "Voir Projet"
          ) : (
            <>
              Voir Documents
              {files.length > 0 && (
                <>
                  {" ("}
                  <UnreadFileCounter files={files} userId={userId} />
                  {")"}
                </>
              )}
            </>
          )}
        </button>
      </div>

      <div>
        {showDocuments ? (
          <ListeFichiersProjet />
        ) : (
          <>
            <ProjetNote />
            <hr />
            <ListeEtapes />
          </>
        )}
      </div>
    </div>
  );
}
