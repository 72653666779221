import React, { useEffect, useState, useContext } from "react";
import { Navbar } from "react-bootstrap";
import { BsPersonFill, BsBoxArrowRight } from "react-icons/bs";

import styles from "./css/Header.css"; // Utilisation de CSS Modules
import { UtilisateurSelecteur } from "../Utilisateurs/UtilisateurSelecteur";
import { AdminTiersSelecteur } from "../Tiers/AdminTiersSelecteur";
import { CheckExpirationDateOfToken } from "../Session/CheckExpirationDateOfToken";

import { AuthContext } from "../../contexts/AuthProvider";

export function Header() {
  const [leaderName, setLeaderName] = useState("");
  const [leaderLastName, setLeaderLastName] = useState("");

  const {
    user,
    userId,
    handleLogout,

  } = useContext(AuthContext);



  useEffect(() => {
    const fetchLeaderInfo = async () => {
      try {
        const userNom = user.nom;
        const userPrenom = user.prenom;

        setLeaderName(userNom);
        setLeaderLastName(userPrenom);
      } catch (error) {
        console.error(error);
      }
    };

    if (userId) {
      fetchLeaderInfo();
    }
  }, [userId]);

  return (
        <Navbar expand="xl" 
        className="d-flex justify-content-between "
        style={{
          borderRadius: "10px",
          marginBottom: "20px",

        }}
  
        >

      <Navbar.Brand href="#">
        <span
          style={{ fontSize: "35px", paddingLeft: "40px", fontWeight: 500, color : "var(--texte-zone)" }}
        >
          iAkka...
        </span>
      </Navbar.Brand>

      <div className="d-flex justify-content-center flex-grow-1"
        style={{  fontSize: "1em", fontWeight: "400", color : "var(--texte-zone)" }}>
        <AdminTiersSelecteur />
        <UtilisateurSelecteur />
      </div>

      <div className="d-flex justify-content-end align-items-center"
       style={{ paddingRight: "20px" }}
      >
        <span className="mr-2 " style={{ paddingRight: "10px" , color : "var(--texte-fond)"}}>
          {userId ? `${leaderLastName} ${leaderName}` : ""}
        </span>

        {userId ? (
          <>
            <CheckExpirationDateOfToken />
            <BsBoxArrowRight
              className="logout double-size ml-2 title-quick"
              onClick={handleLogout}
              color = "var(--texte-fond)"
              title="Se déconnecter"
            />
          </>
        ) : (
          <>
            <BsPersonFill
              className="login double-size title-quick"
              title="Se connecter"
            />
            <span className="badge bg-rouge rounded-pill">!</span>
          </>
        )}
      </div>
    </Navbar>
  );
}