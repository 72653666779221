import React, { useState, useEffect, useContext } from "react";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsDownload, BsEye, BsEyeSlash, BsInfoCircle } from "react-icons/bs";
import { HiOutlineArchiveBoxArrowDown } from "react-icons/hi2";
import { useWebSocket } from "../../contexts/WebSocketProvider";

import customAxios from "../Utilities/getAxiosToken";
import { useProjet } from "../../contexts/ProjetContext";
import { AuthContext } from "../../contexts/AuthProvider";

import useHandleFileClick from "../Utilities/GetFiles";
import FilePreviewModal from "./FilePreviewModal"; // Modal de prévisualisation

export const ListeFichiersProjet = () => {
  const { downloadFile } = useHandleFileClick();
  const { userId, handleToggleDestinataires, handleSelectedFilesIds, projetId, selectedFilesIds, resetSelectedFilesIds } = useContext(AuthContext); // Utilisateur actuel
  const [fichiers, setFichiers] = useState([]);
  const [activeRubriques, setActiveRubriques] = useState({});
  const [activePrefixes, setActivePrefixes] = useState({});
  const { projetData } = useProjet();
  const [filePreviewId, setFilePreviewId] = useState(null); // ID du fichier en preview

  const { socket } = useWebSocket();

  // Marquer un fichier comme lu
  const markFileAsRead = async (fileId) => {
    try {
      await customAxios.patch(`/api/files/markAsRead/${fileId}`, {
        userId,
        readDate: new Date().toISOString(),
      });
      fetchFichiers();
    } catch (error) {
      console.error("ListeFichiersProjet: Erreur lors de la mise à jour de lastReadDate :", error);
    }
  };

  // Marquer un fichier comme non lu
  const markFileAsUnread = async (fileId) => {
    try {
      await customAxios.patch(`/api/files/markAsUnread/${fileId}`, { userId });
      fetchFichiers();
    } catch (error) {
      console.error("ListeFichiersProjet: Erreur lors de la mise à jour de lastReadDate :", error);
    }
  };

  // Ouvrir la prévisualisation d'un fichier
  const handlePreviewFile = async (fileId) => {
    await markFileAsRead(fileId); // Marquer comme lu
    setFilePreviewId(fileId); // Ouvrir la modal
  };

  const closePreviewModal = () => setFilePreviewId(null); // Fermer la modal

  const fetchFichiers = async () => {
    try {
      const response = await customAxios.get(`/api/files/projet/${projetData._id}`);
      setFichiers(groupByRubriqueAndPrefixe(response.data));
    } catch (error) {
      console.error("ListeFichiersProjet: Erreur lors de la récupération des fichiers :", error);
      setFichiers([]);
    }
  };

  const groupByRubriqueAndPrefixe = (data) => {
    const grouped = data.reduce((acc, fichier) => {
      const rubrique = fichier.rubrique || "Sans rubrique";
      const prefixe = fichier.prefixe || "Sans préfixe";

      acc[rubrique] = acc[rubrique] || {};
      acc[rubrique][prefixe] = acc[rubrique][prefixe] || [];
      acc[rubrique][prefixe].push(fichier);
      return acc;
    }, {});

    return grouped;
  };

  const toggleRubrique = (rubrique) => {
    setActiveRubriques((prev) => ({ ...prev, [rubrique]: !prev[rubrique] }));
  };

  const togglePrefixe = (rubrique, prefixe) => {
    setActivePrefixes((prev) => ({
      ...prev,
      [rubrique]: { ...prev[rubrique], [prefixe]: !prev[rubrique]?.[prefixe] },
    }));
  };

  const handleCheckboxChange = (fileId) => {
    handleSelectedFilesIds(fileId); // Ajoute ou retire l'ID du fichier sélectionné
    handleToggleDestinataires(true);
  };

  const countUnreadFiles = (files) => {
    const unreadFiles = files.filter(
      (file) =>
        !file.metadata.lastReadDate ||
        !file.metadata.lastReadDate.some(
          (entry) => entry.utilisateur === userId
        )
    );
  
    // Log les fichiers non lus et leur nombre
    logger.log("ListeFichiersProjet: Fichiers non lus:", unreadFiles.map(file => file._id));
    logger.log("ListeFichiersProjet: Nombre total de fichiers non lus:", unreadFiles.length);
  
    return unreadFiles.length;
  };

    // Active ou désactive le bouton destinataires en fonction des cases activées
    useEffect(() => {
      if (selectedFilesIds.size === 0) {
        handleToggleDestinataires(false); // Aucune case activée
      } else {
        handleToggleDestinataires(true); // Une ou plusieurs cases activées
      }
    }, [selectedFilesIds, handleToggleDestinataires]);

  useEffect(() => {
    if (!projetData || !projetData._id) return;
    fetchFichiers();

    const handleSocketUpdate = () => fetchFichiers();
    socket.on("updateFile", handleSocketUpdate);
    // socket.on("updateEtape", handleSocketUpdate);
    // socket.on("updateProjet", handleSocketUpdate);



    return () => {
      logger.log("ListeAttentesAgences: Nettoyage des écouteurs WebSocket");
      socket.off("updateFile", handleSocketUpdate);
      // socket.off("updateEtape", handleSocketUpdate);
      // socket.off("updateProjet", handleSocketUpdate);
    };

  }, [projetData?._id]);

  if (!projetData || !projetData._id || !projetData.nom) {
    return <h5 className="titre-zone3">Nom du projet indisponible</h5>;
  }

  return (
    <div className="cadre-zone4" style={{ marginBottom: "5px" }}>
      {Object.keys(fichiers).length > 0 ? (
        Object.entries(fichiers).map(([rubrique, prefixeMap]) => {
          const unreadFilesInRubrique = countUnreadFiles(
            Object.values(prefixeMap).flat()
          ); // Total des non lus dans la rubrique
          return (
            <div key={rubrique} style={{ cursor: "pointer", fontWeight: "400" }}>
              <div onClick={() => toggleRubrique(rubrique)} style={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
                {activeRubriques[rubrique] ? <FiChevronDown /> : <FiChevronRight />}
                <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                {rubrique}{" "}
                    {unreadFilesInRubrique > 0 && (
                      // <Badge bg="danger">{unreadFilesInRubrique}</Badge>
                      <span className="badge badge-info">{unreadFilesInRubrique}</span> 
                    )}
                    
                    </span>
              </div>
              {activeRubriques[rubrique] &&
                Object.entries(prefixeMap).map(([prefixe, fichiers]) => {
                  const unreadFilesInPrefixe = countUnreadFiles(fichiers);
                   return (
                    <div key={prefixe} style={{ paddingLeft: "20px" }}>
                      <div
                        onClick={() => togglePrefixe(rubrique, prefixe)}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {activePrefixes[rubrique]?.[prefixe] ? <FiChevronDown /> : <FiChevronRight />}
                        <span style={{ marginLeft: "5px" ,  fontWeight:
                              unreadFilesInPrefixe > 0 ? "bold" : "normal"
                              }}>
                               {prefixe}{" "}
                                  {unreadFilesInPrefixe > 0 && (
                                    // <Badge bg="warning" text="dark">
                                    //   {unreadFilesInPrefixe}
                                    // </Badge>
                                    <span className="badge badge-info">{unreadFilesInPrefixe}</span> 
                                  )}
                                                     
                                </span>
                      </div>
                      {activePrefixes[rubrique]?.[prefixe] &&
                        fichiers.map((fichier) => {
                          const isUnread =
                            !fichier.metadata.lastReadDate ||
                            !fichier.metadata.lastReadDate.some((entry) => entry.utilisateur === userId);

                          return (
                            <div
                              key={fichier._id}
                              style={{
                                paddingLeft: "40px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                  className="form-check-input me-1"
                                  type="checkbox"
                                  checked={selectedFilesIds.has(fichier._id)}
                                  onChange={() => handleCheckboxChange(fichier._id)}
                                />
                                <span style={{ fontWeight: isUnread ? "bold" : "normal" }}
                                  onClick={() => handlePreviewFile(fichier._id)}>
                                  {fichier.nomTiers} {new Date(fichier.uploadDate).toLocaleString()}
                                </span>
                              </div>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-read-${fichier._id}`}>
                                      {isUnread ? "Prévisualiser et marquer comme lu" : "Marquer comme non lu"}
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    onClick={() =>
                                      isUnread
                                        ? handlePreviewFile(fichier._id)
                                        : markFileAsUnread(fichier._id)
                                    }
                                    style={{ cursor: "pointer", margin: "0 5px" }}
                                  >
                                    {isUnread ? <BsEyeSlash size="1.4em" /> : <BsEye size="1.4em" />}
                                  </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id={`tooltip-download-${fichier._id}`}>Télécharger</Tooltip>}
                                >
                                  <span
                                    onClick={() => downloadFile(fichier._id)}
                                    style={{ cursor: "pointer", margin: "0 5px" }}
                                  >
                                    <BsDownload size="1.4em" />
                                  </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id={`tooltip-download-${fichier._id}`}>Archive</Tooltip>}
                                >
                                  <span
                                    // onClick={() => downloadFile(fichier._id)}
                                    style={{ cursor: "pointer", margin: "0 5px" }}
                                  >
                                    <HiOutlineArchiveBoxArrowDown size="1.4em" />
                                  </span>
                                </OverlayTrigger>

                                

                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          );
        })
      ) : (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <p>Pas de documents disponibles pour ce projet.</p>
        </div>
      )}
      {filePreviewId && <FilePreviewModal fileId={filePreviewId} closeModal={closePreviewModal} />}
    </div>
  );
};