
import React, { useContext, useState, useEffect } from "react";
import "./../css/Global.css";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { useProjet } from '../../contexts/ProjetContext';
//
// utilisateur Sélectionné
//
export function CreateurByProjetId() {
  const { utilisateurId, handleLogout, projetId } =
    useContext(AuthContext);

  const { projetData } = useProjet();
 
  const [createur, setCreateur] = useState({});

  logger.log("CreateurByProjetId Main projetId", projetId);

  const fetchCreateurByProjetId = async () => {
    try {
      if (projetId) {
        logger.log("CreateurByProjetId: projetId:", projetId);

        const createurId = projetData.createur;
        
        if (createurId) {
          logger.log(
            "CreateurByProjetId fetchCreateurByProjetId createurId",
            createurId
          );
          const response = await customAxios.get(
            `/api/utilisateurs/${createurId}?isActif=true`
          );
          logger.log(
            "CreateurByProjetId fetchUtilisateurByid createurId",
            createurId,
            "response.data",
            response.data,
            "Taille",
            response.data.count
          );
          // setUtilisateur(response.data); // On récupère le nom du Projet

          setCreateur(response.data); // On récupère le nom du Projet
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logger.log(`CreateurByProjetId: erreur 401 pas d'accès Token expiré`);
        // Déconnexion de l'utilisateur et redirection vers la page de login
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    logger.log("CreateurByProjetId useEffect projetId", projetId);
    fetchCreateurByProjetId();
  }, [projetData]);

  return (
    // <div className="cadre-zone4 contenu-zone4 center-content">
       <div className="cadre-zone4 center-content">
          {createur.prenom} {createur.nom}

    </div>
  );
  
}
