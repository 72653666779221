import React, { useState, useEffect, useContext, useRef } from "react";
import {
  BsArrowDown,
  BsArrowUp,
  BsPencil,
  BsFiles,
  BsTrash,
  BsCheck,
  BsX,
} from "react-icons/bs";


import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "../css/Global.css";

export function GestionCorpsDeMétiers() {
 
  const { handleLogout, newCorpsDeMetier, handleAddNewCorpsDeMetier, searchTerm } = useContext(AuthContext);
  const [corpsDeMetiers, setCorpsDeMetiers] = useState([]);
  const [editModeIndex, setEditModeIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({
    _id: "",
    label: "",
    famille: "",
    description: "",
  });

  const [sortField, setSortField] = useState("label");
  const [sortOrder, setSortOrder] = useState("asc"); // Ou 'desc' si vous préférez un tri descendant
  const [familles, setFamilles] = useState([]);
  // const [searchTerm, setSearchTerm] = useState(""); // État pour le terme de recherche
  const [showSearch, setShowSearch] = useState(false);


  const inputRef = useRef(null);

  const toggleSearch = () => setShowSearch(!showSearch);


  // On ajoute un nouveau corps de métiers

  const handleAddCorpsDeMetier = async () => {
    if (newCorpsDeMetier) {
      // Détermine la famille par défaut
      const defaultFamille = familles.length > 0 ? familles[0] : "Nouvelle Famille";
  
      const newMetier = {
        _id: undefined,  // Supposé être défini par le backend après l'enregistrement
        label: 'ATTENTION NOUVEAU CORPS DE METIER',
        famille: defaultFamille,
        description: 'Description à renseigner'
      };
  
      try {
        logger.log("Ajout d'un nouveau corps de métier :", newMetier);
        const response = await customAxios.post(`/api/corpsdemetiers`, newMetier);
        const savedMetier = response.data;
        logger.log("Nouveau corps de métier ajouté avec succès:", savedMetier);
  
        // Ajoute le nouveau métier à l'état local après l'avoir enregistré avec succès
        setCorpsDeMetiers(prevCorpsDeMetiers => [...prevCorpsDeMetiers, savedMetier]);
  
        // Passer en mode édition pour le nouvel élément
        setEditModeIndex(corpsDeMetiers.length);
        setEditFormData(savedMetier);
        handleAddNewCorpsDeMetier(false);
      } catch (error) {
        console.error("Erreur lors de l'ajout du nouveau corps de métier:", error);
        alert("Erreur lors de l'ajout du nouveau corps de métier. Veuillez réessayer.");
      }
    } else {
      alert("Vous n'êtes pas autorisé à ajouter de nouveaux corps de métiers.");
    }
  };
  

  useEffect(() => {
    if (editModeIndex !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editModeIndex]);
  

  useEffect(() => {
    fetchCorpsDeMetiers();
    if (newCorpsDeMetier) {
      handleAddCorpsDeMetier();
    }
  }, [sortField, sortOrder, newCorpsDeMetier]);

  const fetchCorpsDeMetiers = async () => {
    try {
      const response = await customAxios.get(
        `/api/corpsdemetiers`
      );
      logger.log("GestionCorpsDeMétiers fetchCorpsDeMetiers sortField", sortField);

      let data = response.data;
      if (sortField) {
        data = data.sort((a, b) => {
          if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
          if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
          return 0;
        });
      }
      setCorpsDeMetiers(data);
      extractFamilles(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error(
          "Erreur lors de la récupération des corps de métiers:",
          error
        );
      }
    }
  };

  const extractFamilles = (data) => {
    const famillesUniques = [...new Set(data.map((item) => item.famille))];
    const sortedFamilles = famillesUniques.sort((a, b) => {
        return a > b ? 1 : -1;
    });
    setFamilles(sortedFamilles);
};

const handleDelete = async (id) => {
  // Pop-up de confirmation avant de procéder à la suppression
  const userConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce corps de métier ?");
  if (userConfirmed) {
    // Si l'utilisateur confirme la suppression, exécuter le code de suppression
    try {
      await customAxios.delete(`/api/corpsdemetiers/${id}`);
      fetchCorpsDeMetiers(); // Rafraîchit la liste des corps de métiers après la suppression
    } catch (error) {
      console.error("Erreur lors de la suppression du corps de métier:", error);
    }
  } else {
    // Si l'utilisateur annule, ne rien faire
    logger.log("GestionCorpsDeMétiers Suppression annulée par l'utilisateur.");
  }
};





  const handleDuplicateClick = async (index, corpsDeMetier) => {
    // Créer une copie de l'objet sans inclure l'ID
    const corpsDeMetierToDuplicate = { ...corpsDeMetier, _id: undefined };

    try {
      // Envoyer la copie au serveur pour créer un nouveau corps de métier
      const response = await customAxios.post(
        `/api/corpsdemetiers`,
        corpsDeMetierToDuplicate
      );

      let newEntry = response.data;
      newEntry.isTemporary = true; // Marquer la nouvelle entrée comme temporaire

      // Insérer la nouvelle ligne directement sous l'index de l'élément dupliqué
      let updatedCorpsDeMetiers = [...corpsDeMetiers];
      updatedCorpsDeMetiers.splice(index + 1, 0, newEntry);
      setCorpsDeMetiers(updatedCorpsDeMetiers);

      // Mettre la nouvelle ligne en mode édition
      setEditModeIndex(index + 1); // Ajustez pour se concentrer sur l'élément nouvellement ajouté
      setEditFormData({ ...newEntry }); // Utiliser newEntry qui inclut maintenant isTemporary et _id
    } catch (error) {
      console.error("Erreur lors de la duplication du corps de métier:", error);
    }
  };

  const handleEditClick = (index, corpsDeMetier) => {
    logger.log("GestionCorpsDeMétiers handleEditClick", corpsDeMetier);
    setEditModeIndex(index);
    setEditFormData(corpsDeMetier);
    logger.log(
      "GestionCorpsDeMétiers handleEditClick setEditFormData",
      corpsDeMetier
    );
  };

  const forceSortOnLabel = () => {
    setSortField("label");
    setSortOrder("asc"); // ou 'desc' selon l'ordre désiré
  };

  const handleSaveClick = async () => {
    logger.log(
      "GestionCorpsDeMétiers handleSaveClick editFormData 1 editModeIndex",
      editModeIndex,
      editFormData
    );

    // Vérifier si nous sommes en mode édition ou ajout d'une nouvelle entrée
    if (editModeIndex != null && editFormData._id) {
      // Mise à jour d'une entrée existante
      try {
        logger.log(
          "GestionCorpsDeMétiers handleSaveClick editFormData 2",
          editFormData
        );
        await customAxios.put(
          `/api/corpsdemetiers/${editFormData._id}`,
          editFormData
        );
        fetchCorpsDeMetiers(); // Rafraîchir la liste après la mise à jour
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour du corps de métier:",
          error
        );
      }
    } else {
      // Gérer ici l'ajout d'une nouvelle entrée si nécessaire
    }

    // Sortir du mode édition
    setEditModeIndex(null);
    setEditFormData({ _id: "", label: "", famille: "", description: "" }); // Réinitialiser le formulaire
    // Rafraîchir et trier la liste
    fetchCorpsDeMetiers(); // Cela va rafraîchir et appliquer le tri actuel
    forceSortOnLabel(); // Force le tri sur 'label' après la mise à jour
  };

  const handleCancelClick = () => {
    if (editModeIndex != null) {
      const isTemporary = corpsDeMetiers[editModeIndex]?.isTemporary;
      if (isTemporary) {
        // Supprimer l'entrée non sauvegardée
        const updatedCorpsDeMetiers = corpsDeMetiers.filter(
          (_, i) => i !== editModeIndex
        );
        setCorpsDeMetiers(updatedCorpsDeMetiers);
      }
    }
    setEditModeIndex(null);
  };

  const handleFieldChange = (e, field) => {
    logger.log("GestionCorpsDeMétiers: handleFieldChange field", field," value",  e.target.value);
    setEditFormData({ ...editFormData, [field]: e.target.value });
  };


  // Gère le clic sur l'en-tête pour trier
  const handleSort = (field) => {
    setSortOrder(sortField === field && sortOrder === "asc" ? "desc" : "asc");
    setSortField(field);
  };



  const SortableTableHeader = ({ field, children }) => {
    return (
      <th
        onClick={() => handleSort(field)}
        style={{ textAlign: 'center', cursor: "pointer",}}
      >
        {children}
        {sortField === field &&
          (sortOrder === "asc" ? <BsArrowUp /> : <BsArrowDown />)}
      </th>
    );
  };

    // Gestion de la recherche et du tri
    const filteredCorpsDeMetiers = corpsDeMetiers.filter(
      metier =>
        metier.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
        metier.famille.toLowerCase().includes(searchTerm.toLowerCase()) ||
        metier.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleNewFamille = (e) => {
    if (e.target.value === "autre") {
      const newFamilleName = prompt("Entrez le nom de la nouvelle famille:");
      if (newFamilleName && !familles.includes(newFamilleName.trim())) {
        const updatedFamilles = [...familles, newFamilleName.trim()].sort((a, b) => a.localeCompare(b));
        setFamilles(updatedFamilles);
        setEditFormData({ ...editFormData, famille: newFamilleName.trim() });
      } else if (newFamilleName) {
        alert("Cette famille existe déjà ou le nom est invalide.");
      }
      e.target.value = newFamilleName.trim(); // Assurez-vous de mettre à jour le menu déroulant avec la nouvelle famille
    }
  };

  
  return (
    <div
      // className="cadre-zone2-requetes"
      // Nécessaire pour passer la fenetre en arrière plan
      style={{ zIndex: "0" }}
    >
      <div
        className="titre-zone2 "
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "90%",
        }}
      >
      <h5>CORPS DE MÉTIERS</h5>
      </div>


      <table 
           style={{
            width: "100%",
          }}>
        <thead className="table-header">
          <tr>
            <SortableTableHeader field="label"
                   style={{
                    width: "27%",
                  }}>LABEL</SortableTableHeader>
            <SortableTableHeader field="famille"
                   style={{
                    width: "20%",
                  }}>FAMILLE</SortableTableHeader>
            <SortableTableHeader
              field="description"
              style={{
                width: "45%",
              }}
            >
              DESCRIPTION
            </SortableTableHeader>
            <th
              style={{
                width: "8%",
              }}
            >
              ACTIONS
            </th>
          </tr>
        </thead>
        <tbody className="table-body" style={{   fontSize: "var(--fs-normale)" }}>
        {filteredCorpsDeMetiers.map((corpsDeMetier, index) => (
          // {corpsDeMetiers.map((corpsDeMetier, index) => (
            <tr
              className={index % 2 === 0 ? "bg-custom" : ""}
              key={corpsDeMetier._id}
            >
              <td
                style={{
                  borderRadius: "6px 0 0 6px",
                  paddingLeft: "10px",
                }}
              >
                {editModeIndex === index ? (
                  <input
                  ref={inputRef} // Attache la référence ici
                    type="text"
                    value={editFormData.label}
                    onChange={(e) => handleFieldChange(e, "label")}
                  />
                ) : (
                  corpsDeMetier.label
                )}
              </td>
              <td style={{
                //  fontSize: "var(--fs-plus-petite)", 
                //  fontSize: "var(--fs-normale)", 
              padding:'4px',
              }}
              
              >
                {editModeIndex === index ? (
                  <select
                      value={editFormData.famille}
                      onChange={(e) => handleFieldChange(e, "famille")}
                      onBlur={(e) => handleNewFamille(e)}
                    >
                      {familles.map((famille) => (
                        <option key={famille} value={famille}>
                          {famille}
                        </option>
                      ))}
                      <option value="autre">Ajouter une nouvelle famille...</option>
                    </select>

                ) : (
                  corpsDeMetier.famille
                )}
              </td>
              <td
                style={{
                  width: "45%",
                }}
              >
                {editModeIndex === index ? (
                  <input
                    type="text"
                    value={editFormData.description}
                    onChange={(e) => handleFieldChange(e, "description")}
                  />
                ) : (
                  corpsDeMetier.description
                )}
              </td>
              <td
                style={{
                  width: "8%",
                  borderRadius: "0 6px 6px 0",
                }}
              >

              {editModeIndex === index ? (
                    <>
                      <BsCheck
                        onClick={() => handleSaveClick(corpsDeMetier._id)}
                        style={{ cursor: "pointer", margin: "0 5px" }}
                      />
                      <BsX
                        onClick={handleCancelClick}
                        style={{ cursor: "pointer", margin: "0 5px" }}
                      />
                    </>
                  ) : (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>Duplication du corps de métier.</Tooltip>}
                      >
                        <span>
                          <BsFiles
                            size="1.2em"
                            onClick={() => handleDuplicateClick(index, corpsDeMetier)}
                            style={{ cursor: "pointer", margin: "0 5px" }}
                          />
                        </span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>Édition du corps de métier.</Tooltip>}
                      >
                        <span>
                          <BsPencil
                            size="1.2em"
                            onClick={() => handleEditClick(index, corpsDeMetier)}
                            style={{ cursor: "pointer", margin: "0 5px" }}
                          />
                        </span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>Suppression du corps de métier.</Tooltip>}
                      >
                        <span>
                        <BsTrash
                          size="1.2em"
                          onClick={() => handleDelete(corpsDeMetier._id)}
                          style={{ cursor: "pointer", margin: "0 5px" }}
                        />
                        </span>
                      </OverlayTrigger>
                    </>
                  )}

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
