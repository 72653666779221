import React, { useEffect, useCallback, useState, useContext } from "react";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { iconRendering } from "../Utilities/iconRendering"; //  Accès aux Picto en fonction du type daction
import { useWebSocket } from "../../contexts/WebSocketProvider";

import {
  BsArchive,
  BsLightning,
  BsClock,
  BsPeople,
  BsListCheck,
  BsFileEarmarkPerson,
} from "react-icons/bs";

import useGetModeles from "../Utilities/GetModeles";
import ReactDatePicker from "react-datepicker";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "../css/Global.css";

import {
  activateProject,
  deactivateProject,
} from "../Utilities/ActivateProjet";

// Si AgenceAuComplet si true tous les éléments de l'agence
// Si false seulement le userId

// Dans un autre fichier de votre projet React
import {
  getFontColor,
  getBackgroundClass,
  formatDate,
  calculerDifferenceJours,
  getTooltipTypeActionContent,
  cleanString,
} from "../Utilities/attentesEtapes";
import logger from "../Utilities/Logger";

export const ListeAttentesAgences = React.memo(({ AllAgences }) => {
  const { fetchModeles } = useGetModeles();
  const [etapesEnAttente, setEtapesEnAttente] = useState([]);
  const {
    user,
    handleProjetId,
    projetId,
    handleSelectedMenu,
    handleEtapeId,
    agenceId,
    handleToggleDestinataires,
    searchTerm,
  } = useContext(AuthContext);

  const { socket } = useWebSocket();

  const [sortConfig, setSortConfig] = useState({
    key: "nomProjet",
    direction: "ascending",
  });
  // const [selectedDestinataire, setSelectedDestinataire] = useState(null);
  const [selectedEtapeId, setSelectedEtapeId] = useState(null);
  const [editingArchive, setEditingArchive] = useState(null);
  const [editingEcheance, setEditingEcheance] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const filteredEtapesEnAttente = etapesEnAttente.filter((etape) => {
    const matchesDescriptionEtape = etape.descriptionEtape
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesNomProjet = etape.nomProjet
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesDestinataires = etape.destinatairesEtape.some((destinataire) =>
      destinataire.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Convertir la date d'échéance en string au format 'dd/mm/yyyy' pour la recherche
    let matchesEcheance = false;
    if (etape.echeanceEtape) {
      const date = new Date(etape.echeanceEtape);
      const dateString = date.toLocaleDateString("fr-FR"); // Assurez-vous que le format correspond à celui que les utilisateurs vont rechercher
      matchesEcheance = dateString.includes(searchTerm);
    }

    // Retourne true si l'une des conditions est vraie
    return (
      matchesDescriptionEtape ||
      matchesNomProjet ||
      matchesDestinataires ||
      matchesEcheance
    );
  });

  // Si on clique sur la checkbox pour passer l'étape en archive si le projet est de type CLIENT
  // Sinon passer l'echéance à null UNIQUEMENT
  const ArchiveEtapeEtat = async (etapeId, projetId) => {
    try {
      setEditingArchive(etapeId); // Mettre à jour l'état d'édition pour l'étape actuelle
      let nouvelEtat = "enArchive";

      logger.log("ArchiveEtapeEtat etapeId", etapeId, "projetId", projetId);

      // Mise à jour dans la base de données
      const response = await customAxios.patch(
        `/api/projets/projet/${projetId}/etapeId/${etapeId}`,
        { etat: nouvelEtat, echeanceEtape: null } // L'échéance de l'étape archibvée passe à nulle
      );

      if (response.status === 200) {
        // Mise à jour réussie, rafraîchir la liste des étapes

        const updatedEtapes = etapesEnAttente.map((etape) =>
          etape.etapeId === etapeId
            ? { ...etape, etapeEtat: nouvelEtat }
            : etape
        );

        setEtapesEnAttente(updatedEtapes);
        setEditingArchive(null);
        logger.log("ArchiveEtapeEtat etapesEnAttente", updatedEtapes);
      }

      // Gérer d'autres actions après la mise à jour, si nécessaire
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'étape :", error);
      // Gérer l'erreur, par exemple, en affichant une notification à l'utilisateur
    }
  };

  const SupprimeEcheanceEtape = async (etapeId, projetId) => {
    try {
      setEditingArchive(etapeId); // Mettre à jour l'état d'édition pour l'étape actuelle

      let nouvelEtat = "enCours";
      // setEditingArchive(etapeId); // Mettre à jour l'état d'édition pour l'étape actuelle
      logger.log(
        "SupprimeEcheanceEtape etapeId",
        etapeId,
        "projetId",
        projetId
      );

      // Mise à jour dans la base de données
      const response = await customAxios.patch(
        `/api/projets/projet/${projetId}/etapeId/${etapeId}`,
        { etat: nouvelEtat, echeanceEtape: null } // L'échéance de l'étape archibvée passe à nulle
      );

      if (response.status === 200) {
        // Mise à jour réussie, rafraîchir la liste des étapes

        const updatedEtapes = etapesEnAttente.map((etape) =>
          etape.etapeId === etapeId
            ? { ...etape, etapeEtat: nouvelEtat, echeanceEtape: null }
            : etape
        );

        setEtapesEnAttente(updatedEtapes);
        setEditingArchive(null); // Astuce pour mettre à jour l'affichage
        logger.log("SupprimeEcheanceEtape etapesEnAttente", updatedEtapes);
      }

      // Gérer d'autres actions après la mise à jour, si nécessaire
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'étape :", error);
      // Gérer l'erreur, par exemple, en affichant une notification à l'utilisateur
    }
  };

  // Fonction pour montrer le DatePicker
  const showDatePicker = (etapeId, currentEcheance) => {
    logger.log(
      "ListeAttentesAgences: showDatePicker appelé avec etapeId:",
      etapeId,
      "et currentEcheance:",
      currentEcheance
    );

    setEditingEcheance(etapeId); // Définir l'édition pour l'étape actuelle dans les deux cas

    if (currentEcheance) {
      setSelectedDate(new Date(currentEcheance)); // Utiliser l'échéance actuelle si elle est définie
    } else {
      setSelectedDate(new Date()); // Utiliser la date actuelle si aucune échéance n'est définie
      logger.log(
        "ListeAttentesAgences: showDatePicker selectedDate",
        selectedDate
      );
    }
  };

  const upDateIsValidProject = useCallback(async (currentProjetId) => {
    if (!currentProjetId) return;
    try {
      const response = await customAxios.get(
        `/api/projets/isValid/${currentProjetId}`
      );
      const { isValid, nomProjet, invalidSteps } = response.data;

      logger.log(
        "ListeAttentesAgences upDateIsValidProject ",
        nomProjet,
        isValid,
        invalidSteps
      );
      if (!isValid) {
        deactivateProject(currentProjetId); // Désactiver le projet si non valide
        return false;
      } else {
        activateProject(currentProjetId); // Activer le projet si valide
        return true;
      }
    } catch (error) {
      console.error("Erreur lors de la validation du projet:", error);
      return false;
    }
  }, []);

  // Fonction pour cacher le DatePicker et mettre à jour la date d'échéance
  const onDateChange = (nouvelleEcheance, etapeId, projetId) => {
    setSelectedDate(nouvelleEcheance);

    const updateEcheance = async () => {
      let nouvelEtat;
      try {
        if (nouvelleEcheance === null) {
          nouvelEtat = "enCours";
        } else {
          nouvelEtat = "enAttente";
        }

        logger.log(
          "ListeAttentesAgences: onDateChange projetId etapeId",
          projetId,
          etapeId
        );
        logger.log(
          "ListeAttentesAgences: onDateChange nouvelEtat, nouvelleEcheance",
          nouvelEtat,
          nouvelleEcheance
        );
        const response = await customAxios.patch(
          `/api/projets/projet/${projetId}/etapeId/${etapeId}`,
          {
            etat: nouvelEtat,
            echeanceEtape: nouvelleEcheance, // Mettez à jour la date d'échéance avec la nouvelle date
          }
        );

        if (response.status === 200) {
          logger.log(
            "ListeAttentesAgences: Mise à jour réussie",
            response.data,
            "nouvelleEcheance",
            nouvelleEcheance
          );
          // Mettez à jour l'état avec les nouvelles données de l'étape
          setEtapesEnAttente((prevEtapes) =>
            prevEtapes.map((etape) =>
              etape.etapeId === etapeId
                ? { ...etape, echeanceEtape: nouvelleEcheance }
                : etape
            )
          );
          setSelectedDate(new Date(nouvelleEcheance));
          setEditingEcheance(null);
          await upDateIsValidProject(projetId); // Valider le projet après la mise à jour de la date
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'échéance", error);
      }
    };

    updateEcheance();

    logger.log(
      "ListeAttentesAgences: onDateChange editingEcheance",
      editingEcheance
    );
  };

  const handleDescriptionClick = async (
    event,
    etapeId,
    projetId,
    etapeIndex
  ) => {
    event.preventDefault();

    const etapeActive = selectedEtapeId === etapeId ? null : etapeId;

    // Mise à jour de l'état selectedEtapeId

    setSelectedEtapeId(etapeActive);
    handleProjetId(projetId);
    handleEtapeId(etapeActive);
    handleToggleDestinataires(!(selectedEtapeId === etapeId ));


    logger.log(
      "ListeAttentesAgences: handleDescriptionClick etapeActive",
      etapeActive
    );
    handleEtapeId(etapeActive); // Mettez à jour l'ID de l'étape actuellement sélectionnée
  };

  // Fonction pour vérifier si une chaîne est en UTF-8
  function isUTF8(str) {
    const decoder = new TextDecoder("utf-8", { fatal: true });
    try {
      const encoded = new TextEncoder().encode(str);
      decoder.decode(encoded);
      return true;
    } catch (e) {
      return false;
    }
  }


  const fetchAllEtapesEnAttente = useCallback(async () => {
    try {
      logger.log(
        "ListeAttentesAgences: fetchAllEtapesEnAttente:",
        AllAgences ? "CIBLE AGENCE" : "CIBLE USER"
      );

      let attentes;

      if (AllAgences) {
        const tousLesModelesDetailles = await fetchModeles(agenceId);
        logger.log(
          "ListeAttentesAgences: fetchAllEtapesEnAttente 1",
          agenceId,
          tousLesModelesDetailles
        );

        const modeleIds = tousLesModelesDetailles.map((modele) => modele._id);

        if (modeleIds && modeleIds.length > 0) {
          const payload = { modeleIds: modeleIds };
          const url = `/api/projets/attentesByModeleIds`;
          try {
            const response2 = await customAxios.post(url, payload);

            logger.log(
              "ListeAttentesAgences: fetchAllEtapesEnAttente 4",
              response2.data
            );

            // Vérification de l'encodage UTF-8
            if (isUTF8(JSON.stringify(response2.data))) {
              attentes = response2.data;
              logger.log(
                "ListeAttentesAgences: fetchAllEtapesEnAttente 5",
                attentes
              );
            } else {
              console.error("Les données reçues ne sont pas en UTF-8.");
            }
          } catch (error) {
            console.error("Erreur lors de la requête POST :", error);
          }
        } else {
          console.error("modeleIds est vide ou non défini.");
        }
      } else {
        const requestBody = { userId: user.userId };
        logger.log(
          "ListeAttentesAgences: fetchAllEtapesEnAttente 2",
          requestBody
        );

        const response = await customAxios.post(
          `/api/projets/attentesByUserId`,
          requestBody
        );

        // Vérification de l'encodage UTF-8
        if (isUTF8(JSON.stringify(response.data))) {
          attentes = response.data;
        } else {
          console.error("Les données reçues ne sont pas en UTF-8.");
        }
      }
      logger.log(
        "ListeAttentesAgences: fetchAllEtapesEnAttente 3 attentes",
        attentes
      );
      setEtapesEnAttente(attentes || []);
    } catch (error) {
      console.error(
        "ListeAttentesAgences: Erreur lors de la récupération des étapes en attente",
        error
      );
    }
  }, [user.userId, AllAgences, agenceId]);


  // Fonction pour réinitialiser les sélections
  const resetSelections = () => {
    setSelectedEtapeId(null);
    handleEtapeId(null);
    handleToggleDestinataires(false);
  };

  useEffect(() => {
    // Vérification si le socket est disponible
    if (!socket) {
      logger.warn("ListeAttentesAgences: Socket non initialisé.");
      return;
    }

    // Chargement initial des données
    fetchAllEtapesEnAttente();
    // setSelectedDestinataire(null);
    resetSelections(); // Réinitialiser les sélections

    // Fonction pour gérer les mises à jour des étapes
    const handleUpdate = (data) => {
      logger.log("ListeAttentesAgences: Mise à jour reçue", data);

      setEtapesEnAttente((prevEtapes) => {
        const exists = prevEtapes.some(
          (etape) => etape.etapeId === data.etapeId
        );

        if (exists) {
          // Mettre à jour l'étape existante
          return prevEtapes.map((etape) =>
            etape.etapeId === data.etapeId ? { ...etape, ...data } : etape
          );
        } else {
          // Rafraîchir complètement les données
          fetchAllEtapesEnAttente();
          return prevEtapes;
        }
      });
    };

    // Fonctions intermédiaires pour les écouteurs WebSocket
    const handleUpdateEtape = (data) => handleUpdate(data);
    const handleUpdateProjet = (data) => handleUpdate(data);

    // Enregistrement des écouteurs WebSocket
    socket.on("updateEtape", handleUpdateEtape);
    socket.on("updateProjet", handleUpdateProjet);

    // Nettoyage des écouteurs à la fermeture du composant
    return () => {
      logger.log("ListeAttentesAgences: Nettoyage des écouteurs WebSocket");
      socket.off("updateEtape", handleUpdateEtape);
      socket.off("updateProjet", handleUpdateProjet);
    };
  }, [socket, fetchAllEtapesEnAttente]);


  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleLabelClick = (projetId) => {
    logger.log("ListeAttentesAgences: handleLabelClick", projetId);
    handleProjetId(projetId);
    handleSelectedMenu("PROJETS CLIENTS"); // Pour simuler un reroutage
  };

  const sortedEtapes = React.useMemo(() => {
    let sortableItems = [...filteredEtapesEnAttente]; // Copie du tableau original pour éviter la mutation
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    logger.log("ListeAttentesAgences: sortedEtapes", sortableItems);
    return sortableItems;
  }, [filteredEtapesEnAttente, sortConfig]);

  const handleDestinataireClick = (destinataire, etape) => {
    // event.stopPropagation(); // Pour empêcher d'autres événements de se déclencher

    // Modifier l'état du destinataire sur lequel l'utilisateur a cliqué
    const nouvelEtat =
      destinataire.etat === "aRépondu" ? "enAttente" : "aRépondu";

    const updatedDestinataires = etape.destinatairesEtape.map((d) =>
      d.nom === destinataire.nom ? { ...d, etat: nouvelEtat } : d
    );

    logger.log(
      "ListeAttentesAgences: handleDestinataireClick",
      updatedDestinataires
    );
    // Appel API pour mettre à jour le destinataire
    updateDestinataireEtape(
      etape.projetId,
      etape.etapeId,
      updatedDestinataires
    );
  };

  // Cette fonction envoie les destinataires mis à jour au serveur
  const updateDestinataireEtape = async (
    projetId,
    etapeId,
    updatedDestinataires
  ) => {
    logger.log(
      "ListeAttentesAgences: updateDestinataireEtape",
      projetId,
      etapeId,
      updatedDestinataires
    );

    try {
      const response = await customAxios.patch(
        `/api/projets/projet/${projetId}/etapeId/${etapeId}`,
        {
          destinatairesEtape: updatedDestinataires,
        }
      );
      if (response.status === 200) {
        // Mise à jour réussie, rafraîchir la liste des étapes
        setEtapesEnAttente((prevEtapes) =>
          prevEtapes.map((etape) =>
            etape.etapeId === etapeId
              ? { ...etape, destinatairesEtape: updatedDestinataires }
              : etape
          )
        );
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des destinataires", error);
    }
  };

  const renderDestinataires = (destinataires, etape) => {
    // logger.log(
    //   "ListeAttentesAgences: renderDestinataires",
    //   destinataires,
    //   etape
    // );
    const sortedDestinataires = (destinataires || []) // Vérification pour destinataires
      .filter((destinataire) => destinataire && destinataire.nom) // Vérification pour chaque destinataire
      .sort((a, b) => {
        // Priorité à "enAttente" et tri alphabétique ensuite
        if (a.etat === "aRépondu" && b.etat !== "aRépondu") return -1;
        if (a.etat !== "aRépondu" && b.etat === "aRépondu") return 1;
        return (a.nom || "").localeCompare(b.nom || "");
      });

    const retour = sortedDestinataires.map(
      (destinataire, destinataireIndex) => {
        const key = `${destinataire.nom || ""}-${destinataireIndex}-`;
        // logger.log("renderDestinataires key", key)
        return (
          <React.Fragment key={key}>
            <OverlayTrigger
              placement="bottom" // Position du tooltip
              overlay={
                <Tooltip
                  id={`infoDestinataire-${cleanString(destinataire.nom)}`}
                >
                  {destinataire.etat === "enAttente"
                    ? "Document en Attente"
                    : destinataire.etat === "aRépondu"
                    ? "Document Reçu"
                    : ""}
                </Tooltip>
              }
            >
              <span
                style={{
                  cursor:
                    destinataire.etat === "aRépondu" ? "pointer" : "default", // Curseur actif uniquement si état "aRépondu"
                  borderRadius: "var(--border-radius-destinataires)",
                  padding: "1px 10px",
                }}
                className={
                  destinataire.etat === "enAttente"
                    ? "destinataire-enAttente"
                    : destinataire.etat === "aRépondu"
                    ? "destinataire-aRépondu"
                    : ""
                }
                onClick={() => {
                  if (destinataire.etat === "aRépondu") {
                    handleDestinataireClick(destinataire, etape); // Clic actif seulement si état "aRépondu"
                  }
                }}
              >
                {`${destinataire.nom || ""}`}
              </span>
            </OverlayTrigger>
          </React.Fragment>
        );
      }
    );

    // logger.log("renderDestinataires retour", retour);
    return retour;
  };

  return (
    <div
      style={{
        maxHeight: "600px",
        marginBottom: "15px",
      }}
    >
      <table
        style={{
          width: "100%",
          tableLayout: "fixed",
          borderCollapse: "collapse",
        }}
      >
        <thead className="table-header-requetes">
          <tr
            style={{
              height: "40px",
            }}
          >
            {/* Archive Icon */}
            <th style={{ width: "3%", textAlign: "center" }}>
              <span>
                <BsArchive style={{ fontSize: "1.2em" }} />
              </span>
            </th>

            {/* Projet */}
            <th
              style={{ width: "15%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("nomProjet")}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Liste des Projets</Tooltip>}
              >
                <span>
                  {" "}
                  <BsFileEarmarkPerson style={{ fontSize: "1.2em" }} />
                </span>
              </OverlayTrigger>
            </th>

            {/* Description */}
            <th
              style={{ width: "30%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("descriptionEtape")}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Description de l'étape</Tooltip>}
              >
                <span>
                  {" "}
                  <BsListCheck style={{ fontSize: "1.4em" }} />
                </span>
              </OverlayTrigger>
            </th>

            {/* Destinataires */}
            <th
              style={{ width: "40%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("destinatairesEtape")}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Liste des destinataires</Tooltip>}
              >
                <span>
                  <BsPeople style={{ fontSize: "1.4em" }} />
                </span>
              </OverlayTrigger>
            </th>

            {/* Actions */}
            <th
              style={{ width: "6%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("typeEtape")}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Type d'actions</Tooltip>}
              >
                <span>
                  <BsLightning style={{ fontSize: "1.4em" }} />
                </span>
              </OverlayTrigger>
            </th>

            {/* Échéance */}
            <th
              style={{ width: "8%", textAlign: "center" }}
              className="sortable-header"
              onClick={() => requestSort("echeanceEtape")}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Échéance de la requête</Tooltip>}
              >
                <span>
                  <BsClock style={{ fontSize: "1.4em" }} />
                </span>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
      </table>

      <div style={{ maxHeight: "550px", overflowY: "auto" }}>
        <table
          style={{
            width: "100%",
            tableLayout: "fixed",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            {sortedEtapes.map((etape, index) => {
              const joursRestants = etape.echeanceEtape
                ? calculerDifferenceJours(etape.echeanceEtape)
                : "N/A";
              const classeBackground = getBackgroundClass(joursRestants);
              const fontColor = getFontColor(classeBackground);
              const key = etape.etapeId;
              const rowClass = index % 2 === 0 ? "bg-custom-requetes" : "";

              return (
                <tr
                  key={`${key}-${index}`}
                  className={`
                ${rowClass} 
              ${
                selectedEtapeId === key && !AllAgences
                  ? "selected-row"
                  : "annuler-selected-row"
              } ${
                    selectedEtapeId !== key && !AllAgences
                      ? "annuler-selected-row"
                      : ""
                  }`}
                >
                  <td
                    style={{
                      width: "3%",
                      borderRadius: "6px 0 0 6px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <OverlayTrigger
                      placement="bottom" // Position du tooltip
                      overlay={
                        <Tooltip id={`infoArchive-${etape.etapeId}`}>
                          Archive la tâche
                        </Tooltip>
                      }
                    >
                      <input
                        className="checked-item"
                        type="checkbox"
                        id={`archiveTask-${etape.etapeId}`} // Assurez-vous que `etape.etapeId` est unique
                        name={`archiveTask-${etape.etapeId}`}
                        value=""
                        checked={etape.etat === "enArchive"}
                        onChange={() => {
                          etape.typeTiers === "CLIENT"
                            ? ArchiveEtapeEtat(etape.etapeId, etape.projetId)
                            : SupprimeEcheanceEtape(
                                etape.etapeId,
                                etape.projetId
                              );
                        }}
                      />
                    </OverlayTrigger>
                  </td>

                  <td
                    style={{
                      width: "15%",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                  >
                    <OverlayTrigger
                      placement="bottom" // Position du tooltip
                      overlay={
                        <Tooltip id={`infoProjet-${etape.etapeId}`}>
                          Type de projet: {etape.descriptionProjet}
                        </Tooltip>
                      }
                    >
                      <span onClick={() => handleLabelClick(etape.projetId)}>
                        {etape.nomProjet}
                      </span>
                    </OverlayTrigger>
                  </td>

                  <td
                    style={{
                      width: "30%",
                      textAlign: "left",
                    }}
                    onClick={(event) => {
                      if (etape.destinatairesModifiablesEtape) {
                        handleDescriptionClick(
                          event,
                          etape.etapeId,
                          etape.projetId,
                          etape.ordreEtape
                        );
                      }
                    }}
                    className="selection-description-col"
                  >
                    <OverlayTrigger
                      placement="bottom" // Position du tooltip
                      overlay={
                        <Tooltip id={`infoDescription-${etape.etapeId}`}>
                          {etape.destinatairesModifiablesEtape
                            ? "Cliquer pour ajouter ou supprimer des destinataires >>>"
                            : "Sélection des destinataires impossible"}
                        </Tooltip>
                      }
                    >
                      <span>{etape.descriptionEtape}</span>
                    </OverlayTrigger>
                  </td>

                  <td
                    style={{
                      width: "40%",
                      textAlign: "left",
                    }}
                  >
                    <span className="destination-col">
                      {renderDestinataires(etape.destinatairesEtape, etape)}
                    </span>
                  </td>

                  <td
                    style={{
                      width: "6%",
                      textAlign: "center",
                    }}
                  >
                    <OverlayTrigger
                      placement="bottom" // Position du tooltip
                      overlay={
                        etape.echeanceEtape ? (
                          <Tooltip id={`infoAction-${etape.etapeId}-${index}`}>
                            {getTooltipTypeActionContent(etape.typeEtape)}
                          </Tooltip>
                        ) : (
                          <></> // Pas de tooltip si `echeanceEtape` est null ou false
                        )
                      }
                    >
                      <span
                        className="action-col"
                        style={{ cursor: "pointer" }}
                      >
                        {etape.echeanceEtape && iconRendering(etape)}
                      </span>
                    </OverlayTrigger>
                  </td>

                  <td
                    className={`echeance-col ${classeBackground}`}
                    style={{
                      cursor: "pointer",
                      width: "8%", // Appliquer la condition ici
                      fontSize: "var(--fs-plus-petite)",
                      color: fontColor,
                      textAlign: "center",
                    }}
                    onClick={() =>
                      showDatePicker(etape.etapeId, etape.echeanceEtape)
                    }
                  >
                    <OverlayTrigger
                      placement="bottom" // Position du tooltip
                      overlay={
                        etape.echeanceEtape ? (
                          <Tooltip id={`infoEcheance-${etape.etapeId}`}>
                            Modifier l'échéance
                          </Tooltip>
                        ) : (
                          <></> // Pas de tooltip si `echeanceEtape` est null ou undefined
                        )
                      }
                    >
                      <span>
                        {editingEcheance === etape.etapeId ? (
                          <>
                            <ReactDatePicker
                              key={etape.etapeId}
                              selected={selectedDate}
                              onChange={(date) =>
                                onDateChange(
                                  date,
                                  etape.etapeId,
                                  etape.projetId
                                )
                              }
                              inline
                            />
                            <button
                              onClick={() =>
                                onDateChange(
                                  null,
                                  etape.etapeId,
                                  etape.projetId
                                )
                              }
                            >
                              Réinitialiser la date
                            </button>
                          </>
                        ) : (
                          etape.echeanceEtape && formatDate(etape.echeanceEtape)
                        )}
                      </span>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});
