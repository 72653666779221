import React, { useEffect, useState, useContext, useCallback } from "react";
import "../css/Compteurs.css";

import { AuthContext } from "../../contexts/AuthProvider";
import { fetchEnsembleAgences } from "../Utilities/utilities";
import customAxios from "../Utilities/getAxiosToken";
import { useWebSocket } from "../../contexts/WebSocketProvider";


/**
 * Composant CountAllProjetsClients
 * Ce composant récupère et affiche le nombre de projets uniques dont l'état est "ouvert" ou "archivé" pour tous les utilisateurs d'une agence.
 * Il utilise AuthContext pour obtenir les informations nécessaires sur le serveur et l'utilisateur.
 * Les projets sont récupérés via des appels API et les résultats sont filtrés pour garantir l'unicité des projets.
 * Un socket est utilisé pour mettre à jour la liste des projets en temps réel lorsque de nouveaux projets sont ajoutés.
 */

export function CountAllProjetsClients(props) { 
  const { cibleProjets } = props; // CLIENT ou PARTENAIRE
  const { userId, agenceId } = useContext(AuthContext);
  const [projets, setListeProjets] = useState([]);
  const [etatProjet, setEtatProjet] = useState("projetOuvert");

  const fetchProjetsAll = useCallback(async () => {
    try {
      // Appel à la nouvelle API pour récupérer les projets de l'agence
      logger.log("CountAllProjetsClients: fetchProjetsAll agenceId", agenceId, cibleProjets, etatProjet);

      const response = await customAxios.get(
        `/api/projets/projetsByAgence/${agenceId}/${cibleProjets}/${etatProjet}`
      );
      const projetsListe = response.data;
  
      // Vérification des projets récupérés
      if (projetsListe && projetsListe.length > 0) {
        logger.log("CountAllProjetsClients - Projets récupérés:", projetsListe);
        setListeProjets(projetsListe); // Mise à jour de l'état avec les projets
      } else {
        console.warn("Aucun projet trouvé pour l'agence spécifiée.");
        setListeProjets([]); // Réinitialiser si aucun projet n'est trouvé
      }
    } catch (error) {
      console.error("CountAllProjetsClients: fetchProjetsAll: Erreur lors de la récupération des projets :", error);
      setListeProjets([]); // Réinitialiser en cas d'erreur
    } finally {

    }
  }, [agenceId, etatProjet]);



   const {socket} = useWebSocket();
  useEffect(() => {
 
    if (!socket) {
      console.warn("CountAllProjetsClients: Socket non initialisé.");
      return;
    }
  
 
  
    // Appeler la fonction au montage
    fetchProjetsAll();
  
    // Gestion des événements WebSocket
    const handleNewProjectEvent = () => {
      logger.log("CountAllProjetsClients: Événement 'nouveauProjet' reçu.");
      fetchProjetsAll();
    };
  
    const handleUpdateProjectEvent = () => {
      logger.log("CountAllProjetsClients: Événement 'updateProjet' reçu.");
      fetchProjetsAll();
    };
  
    socket.on("nouveauProjet", handleNewProjectEvent);
    socket.on("updateProjet", handleUpdateProjectEvent);
  
    // Nettoyage des écouteurs WebSocket
    return () => {
      logger.log("CountAllProjetsClients: Nettoyage des écouteurs WebSocket.");
      socket.off("nouveauProjet", handleNewProjectEvent);
      socket.off("updateProjet", handleUpdateProjectEvent);
  
      // Ne pas déconnecter le socket s'il est global
      // socket.disconnect();
    };
  }, [socket, fetchProjetsAll]);

  return <span>{projets.length}</span>;
  // <span className="badge badge-info">
  //   {projets.length}
  // </span> ;
}