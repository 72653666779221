import React, { useEffect, useState, useContext, useCallback } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeTiers.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { useWebSocket } from "../../contexts/WebSocketProvider";

// On récupère le nombre de  d'un tiers en fonction de typeTiers


export function CountTiersByTypeTiers({ typeTiers }) {
    const [listeTiers, setListeTiers] = useState([]);
   
    const { userId, tiersId } = useContext(AuthContext);
  
    const fetchListeTiers = useCallback(async () => {
      try {
        if (typeTiers && userId && tiersId) {
          const response = await customAxios.get(
            `/api/tiers/byTiersIdAndTypeTiers/${tiersId}/${typeTiers}`
          );
          setListeTiers(Array.isArray(response.data.tiersAttaches) ? response.data.tiersAttaches : []);
        }
      } catch (error) {
        console.error(error);
        // Ici, vous pouvez gérer l'erreur plus précisément, par exemple, en définissant un état d'erreur et en l'affichant.
      }
    }, [ userId, typeTiers, tiersId]);

     const {socket} = useWebSocket();
    useEffect(() => { 
      if (!socket) {
        console.warn("CountTiersByTypeTiers: Socket non initialisé.");
        return;
      }
    
    
      fetchListeTiers();
    
      const handleUpdateTiers = (data) => {
        logger.log("CountTiersByTypeTiers: WebSocket - Mise à jour reçue", data);
        fetchListeTiers(); // Rafraîchit les données chaque fois qu'un événement WebSocket est reçu
      };
    
      // Écoute des événements WebSocket
      socket.on("updateTiers", handleUpdateTiers);
    
      // Nettoyage des écouteurs WebSocket lors du démontage
      return () => {
        logger.log("CountTiersByTypeTiers: Nettoyage des écouteurs WebSocket.");
        socket.off("updateTiers", handleUpdateTiers);
    
        // Ne pas déconnecter le WebSocket global
        // socket.disconnect();
      };
    }, [socket, userId, typeTiers, tiersId]);
  
    const count = listeTiers.length; // Supposons que cette ligne est quelque part dans votre composant

    return (
          <>{userId ? <span className="badge badge-info">{count}</span> : null}</> // Afficher null plutôt que une chaîne vide pour le cas sans utilisateur
      
    );
    
  }
  
