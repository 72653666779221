import customAxios from "./getAxiosToken";
import { useState, useEffect } from "react";
import logger from "./Logger";

export function extractModelIdWithIsDefaultTrue(listeModeles) {
  const modeleId = listeModeles.find((modele) => modele.isDefault === true);
  return modeleId ? modeleId._id : null;
}

const useGetModeles = () => {
 
  const [listeModeles, setListeModeles] = useState([]); // 👈 Initialisation

  const fetchModelesAdmin = async () => {
    try {
      const responseAgence = await customAxios.get(
        `/api/modeles/AllAdminModeles`
      );
      const listeModeles = responseAgence.data.listeModeles;
      logger.log("fetchModelesAdmin: responseAgence", responseAgence);
      logger.log("fetchModelesAdmin: listeModeles", listeModeles);

      const modeleDetailsPromises = listeModeles.map((Modele) =>
        fetchModeleDetails(Modele.modeleId._id)
      );

      const results = await Promise.all(modeleDetailsPromises);
      return results;
    } catch (error) {
      console.error("Erreur lors de la récupération des modèles ou des détails des modèles", error);
      return [];
    }
  };

  const fetchModeles = async (tiersId) => {
    try {
      if (!tiersId) {
        console.warn("⚠️ fetchModeles: tiersId est null ou undefined");
        setListeModeles([]); // ✅ Évite `undefined`
        return;
      }

      const response = await customAxios.get(`/api/tiers/${tiersId}`);
      const modeles = response.data.listeModeles || []; // ✅ Si `listeModeles` est null, mettre []

      logger.log("fetchModeles: modeles", modeles);
     

      const modeleDetailsPromises = modeles.map((Modele) =>
        fetchModeleDetails(Modele.modeleId)
      );

      const results = await Promise.all(modeleDetailsPromises);
      logger.log("fetchModeles: modeles détaillés", results);
      setListeModeles(results); // ✅ Stocke les modèles récupérés
      return results;
    } catch (error) {
      console.error("❌ Erreur lors de la récupération des modèles :", error);
      setListeModeles([]); // ✅ Toujours initialiser à []
      return [];
    }
};

  // const fetchModeles = async (tiersId) => {
  //   try {

  //     if (tiersId == null) {
  //       logger.log("fetchModeles tiersId est null ou undefined");
  //       return [];
  //     }

  //     const responseAgence = await customAxios.get(
  //       `/api/tiers/${tiersId}`
  //     );
  //     const listeModeles = responseAgence.data.listeModeles;

  //     const modeleDetailsPromises = listeModeles.map((Modele) =>
  //       fetchModeleDetails(Modele.modeleId)
  //     );

  //     const results = await Promise.all(modeleDetailsPromises);
  //     return results;
  //   } catch (error) {
  //     console.error("Erreur lors de la récupération des modèles ou des détails des modèles", error);
  //     return [];
  //   }
  // };
  

  // On récupère les projets d'un modèle spécifique
  
  const fetchProjetModeleId = async (modeleId) => {
    try {
      logger.log("fetchProjetModeleId : modeleId envoyé :", modeleId);
      const response = await customAxios.post("/api/projets/projetsFiltresByModeleId", { modeleId });
  
      logger.log("fetchProjetModeleId : réponse reçue :", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          console.warn("fetchProjetModeleId : Aucun projet correspondant trouvé pour modeleId :", modeleId);
          return []; // Retourne un tableau vide si aucun projet n'est trouvé
        } else {
          console.error("fetchProjetModeleId : Erreur serveur :", error.response.data.message);
        }
      } else {
        console.error("fetchProjetModeleId : Erreur réseau :", error);
      }
      return []; // Retourne un tableau vide en cas d'erreur
    }
  };


  const dupliquerModeles = async (modeles) => {
    try {
      logger.log("dupliquerModeles modeles", modeles);
      const response = await customAxios.post(
        `/api/modeles/dupliquer-modeles`,
        { modeles }
      );
      return response.data;
    } catch (error) {
      console.error(
        "CreateNewTiers: Erreur lors de la duplication des modèles:",
        error
      );
      return [];
    }
  };

  const fetchAttentesProjetByUserIdAndModeleId = async (userId, modeleId) => {
    try {
      const response = await customAxios.get(
        `/api/projets/attentesByUserIdAndModeleId/${userId}/${modeleId}`
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "CountEtapesAttentesProjetsByModeles Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "CountEtapesAttentesProjetsByModeles Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };

  const fetchSansAttentesProjetByUserIdAndModeleId = async (userId, modeleId) => {
    try {
      logger.log("fetchSansAttentesProjetByUserIdAndModeleId", modeleId);
      const response = await customAxios.get(
        `/api/projets/sansAttentesByUserIdAndModeleId/${userId}/${modeleId}`
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "CountEtapesAttentesProjetsByModeles Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "CountEtapesAttentesProjetsByModeles Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };

  const fetchProjetBydModeleId = async (userId, modeleId) => {
    try {
      logger.log("fetchProjetBydModeleId", modeleId);
      const response = await customAxios.get(
        `/api/projets/byModeleId/${modeleId}`
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "CountEtapesAttentesProjetsByModeles Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "CountEtapesAttentesProjetsByModeles Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };

  const fetchProjetByUserIdAndModeleId = async (userId, modeleId) => {
    try {
      // logger.log("fetchProjetByUserIdAndModeleId modeleId:", modeleId);
      const response = await customAxios.get(
        `/api/projets/byUserAndModeleId/${userId}/${modeleId}`
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(
          "fetchProjetByUserIdAndModeleId Le modèle avec l'ID spécifié n'a pas été trouvé."
        );
      } else {
        console.error(
          "fetchProjetByUserIdAndModeleId Erreur lors de la récupération du nom du modèle:",
          error
        );
      }
      return null;
    }
  };

  const fetchModeleName = async (modeleId) => {
    try {
      const response = await customAxios.get(
        `/api/modeles/${modeleId}`
      );
      return response.data.nom;
    } catch (error) {
      console.error("Error fetching modele name:", error);
      return null;
    }
  };

  const fetchModeleDetails = async (modeleId) => {
    try {
      const response = await customAxios.get(
        `/api/modeles/${modeleId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching modele name:", error);
      return null;
    }
  };

  // On récupère les étapes d'un modèle spécifique
  const fetchEtapesFromModeleId = async (modeleId) => {
    try {
      const response = await customAxios.get(
        `/api/modeles/${modeleId}`
      );
      logger.log("fetchEtapesFromModeleId", modeleId, response.data.etapes);
      return response.data.etapes;
    } catch (error) {
      console.error("fetchEtapesFromModeleId: Error fetching modele name:", error);
      return null;
    }
  };

  return {
    listeModeles,
    fetchModelesAdmin,
    fetchModeles,
    fetchProjetModeleId,
    fetchAttentesProjetByUserIdAndModeleId,
    fetchSansAttentesProjetByUserIdAndModeleId,
    fetchProjetBydModeleId,
    fetchProjetByUserIdAndModeleId,
    fetchModeleName,
    fetchModeleDetails,
    fetchEtapesFromModeleId,
    dupliquerModeles
  };
};

export default useGetModeles;

export function getIdFromName(array, name) {
  const item = array.find((obj) => obj.nom === name);
  return item ? item._id : null;
}

export function getNameFromId(array, id) {
  logger.log("getNameFromId id", id);
  logger.log("getNameFromId array", array);
  const item = array.find((obj) => obj._id === id);
  return item ? item.nom : null;
}
