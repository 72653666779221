import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { useWebSocket } from "../../contexts/WebSocketProvider";

export function CountListProjetsByUserId({ etatProjet, cibleProjets }) {
  const [projetCount, setProjetCount] = useState(0);
  const { userId, tiersId } = useContext(AuthContext);

  const modeleIsActif = true; // Tous les modèles isActifs

  // Fonction pour récupérer le nombre de projets
  const fetchProjetCount = useCallback(async () => {
    try {
      const response = await customAxios.get(
        `/api/projets/All/${tiersId}/${modeleIsActif}/${userId}/${etatProjet}`
      );
      const count = response.data.projects.length;
      logger.log("CountListProjetsByUserId: fetchProjetCount", tiersId, userId, count, etatProjet);
      setProjetCount(count);
    } catch (error) {
      console.error("Erreur lors de la récupération du nombre de projets :", error);
    }
  }, [userId, etatProjet, cibleProjets, tiersId]);

  useEffect(() => {
    if (cibleProjets && userId) {
      fetchProjetCount();
    }
  }, [cibleProjets, userId, etatProjet, fetchProjetCount]);

   const {socket} = useWebSocket();
  useEffect(() => {
    if (!socket) {
      console.warn("CountListProjetsByUserId: Socket non initialisé.");
      return;
    }
  
    const handleNewProjectEvent = () => {
      logger.log("CountListProjetsByUserId: Événement 'nouveauProjet' reçu.");
      fetchProjetCount();
    };
  
    const handleUpdateProjectEvent = () => {
      logger.log("CountListProjetsByUserId: Événement 'updateProjet' reçu.");
      fetchProjetCount();
    };
  
    // Ajouter les écouteurs WebSocket
    socket.on("nouveauProjet", handleNewProjectEvent);
    socket.on("updateProjet", handleUpdateProjectEvent);
  
    // Nettoyage des écouteurs WebSocket lors du démontage du composant
    return () => {
      logger.log("CountListProjetsByUserId: Nettoyage des écouteurs WebSocket.");
      socket.off("nouveauProjet", handleNewProjectEvent);
      socket.off("updateProjet", handleUpdateProjectEvent);
  
      // Ne pas déconnecter le socket global
      // socket.disconnect();
    };
  }, [socket, fetchProjetCount]);

  return (
    <>{userId ? <span className="badge badge-info">{projetCount}</span> : null}</>
  );
}