import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Row, Col, Card, Form, FormControl } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import {jwtDecode} from "jwt-decode"; // Correctement importé
import { io } from "socket.io-client";
import { AuthContext } from "../../contexts/AuthProvider";
import "./css/Accueil.css";
import { useWebSocket } from "../../contexts/WebSocketProvider";


export function Accueil({ children }) {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [credentials, setCredentials] = useState({ username: "", password: "" });
  const [honeypot, setHoneypot] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setSocketHandle, socket } = useWebSocket();
  const { handleLogin, handleIsHttps, isHttps } = useContext(AuthContext);



  useEffect(() => {
    console.log("Accueil monté");
    return () => {
      console.log("Accueil démonté");
    };
  }, []);
  
  // console.log("Accueil rendu");

  // Toggle du formulaire de connexion
  const toggleLoginForm = () => setShowLoginForm((prev) => !prev);

  // Gestion des champs de formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  // Gestion du honeypot pour détecter les bots
  const handleHoneypotChange = (e) => setHoneypot(e.target.value);

  // Création du WebSocket
  const createSocket = (token) => {
    try {

      if (socket) {
        console.log("Socket déjà créé");
        return socket;
      }

      const socketUrl = process.env.REACT_APP_SOCKET_URL;
      console.log("Accueil: Create WebSocket", socketUrl);
  
      if (!socketUrl) {
        console.error("WebSocket URL non défini. Vérifiez REACT_APP_SOCKET_URL dans votre .env.");
        return null;
      }
  
      const newSocket = io(socketUrl, {
        path: "/ws",
        auth: { token },
      });
  
      newSocket.on("connect", () => {
        console.log("Accueil: WebSocket connecté :", newSocket.id);
      });
  
      newSocket.on("disconnect", (reason) => {
        console.log("Accueil: WebSocket déconnecté :", reason);
      });
  
      newSocket.on("connect_error", (error) => {
        console.error("Erreur de connexion WebSocket :", error.message);
      });
  
      return newSocket;
    } catch (error) {
      console.error("Accueil: Erreur lors de la création du WebSocket :", error.message || error);
      return null;
    }
  };

  // Soumission du formulaire de connexion
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (honeypot) {
      console.warn("Honeypot détecté. Formulaire bloqué.");
      return;
    }
    setIsLoading(true);

    try {
      const response = await axios.post("/api/login", credentials);
      // Récupération du token JWT et de la date d'expiration
      const { userTokenAcces, expirationDate } = response.data;

      logger.log("Accueil: userTokenAcces", userTokenAcces);
      logger.log("Accueil: expirationDate", expirationDate);
      // Décodage du token JWT pour extraire les données utilisateur
      const decoded = jwtDecode(userTokenAcces);
      const { userId, userNom, userPrenom, userRole, userTypeUtilisateur } = decoded;

      // Enregistrement du token dans les cookies
      Cookies.set("userTokenAcces", userTokenAcces, {
        expires: new Date(expirationDate),
        secure: isHttps,
        sameSite: isHttps ? "None" : "Lax",
      });

      
      // Ajoutez un délai (ou une vérification explicite)
      setTimeout(() => {
        const token = Cookies.get("userTokenAcces");
        console.log("Accueil Token récupéré après délai :", token);
      }, 100); // Délai court pour permettre au cookie d'être défini


      console.log("Accueil New Cookies", Cookies.get("userTokenAcces"));

      // Mise à jour du header Authorization par défaut
      axios.defaults.headers.Authorization = `Bearer ${userTokenAcces}`;
      axios.defaults.withCredentials = true;

      // Récupération des informations complémentaires (exemple : agenceId)
      const tiersResponse = await axios.get(`/api/login/agence/${userId}`);
      const { agenceId } = tiersResponse.data;

      logger.log("Accueil: agenceId", agenceId);
      
      // Création du WebSocket et mise à jour
      const newSocket = createSocket(userTokenAcces);
       
      setSocketHandle(newSocket); // Mettre à jour le socket dans le WebSocketProvider
      console.log("Accueil New Socket", newSocket);

      // Mise à jour du contexte Auth
      handleLogin(userId, userNom, userPrenom, userRole, agenceId, userTypeUtilisateur);
      setShowLoginForm(false);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Vérification du protocole HTTPS
  useEffect(() => {
    const isHttps = window.location.protocol === "https:";
    handleIsHttps(isHttps);
  }, [handleIsHttps]);

  return (
    <div className="accueil">
      {/* Header */}
      <header className="header">
        <div className="header-container">
          <h1 className="logo">iAkka</h1>
          <nav className="nav">
            <a href="#features">Fonctionnalités</a>
            <a href="#pricing">Tarifs</a>
            <a href="#about">À propos</a>
            <a href="#contact">Contact</a>
          </nav>
          <div className="header-buttons">
            <button className="btn btn-outline-primary" onClick={toggleLoginForm}>
              Se connecter
            </button>
            <button className="btn btn-primary">S'inscrire</button>
          </div>
        </div>
      </header>

      {/* Formulaire de connexion */}
      {showLoginForm && (
        <div className="login-overlay">
          <div className="login-container">
            <Button variant="outline-danger" className="close-login" onClick={toggleLoginForm}>
              Fermer
            </Button>
            <Form onSubmit={handleSubmit}>
              <h2 className="text-center">Connexion</h2>
              <FormControl
                type="text"
                name="username"
                placeholder="Nom d'utilisateur"
                value={credentials.username}
                onChange={handleChange}
                className="form-control-small"
              />
              <FormControl
                type="password"
                name="password"
                placeholder="Mot de passe"
                value={credentials.password}
                onChange={handleChange}
                className="form-control-small"
              />
              <input
                type="text"
                name="company"
                value={honeypot}
                onChange={handleHoneypotChange}
                style={{ display: "none" }}
              />
              <Button type="submit" variant="primary" disabled={isLoading} className="w-100">
                {isLoading ? "Connexion..." : "Se connecter"}
              </Button>
            </Form>
          </div>
        </div>
      )}



      {/* Hero Section */}
      <section className="hero">
        <Container className="text-center hero-container">
          <div className="hero-text">
            <h1 className="hero-title">Simplifiez vos projets avec iAkka</h1>
            <p className="hero-subtitle">
              Une IA toujours présente pour garantir la cohérence de vos données et maximiser votre productivité.
            </p>
            <p className="hero-subtitle">
              Gagnez du temps et collaborez efficacement avec notre solution.
            </p>
            <div className="hero-buttons">
              <Button className="cta-primary me-1">Découvrir</Button>
              <Button variant="warning" className="cta-demo">
                Demander une démonstration
              </Button>
            </div>
          </div>
        </Container>
      </section>

      {/* Features Section */}
      <section id="features" className="features">
        <Container>
          <h2 className="text-center mb-4">Fonctionnalités clés</h2>
          <Row>
            <Col md={4}>
              <Card className="feature-card">
                <Card.Body>
                  <Card.Title>Gestion simplifiée</Card.Title>
                  <Card.Text>Automatisez vos tâches et centralisez vos données.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card">
                <Card.Body>
                  <Card.Title>Collaboration</Card.Title>
                  <Card.Text>Travaillez en équipe avec des outils intuitifs.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card">
                <Card.Body>
                  <Card.Title>Suivi en temps réel</Card.Title>
                  <Card.Text>Obtenez des mises à jour instantanées sur vos projets.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Call to Action */}
      <section className="cta">
        <Container className="text-center">
          <h2>Rejoignez des milliers d'utilisateurs satisfaits</h2>
          <Button className="cta-primary">Commencer maintenant</Button>
        </Container>
      </section>
    </div>
  );
}