import { useState } from "react";
import { toast } from "react-toastify";
import customAxios from "./getAxiosToken";
import { useCreateNewProjet } from "../Utilities/GetProjets";
import { fakerFR as faker } from "@faker-js/faker";
import logger from "../Utilities/Logger";
import { fetchEnsembleAgences } from "../Utilities/utilities";

export const useTiersForm = ({ 
  agenceId,
  typeTiers, 
  userId, 
  setValue, 
  refreshProjects, 
  closeCreateNewTiers, 
  listeModeles, 
  fetchCompanyInfoBySiren, 
  setSirenValid,  
  setError, 
  setCompanyInfo,
  isClientParticulier
}) => {

   logger.log("🔍 useTiersForm est exécuté !", isClientParticulier);
  
  const [isModified, setIsModified] = useState(false);
  const { createNewProjet } = useCreateNewProjet();

  const typesProjets = ["Extension", "Rénovation totale", "Rénovation partielle", "Surélévation"];

  // Vérifie si un utilisateur existe déjà
  const checkIfUserExists = async (email) => {
    try {
      const response = await customAxios.get(`/api/utilisateurs/recherche-utilisateur`, { params: { email } });
      return response.data.utilisateur;
    } catch (error) {
      return false;
    }
  };

  const remplirChampsAvecDonneesFictives = async () => {
    const civiliteOptions = ["Mr", "Mme"];
    const indexAleatoireCivilite = Math.floor(Math.random() * civiliteOptions.length);
    const indexAleatoireProjet = Math.floor(Math.random() * typesProjets.length);
    const siren = generateValidSiren();


   // 🔹 Filtrer les modèles correspondant au `typeTiers`
   const modelesFiltres = listeModeles.filter((modele) => modele.cible === typeTiers);
    logger.log("🔍 Modèles filtrés pour", typeTiers, ":", modelesFiltres);
 
   let modeleId = null;
   if (modelesFiltres.length === 1) {
     modeleId = modelesFiltres[0]._id; // ✅ Sélection unique si un seul modèle
      logger.log("remplirChampsAvecDonneesFictives: ✅ Un seul modèle trouvé :", modeleId);
   } else if (modelesFiltres.length > 1) {
     modeleId = faker.helpers.arrayElement(modelesFiltres)._id; // ✅ Sélection aléatoire
      logger.log("remplirChampsAvecDonneesFictives: ✅ Modèle sélectionné aléatoirement :", modeleId);
   } else {
     console.warn("⚠️ Aucun modèle correspondant trouvé pour", typeTiers);
   }
    // 🔹 Génération des données fictives en fonction du type de client
    const fakeData = {
      civilite: civiliteOptions[indexAleatoireCivilite],
      nom: faker.person.lastName().toUpperCase(),
      prenom: isClientParticulier ? faker.person.firstName() : "", 
      raisonSociale: isClientParticulier ? "" : faker.company.name().toUpperCase(),
      email: faker.internet.email(),
      emailContact: isClientParticulier ? "" : faker.internet.email(),
      nomContact: isClientParticulier ? "" : faker.person.lastName().toUpperCase(),
      prenomContact: isClientParticulier ? "" : faker.person.firstName(),
      adresse: faker.location.streetAddress(),
      ville: faker.location.city().toUpperCase(),
      cp: faker.number.int({ min: 10000, max: 99999 }).toString(),
      telephone: faker.phone.number(),
      siren,
      modeleId,
      descriptionProjet: typesProjets[indexAleatoireProjet],
      iban: generateFakeIban(),
    };

    logger.log("remplirChampsAvecDonneesFictives: 📌 Données fictives générées :", fakeData);

    // Mise à jour du formulaire
    Object.keys(fakeData).forEach((key) => {
      if (setValue) {
        setValue(key, fakeData[key]);
         logger.log(`remplirChampsAvecDonneesFictives: ✅ setValue exécuté pour ${key} :`, fakeData[key]);
      } else {
        console.error("❌ ERREUR: setValue est undefined !");
      }
    });
 
    setIsModified(true);

    // Vérification SIREN après l'affectation
    if (siren) {
      try {
        await fetchCompanyInfoBySiren(siren, setSirenValid, setError, setCompanyInfo, setValue);
      } catch (error) {
        console.error("Erreur lors de la récupération des infos :", error);
        setError("Impossible de récupérer les informations de l'entreprise.");
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      let idsUtilisateurs = [];

      logger.log("🚀 onSubmit a été appelé avec :", data);
      logger.log("onSubmit Création d'un nouveau tiers DEBUT :", data);

      if (!data.modeleId) {
        toast.error("Veuillez sélectionner un modèle de projet.");
        return;
      }

      const userExists = await checkIfUserExists(data.email);
      let tiersId = null;

      if (!userExists) {
        data.typeTiers = typeTiers;
        data.clientParticulier = isClientParticulier;
        data.siteWeb = "";
        data.createur = userId;
        data.autonome = true;
        data.etat = "tiersClientOuvert";   
        data.accesTiers = [{ utilisateur: userId, etat: "ouvert", dateArchivage: null }];

        // 🔹 Récupération des utilisateurs pour les partenaires , fournisseurs, prestatataires et Agence
        if (typeTiers === "PARTENAIRE"   || typeTiers === "FOURNISSEUR" || typeTiers === "PRESTATAIRE" || typeTiers === "AGENCE") {
          const dataUtilisateurs = await fetchEnsembleAgences(agenceId);
          logger.log("onSubmit 📌 Utilisateurs de l'agence :", dataUtilisateurs)  ;
          const listeUtilisateursData = dataUtilisateurs.TousLesUtilisateurs;
  
          idsUtilisateurs = listeUtilisateursData.map(
            (utilisateur) => utilisateur.utilisateurId
          );
  

          data.accesTiers = idsUtilisateurs.map((utilisateurId) => ({
            utilisateur: utilisateurId, // Utilisateur à partir de l'id
            etat: "ouvert", // État par défaut
            dateArchivage: null, // Date d'archivage nulle par défaut
          }));

          logger.log("onSubmit 📌 Accès tiers pour data.accesTiers",data.accesTiers); 
  
        }

        // 🔹 Ajout des informations du contact pour une entreprise
        if (!isClientParticulier) {
          data.nom = data.raisonSociale;
          data.email = data.emailContact;
          data.prenom = null;
        }

        logger.log("onSubmit Création d'un nouveau tiers :", data);

        const response = await customAxios.post(`/api/tiers/`, data);
        logger.log("onSubmit 📌 Réponse API création tiers :", response);

        tiersId = response.data._id;
        const nomAffichage = isClientParticulier ? data.nom : data.raisonSociale;

        toast.success(`Tiers '${nomAffichage}' créé avec succès !`);

        // 🔹 Création de l'utilisateur associé au tiers
        const userData = {
          civilite: data.civilite,
          email: data.email,
          nom: isClientParticulier ? data.nom : data.nomContact,
          prenom: isClientParticulier ? data.prenom : data.prenomContact,
          telephone: data.telephone,
          adresse: data.adresse,
          cp: data.cp,
          ville: data.ville,
          tiersId,
          role: "Utilisateur",
          password: "azerty12",
          typeTiers,
        };

        await customAxios.post(`/api/utilisateurs/`, userData);
        toast.success("Utilisateur créé avec succès !");
      } else {
        toast.warn("L'utilisateur existe déjà. Le tiers n'a pas été recréé.");
      }

      if (tiersId) {
        await createNewProjet(data.modeleId, typeTiers, tiersId, userId, isClientParticulier ? data.descriptionProjet : "Traitement Administratif", data.accesTiers);
        toast.success("Projet créé avec succès !");
      }

      // On ajoute le tiers à la liste des tiers de l'agence
      if (typeTiers !== "AGENCE") {
        // On ajoute le Tiers ID à la liste des Tiers de l'Agence
        try {
          await customAxios.patch(`/api/tiers/${agenceId}/ajouterTiers`, {
            tiersId: tiersId,
          });
        } catch (error) {
          console.error(
            "CreateNewTiers: Erreur lors de la mise à jour de l'agence avec le nouveau tiers",
            error
          );
        }
      }
      refreshProjects();
      closeCreateNewTiers();
    } catch (error) {
      toast.error("Erreur lors de la création du tiers, utilisateur ou projet.");
    }
  };

  /**
   * Génère un SIREN valide en utilisant l'algorithme de Luhn.
   * @returns {string} SIREN généré
   */
  const generateValidSiren = () => {
    let siren;
    do {
      siren = faker.number.int({ min: 100000000, max: 999999999 }).toString();
    } while (!isValidSiren(siren));
    return siren;
  };

  const isValidSiren = (siren) => {
    if (!/^\d{9}$/.test(siren)) return false;
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      let num = parseInt(siren[i]);
      if (i % 2 === 1) num *= 2;
      if (num > 9) num -= 9;
      sum += num;
    }
    return sum % 10 === 0;
  };

  /**
   * Génère un IBAN aléatoire valide.
   * @returns {string} IBAN généré
   */
  const generateFakeIban = () => {
    const countryCode = "FR";
    const bankCode = faker.number.int({ min: 10000, max: 99999 }).toString();
    const branchCode = faker.number.int({ min: 10000, max: 99999 }).toString();
    const accountNumber = faker.number.int({ min: 10000000000, max: 99999999999 }).toString();
    const key = faker.number.int({ min: 10, max: 99 }).toString();
    return `${countryCode}${bankCode}${branchCode}${accountNumber}${key}`;
  };

  return { onSubmit, remplirChampsAvecDonneesFictives, isModified };
};