
import React from "react";
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";


// Composant pour l'en-tête du formulaire
export function FormHeader({ typeTiers }) {
  return (
      <h5 className="titre-zone3" style={{ textAlign: "center" }}>
      {typeTiers === "AGENCE" ? "Création d'une nouvelle Agence" : `Création nouveau ${typeTiers}`}
    </h5>
  );
}



// Composant réutilisable pour chaque champ de formulaire
export function FormField({ label, type, name, placeholder, register, errors }) {
  return (
    <div className="form-field">
      <label htmlFor={name}>{label}</label>
      <input
        {...register(name, { required: `${label} est requis` })}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
      />
      {errors[name] && <p className="errorMessage">{errors[name].message}</p>}
    </div>
  );
}

export function SelectField({ label, name, options, register, errors, onChange, defaultValue }) {
  return (
    <div className="form-field">
      <label htmlFor={name}>{label}</label>
      <select
        id={name}
        {...register(name, { required: true })}
        defaultValue={defaultValue} // Utilisation de la valeur par défaut
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[name] && <p className="errorMessage">{errors[name].message}</p>}
    </div>
  );
}




export function CpField({ register, errors }) {
  return (
    <div className="form-field">
      <label htmlFor="cp">CP</label>
      <input
        {...register("cp", {
          required: "Le code postal est requis",
          pattern: {
            value: /^[0-9]+$/,
            message: "Le code postal doit être un nombre entier",
          },
        })}
        type="text"
        id="cp"
        placeholder="CP"
      />
      {errors.cp && <p className="errorMessage">{errors.cp.message}</p>}
    </div>
  );
}

export function EnseigneField({ register, errors, onChange, onBlur }) {
  return (
    <div className="form-field">
      <label htmlFor="enseigne">Enseigne</label>
      {/* <div style={{ display: "flex", alignItems: "center" }}> */}
        <input
          {...register("enseigne", {
            required: "Enseigne est nécessaire",
          })}
          type="text"
          id="enseigne"
          name="enseigne"
          placeholder="Enseigne"
          onChange={onChange}
          onBlur={onBlur}
          data-tooltip-id="tooltip-enseigne"
        />
        <FaInfoCircle
          style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
          data-tooltip-id="tooltip-enseigne-info"
          data-tooltip-content="Par défaut, l'enseigne est identique à la raison sociale, mais vous pouvez la modifier."
        />
      {/* </div> */}

      {/* Tooltip pour le champ Enseigne */}
      <Tooltip
        id="tooltip-enseigne"
        place="bottom"
        effect="solid"
        className="custom-tooltip"
      >
        L'enseigne par défaut est identique à la raison sociale mais peut être modifiée si nécessaire.
      </Tooltip>

      {/* Tooltip pour l'icône d'information */}
      <Tooltip
        id="tooltip-enseigne-info"
        place="bottom"
        effect="solid"
        className="custom-tooltip"
      />

      {errors.enseigne && <p className="errorMessage">{errors.enseigne.message}</p>}
    </div>
  );
}


export function IbanField({ register, errors, setValue }) {
  return (
    <div className="form-field">
      <label htmlFor="iban">IBAN</label>
      <input
        {...register("iban", {
          required: "L'IBAN est requis",
          pattern: { value: /^[A-Z0-9]+$/i, message: "Seulement lettres et chiffres" },
        })}
        type="text"
        id="iban"
        placeholder="IBAN"
        onChange={(e) => setValue("iban", e.target.value.replace(/\s/g, ""))} // ✅ Supprime les espaces
      />
      {errors.iban && <p className="errorMessage">{errors.iban.message}</p>}
    </div>
  );
}


export function PasswordField({ register, errors, watch }) {
  const passwordsMatch = watch("password") === watch("confirmPassword");
  
  return (
    <>
      <div className="form-field">
        <label htmlFor="password">Mot de passe </label>
 
          <input
            {...register("password", {
              required: "Le mot de passe est requis",
              minLength: {
                value: 8,
                message: "Le mot de passe doit contenir au moins 8 caractères",
              },
            })}
            type="password"
            id="password"
            placeholder="Mot de passe"
            data-tooltip-id="tooltip-password"
          />
          {passwordsMatch && watch("password") && <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />}
          <FaInfoCircle
            style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
            data-tooltip-id="tooltip-password-info"
            data-tooltip-content="Le mot de passe doit comporter au moins 8 caractères."
          />
    

        {/* Tooltip pour le mot de passe */}
        <Tooltip
          id="tooltip-password"
          place="bottom"
          effect="solid"
          className="custom-tooltip"
        >
          Choisissez un mot de passe de 8 caractères minimum.
        </Tooltip>

        {/* Tooltip pour l'icône d'information */}
        <Tooltip
          id="tooltip-password-info"
          place="bottom"
          effect="solid"
          className="custom-tooltip"
        />

        {errors.password && <p className="errorMessage">{errors.password.message}</p>}
      </div>

      <div className="form-field">
        <label htmlFor="confirmPassword">Confirmer le mot de passe </label>
          <input
            {...register("confirmPassword", {
              required: "Veuillez confirmer votre mot de passe",
              validate: (value) => value === watch("password") || "Les mots de passe ne correspondent pas",
            })}
            type="password"
            id="confirmPassword"
            placeholder="Confirmer le mot de passe"
            data-tooltip-id="tooltip-confirm-password"
          />
          {passwordsMatch && watch("confirmPassword") && <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />}
          {watch("confirmPassword") && !passwordsMatch && <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />}


        {/* Tooltip pour la confirmation du mot de passe */}
        <Tooltip
          id="tooltip-confirm-password"
          place="bottom"
          effect="solid"
          className="custom-tooltip"
        >
          Assurez-vous que les deux mots de passe correspondent.
        </Tooltip>

        {errors.confirmPassword && <p className="errorMessage">{errors.confirmPassword.message}</p>}
      </div>
    </>
  );
}

export function PhoneField({ register, errors, setValue }) {
  return (
    <div className="form-field">
      <label htmlFor="telephone">Téléphone </label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          {...register("telephone", {
            required: "Le téléphone est requis",
            pattern: {
              value: /(\+33\s?[1-9]{1}\d{8})|(0[1-9](?:[ _.-]?[0-9]{2}){4})/,
              message: "Format de téléphone invalide",
            },
          })}
          type="text"
          id="telephone"
          name="telephone"
          placeholder="Numéro de téléphone"
          onChange={(e) => {
            const cleanedValue = e.target.value.replace(/[^0-9+]/g, ""); // ✅ Garde uniquement chiffres et "+"
            setValue("telephone", cleanedValue);
          }}
          data-tooltip-id="tooltip-telephone"
        />
        <FaInfoCircle
          style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
          data-tooltip-id="tooltip-telephone-info"
          data-tooltip-content="Entrez un numéro de téléphone au format français (+33 ou 0 suivi de 9 chiffres)."
        />
      </div>

      {/* Tooltip pour le téléphone */}
      <Tooltip id="tooltip-telephone" place="bottom" effect="solid" className="custom-tooltip">
        Le numéro doit être au format +33 ou 0 suivi de 9 chiffres.
      </Tooltip>

      <Tooltip id="tooltip-telephone-info" place="bottom" effect="solid" className="custom-tooltip" />

      {errors.telephone && <p className="errorMessage">{errors.telephone.message}</p>}
    </div>
  );
}

export function EmailField({ register, errors, setValue }) {
  return (
    <div className="form-field">
      <label htmlFor="email">Email </label>
      <input
        {...register("email", {
          required: "L'email est requis",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Format d'email invalide",
          },
        })}
        type="email"
        id="email"
        name="email"
        placeholder="Email"
        onChange={(e) => setValue("email", e.target.value.toLowerCase())} // ✅ Convertit en minuscule
        data-tooltip-id="tooltip-email"
      />
      <FaInfoCircle
        style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
        data-tooltip-id="tooltip-email-info"
        data-tooltip-content="Veuillez entrer une adresse email valide, par exemple : nom@exemple.com."
      />

      {/* Tooltip pour le champ email */}
      <Tooltip id="tooltip-email" place="bottom" effect="solid" className="custom-tooltip">
        Assurez-vous d'entrer une adresse email valide.
      </Tooltip>

      <Tooltip id="tooltip-email-info" place="bottom" effect="solid" className="custom-tooltip" />

      {errors.email && <p className="errorMessage">{errors.email.message}</p>}
    </div>
  );
}

export function SirenField({ register, errors, sirenValid, setValue, watch, onBlur, sirenExists }) {
  if (!setValue) {
    console.error("❌ ERREUR: `setValue` est undefined dans SirenField !");
    return null; // ⛔ Stoppe le rendu pour éviter une erreur
  }

  const sirenValue = watch ? watch("siren") || "" : "";

  return (
    <div className="form-field">
      <label htmlFor="siren">SIREN</label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          {...register("siren", {
            required: "Le SIREN est requis",
            pattern: {
              value: /^[0-9]{9}$/,
              message: "Le SIREN doit comporter exactement 9 chiffres.",
            },
            maxLength: 9,
          })}
          type="text"
          id="siren"
          name="siren"
          placeholder="SIREN"
          value={sirenValue}
          onChange={(e) => {
            const cleanedValue = e.target.value.replace(/\D/g, ""); // 🔹 Supprime tout sauf les chiffres
            if (cleanedValue.length <= 9 && setValue) {
              setValue("siren", cleanedValue); // ✅ Vérifie que `setValue` est bien défini
            }
          }}
          onBlur={onBlur} // ✅ Validation après avoir quitté le champ
          data-tooltip-id="tooltip-siren"
        />


          {/* ✅ Affichage dynamique des icônes en fonction de la saisie */}
          {sirenValue.length > 0 && ( // Afficher seulement si l'utilisateur a commencé à saisir
          sirenExists || !sirenValid ? (
            <FaTimesCircle style={{ color: "red", marginLeft: "8px" }} />
          ) : (
            <FaCheckCircle style={{ color: "green", marginLeft: "8px" }} />
          )
        )}


      </div>

      <Tooltip id="tooltip-siren" place="bottom" effect="solid" className="custom-tooltip">
        Entrez un SIREN de 9 chiffres.
      </Tooltip>

      {sirenExists && (
        <p className="errorMessage" style={{ color: "red" }}>
          Le SIREN existe déjà pour un autre tiers.
        </p>
      )}

      {errors.siren && <p className="errorMessage">{errors.siren.message}</p>}
    </div>
  );
}



export function SubmitButton({ isModified, typeTiers, watch }) {
  if (!isModified || !watch) return null; // ✅ Vérifier si `watch` est défini

  // 🔹 Définition des types de tiers professionnels
const typeTiersProfessionnels = ["PARTENAIRE", "PRESTATAIRE", "FOURNISSEUR", "AGENCE"];

// 🔹 Définir `typeClient` dynamiquement
const typeClient = typeTiersProfessionnels.includes(typeTiers) ? "Professionnel" : (watch("typeClient") || "Particulier");

const isParticulier = typeClient.toLowerCase() === "particulier";
const isEntreprise = !isParticulier; // ✅ Inversement automatique


  const nom = watch("nom")?.trim() || "";
  const prenom = watch("prenom")?.trim() || "";
  const raisonSociale = watch("raisonSociale")?.trim() || "";
  const nomContact = watch("nomContact")?.trim() || "";
  const prenomContact = watch("prenomContact")?.trim() || "";

  let nomAffichage = "Nouveau Tiers";

  console.log("SubmitButton 🔍 Valeurs du formulaire :", {
    typeTiers,
    typeClient,
    nom,
    prenom,
    raisonSociale,
    nomContact,
    prenomContact,
  });

  if (typeTiers === "CLIENT") {
    if (isParticulier) {
      // ✅ CLIENT PARTICULIER : Afficher "Prénom Nom"
      nomAffichage = (prenom && nom) ? `${prenom} ${nom}` : "Client Particulier";
    } else if (isEntreprise) {
      // ✅ CLIENT ENTREPRISE : Afficher la Raison Sociale sinon le Contact
      nomAffichage = raisonSociale || (nomContact && prenomContact ? `${prenomContact} ${nomContact}` : "Entreprise inconnue");
    }
  } else {
    // ✅ PARTENAIRE / FOURNISSEUR / AGENCE
    nomAffichage = raisonSociale || nom || "Nouveau Tiers";
  }

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0" }}>
      <button className="btn">Cliquer pour créer {nomAffichage}</button>
    </div>
  );
}

