import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";
import { useWebSocket } from "../../contexts/WebSocketProvider";
import useGetModeles from "../Utilities/GetModeles";
import { useFetchAgences } from "../Utilities/fetchAgences"; // Corrected here
import {  BsPencil, BsTrash, } from 'react-icons/bs';
import { FaInfoCircle } from "react-icons/fa";

export function ListeCatégories({ AllAgences }) {
  const { fetchModeles }  = useGetModeles(); 
  const { fetchAgences } = useFetchAgences();

 
  const [ListeCatégoriesDétaillés, setListeCatégoriesDétaillés] = useState([]);
  const [checkedModeles, setCheckedModeles] = useState({});
  const [selectedModeleId, setSelectedModeleId] = useState(null);
  const [toutesLesCibles, setCibles] = useState([]); // Pour les différentes Vues
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [selectedAgence, setSelectedAgence] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'cible', direction: 'ascending' });
  const [editModele, setEditModele] = useState(null);
  const [editValues, setEditValues] = useState({ nom: "", cible: "" });
  const [editedCategory, setEditedCategory] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState("");

  const { handleModeleId, tiersId, userId, triggerAddModele, handleTriggerAddAction } = useContext(AuthContext);

  const [showInfo, setShowInfo] = useState(false); // État pour la fenêtre d'explication

  // Fonction pour gérer le clic sur l'icône et afficher/masquer la fenêtre d'explication
  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };

  const addNewModele = async () => {
    try {
      // Sauvegarde de la nouvelle action dans la base de données
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un nouveau modèle:", error);
    }
  };

  // Récupère la liste des types de Tiers
  const fetchCibles = useCallback(async () => {
    try {
      const response = await customAxios.get(`/api/actions/types-actions-et-tiers`);
      const result = response.data;
      setCibles(result.tiers);
    } catch (error) {
      console.error("GestionActions: Failed to fetch data:", error);
    }
  }, []);

  const chargerTousLesModeles = useCallback(async (localParametreId) => {
    try {
      const tousLesModelesDetailles = await fetchModeles(localParametreId);
      setListeCatégoriesDétaillés(tousLesModelesDetailles);

      // Récupérer toutes les catégories disponibles
      const allCategories = new Set();
      tousLesModelesDetailles.forEach((modele) => {
        modele.categorie.forEach((cat) => allCategories.add(cat));
      });
   
      
      // Convertir le Set en tableau, puis trier les catégories par ordre alphabétique
      const categoriesTries = Array.from(allCategories).sort((a, b) => a.localeCompare(b));
      
      setCategories(categoriesTries);


      // Trouver et sélectionner le modèle par défaut (s'il existe)
      const modeleParDefaut = tousLesModelesDetailles.find((modele) => modele.isDefault);
      if (modeleParDefaut) {
        setSelectedModeleId(modeleParDefaut._id); // Sélectionner le modèle par défaut
        handleModeleId(modeleParDefaut._id); // Mettre à jour le contexte si nécessaire
      }
    } catch (error) {
      console.error("Erreur lors du chargement des modèles:", error);
    }
  }, [handleModeleId]);

  useEffect(() => {
    if (triggerAddModele) {
      addNewModele();
      handleTriggerAddAction(false);
    }
  }, [triggerAddModele, tiersId, handleTriggerAddAction]);

  const fetchAgencesCallback = useCallback(async () => {
    try {
      const uniqueTiers = await fetchAgences();
      const adminAgence = uniqueTiers.find((agence) => agence.typeTiers === "ADMINISTRATEUR");
      if (adminAgence) {
        setSelectedAgence(adminAgence);
        chargerTousLesModeles(adminAgence._id);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des agences et administrateurs :", error);
    }
  }, [ chargerTousLesModeles]);

  const handleLabelClick = (ModeleId) => {
    handleModeleId(ModeleId);
    setSelectedModeleId(ModeleId);
    setCheckedModeles((prevState) => ({
      ...prevState,
      [ModeleId]: !prevState[ModeleId],
    }));
  };

  const handleSocketEvent = useCallback(() => {
    fetchAgencesCallback();
  }, [fetchAgencesCallback]);

     const {socket} = useWebSocket();
    useEffect(() => {
      if (!tiersId || !userId) {
        logger.warn("Dépendances manquantes : tiersId ou userId non défini.");
        return;
      }
    
      const fetchInitialData = async () => {
        try {
          logger.log("Fetching agences et modèles.");
          await fetchAgencesCallback();
          await chargerTousLesModeles();
        } catch (error) {
          console.error("Erreur lors du chargement des données initiales :", error);
        }
      };
    
      fetchInitialData();
    
      // Gérer les événements WebSocket
      if (socket) {
        socket.on("updateModèle", handleSocketEvent);
        socket.on("updateAgence", handleSocketEvent);
    
        logger.log("Abonnements WebSocket configurés.");
      } else {
        logger.warn("Socket non initialisé.");
      }
    
      return () => {
        logger.log("Nettoyage des abonnements WebSocket.");
        if (socket) {
          socket.off("updateModèle", handleSocketEvent);
          socket.off("updateAgence", handleSocketEvent);
        }
      };
    }, [userId, AllAgences, tiersId, fetchAgencesCallback, chargerTousLesModeles, handleSocketEvent]);
  useEffect(() => {
    fetchAgencesCallback();
    fetchCibles();
  }, [fetchAgencesCallback, fetchCibles]);



  const handleCategoryChange = async (selectedModelId, category) => {
    try {
      const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
        if (modele._id === selectedModelId) {
          const newCategories = modele.categorie.includes(category)
            ? modele.categorie.filter((cat) => cat !== category)
            : [...modele.categorie, category];
          return { ...modele, categorie: newCategories };
        }
        return modele;
      });

      setListeCatégoriesDétaillés(updatedModels);

      const updatedModel = updatedModels.find((modele) => modele._id === selectedModelId);
      const updateData = { categorie: updatedModel.categorie };

      await customAxios.patch(`/api/modeles/ModifieOptionsDuModele/${selectedModelId}`, updateData);
    } catch (error) {
      console.error(error);
      alert("Une erreur est survenue lors de la mise à jour des catégories sur le serveur.");
    }
  };

  const handleAddCategory = () => {
    if (newCategory && !categories.includes(newCategory)) {
      const updatedCategories = [...categories, newCategory].sort((a, b) => a.localeCompare(b));
      setCategories(updatedCategories);
      setNewCategory("");
    }
  };

  const handleRemoveCategory = async (categoryToRemove) => {
    const updatedCategories = categories.filter((category) => category !== categoryToRemove);
    setCategories(updatedCategories);

    const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
      const newCategories = modele.categorie.filter((cat) => cat !== categoryToRemove);
      return { ...modele, categorie: newCategories };
    });

    setListeCatégoriesDétaillés(updatedModels);

    for (let model of updatedModels) {
      const updateData = { categorie: model.categorie };
      await customAxios.patch(`/api/modeles/ModifieOptionsDuModele/${model._id}`, updateData);
    }
  };


   // Pour editer le nom de la catégorie
  const handleEditCategory = (category) => {
    setEditedCategory(category);
    setEditedCategoryName(category);
  };

  const handleSaveEditCategory = async () => {
    try {
      const updatedCategories = categories.map((category) => {
        return category === editedCategory ? editedCategoryName : category;
      });

      setCategories(updatedCategories);

      const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
        const newCategories = modele.categorie.map((cat) => (cat === editedCategory ? editedCategoryName : cat));
        return { ...modele, categorie: newCategories };
      });

      setListeCatégoriesDétaillés(updatedModels);

      for (let model of updatedModels) {
        const updateData = { categorie: model.categorie };
        await customAxios.patch(`/api/modeles/ModifieOptionsDuModele/${model._id}`, updateData);
      }

      setEditedCategory(null);
      setEditedCategoryName("");
    } catch (error) {
      console.error("Erreur lors de la mise à jour des catégories :", error);
    }
  };

  const handleCancelEditCategory = () => {
    setEditedCategory(null);
    setEditedCategoryName("");
  };

  const handleEditModele = (modele) => {
    setEditModele(modele);
    setEditValues({ nom: modele.nom, cible: modele.cible });
  };

  const handleSaveEdit = async () => {
    try {
      const updatedModels = ListeCatégoriesDétaillés.map((modele) => {
        if (modele._id === editModele._id) {
          return { ...modele, nom: editValues.nom, cible: editValues.cible };
        }
        return modele;
      });

      setListeCatégoriesDétaillés(updatedModels);
      await customAxios.patch(`/api/modeles/ModifieOptionsDuModele/${editModele._id}`, { nom: editValues.nom, cible: editValues.cible });

      setEditModele(null);
    } catch (error) {
      console.error(error);
      alert("Une erreur est survenue lors de la mise à jour sur le serveur.");
    }
  };

  const handleCancelEdit = () => {
    setEditModele(null);
    setEditValues({ nom: "", cible: "" });
  };

  const sortData = (data, config) => {
    const { key, direction } = config;
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderTableHeader = () => {
    return (
      <>
        <th style={{ textAlign: "left", fontWeight: 600, cursor: 'pointer' }} data-tooltip-id={`Nom du contrat`} data-tooltip-content={"Type de contrat."} data-tooltip-place="bottom" onClick={() => handleSort('nom')}>
          MODELES
        </th>
        <th style={{ textAlign: "left", fontWeight: 600, cursor: 'pointer' }} data-tooltip-id={`Cible du Projet`} data-tooltip-content={"A qui s'adresse ce projet?"} data-tooltip-place="bottom" onClick={() => handleSort('cible')}>
          CIBLES
        </th>
        {categories.map((category, index) => (
          <th key={index} style={{ textAlign: "center", fontWeight: 600, padding: "0 10px" }} data-tooltip-id={`Catégorie ${category}`} data-tooltip-content={"Catégories proposées à la création d'une nouvelle agence"} data-tooltip-place="bottom">
            <div style={{ display: 'inline-block', border: '1px solid white', padding: '5px', borderRadius: '5px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
              {editedCategory === category ? (
                <>
                  <input type="text" value={editedCategoryName} onChange={(e) => setEditedCategoryName(e.target.value)} />
                  <button onClick={handleSaveEditCategory} style={{ marginLeft: "5px" }}>
                    ✓
                  </button>
                  <button onClick={handleCancelEditCategory} style={{ marginLeft: "5px" }}>
                    x
                  </button>
                </>
              ) : (
                <>
                  {category}
 

                    <button
                      onClick={() => handleEditCategory(category)}
                      data-tooltip-id="edit-tooltip"
                      data-tooltip-content="Éditer la catégorie"
                      style={{ color: "blue", border: "none", background: "none", cursor: "pointer" }}
                    >
                      <BsPencil style={{ color: "var(--texte-zone)", cursor: "pointer" }} />
                    </button>

                    <button
                      onClick={() => handleRemoveCategory(category)}
                      data-tooltip-id="delete-tooltip"
                      data-tooltip-content="Supprimer la catégorie"
                      style={{ color: "red", border: "none", background: "none", cursor: "pointer" }}
                    >
                      <BsTrash style={{ color: "var(--texte-zone)", cursor: "pointer"}} />
                    </button>

                    {/* Ajouter les tooltips au rendu */}
                    <Tooltip id="edit-tooltip" place="bottom" effect="solid" className="custom-tooltip" />
                    <Tooltip id="delete-tooltip" place="bottom" effect="solid" className="custom-tooltip" />
                                    </>
              )}
            </div>
          </th>
        ))}
      </>
    );
  };

  const renderTableBody = () => {
    const sortedModeles = [...ListeCatégoriesDétaillés].sort((a, b) => 
      a.nom.localeCompare(b.nom)
    );
    return sortedModeles.map((modele, index) => (
      <tr key={modele._id} className={index % 2 === 0 ? "bg-custom" : ""}>
        <td>
          {editModele && editModele._id === modele._id ? (
            <input type="text" value={editValues.nom} onChange={(e) => setEditValues({ ...editValues, nom: e.target.value })} />
          ) : (
            <label className={`${modele._id === selectedModeleId ? "label-semi-gras" : ""}`} htmlFor={`flexCheckDefault${modele._id}`} style={{ cursor: "pointer" }} onClick={() => handleLabelClick(modele._id)}>
              <span style={{ marginLeft: "5px" }}>{modele.nom}</span>
              {modele.categorie.length === 0 && (
                <span style={{ color: "yellow", marginLeft: "10px", fontSize: '20px' }} data-tooltip-id={`noCategory-${modele._id}`} title="Aucune catégorie sélectionnée">
                  ⚠️
                </span>
              )}
              {(!modele.etapes || modele.etapes.length === 0) && (
                <span style={{ color: "green", marginLeft: "10px", fontSize: '20px' }} data-tooltip-id={`noStep-${modele._id}`} title="Aucune étape rattachée">
                  ▲
                </span>
              )}
            </label>
          )}
        </td>

        <td>
          {editModele && editModele._id === modele._id ? (
            <select value={editValues.cible} onChange={(e) => setEditValues({ ...editValues, cible: e.target.value })}>
              {toutesLesCibles.map((cible) => (
                <option key={cible} value={cible}>
                  {cible}
                </option>
              ))}
            </select>
          ) : (
            <label>{modele.cible}</label>
          )}
        </td>
        {categories.map((category, index) => (
          <td key={index} style={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input type="checkbox" name={`category${index}`} id={`checkboxCategory${modele._id}${index}`} checked={modele.categorie.includes(category)} onChange={() => handleCategoryChange(modele._id, category)} />
            </div>
          </td>
        ))}
        {editModele && editModele._id === modele._id && (
          <td>
            <button className="btn btn-success" onClick={handleSaveEdit}>
              Sauvegarder
            </button>
            <button className="btn btn-secondary" onClick={handleCancelEdit} style={{ marginLeft: "5px" }}>
              Annuler
            </button>
          </td>
        )}
        {!editModele && (
          <td>
            <button className="btn btn-primary" onClick={() => handleEditModele(modele)}>
              Modifier
            </button>
          </td>
        )}
      </tr>
    ));
  };

  const renderContent = () => {
    if (!userId) return <p>Non connecté</p>;
    if (ListeCatégoriesDétaillés.length === 0) return <p>Aucun modèle trouvé.</p>;
    return (
      <>
        <table>
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>

        <Tooltip id="Nom du contrat" place="bottom" effect="solid" className="custom-tooltip" />
        <Tooltip id="Cible du Projet" place="bottom" effect="solid" className="custom-tooltip" />
        {categories.map((category, index) => (
          <Tooltip key={index} id={`Catégorie ${category}`} place="bottom" effect="solid" className="custom-tooltip" />
        ))}
           {ListeCatégoriesDétaillés.map((modele) => (
          <React.Fragment key={modele._id}>
            <Tooltip id={`noCategory-${modele._id}`} place="bottom" effect="solid" className="custom-tooltip">
              Aucune catégorie sélectionnée
            </Tooltip>
            <Tooltip id={`noStep-${modele._id}`} place="bottom" effect="solid" className="custom-tooltip">
              Aucune étape rattachée
            </Tooltip>
          </React.Fragment>
        ))}
      </>
    );
  };


  const renderInfoWindow = () => {
    if (!showInfo) return null;

    return (
      <div className="info-window">
        <p>Voici une explication détaillée sur la fonctionnalité des catégories et des modèles de références :</p>
        <ul>
          <li>Ce tableau liste les modèles et les catégories associées pour la création des Agences.</li>
          <li>Il est possible d'ajouter une nouvelle catégorie. Si elle n'est pas associée à un modèle, elle sera supprimée.</li>
          <li>Par exemple, si l'agence choisit d'utiliser le modèle illiCO travaux ou Camif Habitat, alors le modèle PARTENARIAT sera créé.</li>
          <li>Il est possible de modifier les noms des modèles et les cibles pour chaque type de projet, en fonction des besoins de l'agence.</li>
          <li>Le modèle PARTENARIAT est un modèle de projet associé aux PARTENAIRES. Les PARTENAIRES sont acteurs du projet Client.</li>
          <li>Les modèles AMO, MOE, CG, etc., sont des modèles de projets associés aux CLIENTS.</li>
          <li>Règles importantes:</li>
          <li>CLIENTS peuvent être associés à plusieurs MODELES</li>
          <li>PARTENAIRES, AGENCE, FOURNISSEUR, PRESTATAIRES peuvent être associés à UN SEUL MODELE</li>
        </ul>
        <button className="btn btn-secondary" onClick={() => setShowInfo(false)}>
          Fermer
        </button>
      </div>
    );
  };


  return (
    <>
      <div className="form-group">
        <h5>
          <FaInfoCircle onClick={handleInfoClick} style={{ cursor: "pointer", marginRight: "10px"  }} /> 
          Tableau des Catégories pour la création des Agences
        
        </h5>
        {renderInfoWindow()}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', width: '40%' }}>
          <input
            type="text"
            className="form-control"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Nouvelle catégorie"
            style={{ flex: 1 }} // Prend tout l'espace disponible à l'intérieur du div
          />
          <button className="btn btn-primary" onClick={handleAddCategory} style={{ marginLeft: '10px' }}>
            Ajouter une catégorie
          </button>
        </div>
      </div>
      <div className="cadre-zone2">{renderContent()}</div>
    </>
  );
}
