
import customAxios from "../Utilities/getAxiosToken";
// import logger from "./Logger";

/**
 * Formatte une date ISO en un format lisible pour les noms de fichier
 * @param {string} isoDate - La date en format ISO
 * @returns {string} - La date formatée
 */
const formatDateForFilename = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}_${month}_${year} ${hours}:${minutes}:${seconds}`;
};

/**
 * Ouvre un fichier dans un nouvel onglet
 * @param {string} fileId - L'identifiant du fichier
 */
const openFile = async (fileId) => {
    const fileUrl = `/api/files/fichier/${fileId}`;
    try {
        const response = await customAxios.get(fileUrl, {
            responseType: "blob", // Télécharger en tant que blob
        });

        // Créer un URL blob pour le fichier
        const blob = new Blob([response.data], { type: response.headers["content-type"] || "application/pdf" });
        const blobUrl = window.URL.createObjectURL(blob);

        // Ouvrir le fichier dans un nouvel onglet
        window.open(blobUrl, "_blank", "noopener,noreferrer");

        // Nettoyer l'URL blob après utilisation
        setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
        }, 10000);
    } catch (error) {
        console.error("Erreur lors de la récupération du fichier :", error);
        alert("Erreur lors de l'ouverture du fichier.");
    }
};

/**
 * Télécharge un fichier avec un nom formaté
 * @param {string} fileId - L'identifiant du fichier
 */
const downloadFile = async (fileId) => {
    const fileUrl = `/api/files/fichier/${fileId}`;
    try {
        const response = await customAxios.get(fileUrl, {
            responseType: "blob", // Télécharger en tant que blob
        });

        // Récupérer les métadonnées depuis les en-têtes HTTP
        const filename = decodeURIComponent(response.headers["x-file-name"] || "Fichier_inconnu");
        const rubrique = response.headers["x-rubrique"] || "";
        const prefixe = response.headers["x-prefixe"] || "";
        const uploadDate = response.headers["x-upload-date"] || "";
        const nomProjet = decodeURIComponent(response.headers["x-nom-projet"] || "Projet_inconnu");
        const nomTiers = decodeURIComponent(response.headers["x-nom-tiers"] || "Tiers_inconnu");

        // Créer un nom de fichier basé sur les métadonnées
        const dateFichier = formatDateForFilename(uploadDate);
        const formattedFilename = `${prefixe}_${nomProjet}_${nomTiers} ${dateFichier || "no_date"}.pdf`;

        // Créer un lien de téléchargement
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = formattedFilename;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    } catch (error) {
        console.error("Erreur lors de la récupération du fichier :", error);
        alert("Erreur lors du téléchargement du fichier.");
    }
};



/**
 * Hook personnalisé pour gérer les actions sur les fichiers
 * @returns {object} - Fonctions pour ouvrir, télécharger et prévisualiser des fichiers
 */
const useHandleFileClick = () => {
    return { openFile, downloadFile };
};

export default useHandleFileClick;