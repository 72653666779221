import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import BoutonDownLoad from "../Actions/BoutonDownLoad";
import { SelectUtilisateurByTiersId } from "../Tiers/SelectUtilisateurByTiersid"; // Nouveau composant

export const RequêteExpress = React.memo(() => {
  const [actionsFichier, setActionsFichier] = useState([]);
  const [selectedRubrique, setSelectedRubrique] = useState("");
  const [selectedPrefixe, setSelectedPrefixe] = useState("");
  const [selectedUtilisateur, setSelectedUtilisateur] = useState(null);
  const [note, setNote] = useState("");
  const { userTiersId } = useContext(AuthContext);
  const [typeTiers, setTypeTiers] = useState("");

  // Récupération du type de tiers
  useEffect(() => {
    const fetchTypeTiers = async () => {
      try {
        const response = await customAxios.get(`/api/tiers/${userTiersId}`);
        setTypeTiers(response.data.typeTiers);
      } catch (error) {
        console.error("Erreur lors de la récupération du type de tiers :", error);
      }
    };
    if (userTiersId) {
      fetchTypeTiers();
    }
  }, [userTiersId]);

  // Récupération des actions fichiers en fonction du type de tiers
  useEffect(() => {
    const fetchActionsFichier = async () => {
      try {
        const response = await customAxios.get(`/api/actions/typesActions/${typeTiers}/FICHIER`);
        setActionsFichier(response.data.actionsFichier);
      } catch (error) {
        console.error("Erreur lors de la récupération des actions fichiers :", error);
      }
    };
    if (typeTiers) {
      fetchActionsFichier();
    }
  }, [typeTiers]);

  // Liste unique des rubriques
  const uniqueRubriques = Array.from(new Set(actionsFichier.map((action) => action.rubrique)));

  // Gestion du changement de rubrique
  const handleRubriqueChange = (event) => {
    const selectedRubrique = event.target.value;
    setSelectedRubrique(selectedRubrique);
    setSelectedPrefixe(""); // Réinitialise le préfixe lorsque la rubrique change.

    // Si un seul préfixe est disponible, l'affecter automatiquement
    const prefixes = actionsFichier
      .filter((action) => action.rubrique === selectedRubrique)
      .map((action) => action.prefixe);
    if (prefixes.length === 1) {
      setSelectedPrefixe(prefixes[0]);
    }
  };

  // Liste des préfixes filtrés
  const filteredPrefixes = actionsFichier
    .filter((action) => action.rubrique === selectedRubrique)
    .map((action) => action.prefixe);

  return (
    <div style={{ padding: "20px" }}>
      {/* Ligne 1 : Sélection du type de document */}
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="type-document">Type de document :</label>
        <select
          id="type-document"
          value={selectedRubrique}
          onChange={handleRubriqueChange}
          style={{ padding: "5px", width: "100%", marginTop: "5px" }}
        >
          <option value="">-- Sélectionner un type de document --</option>
          {uniqueRubriques.map((rubrique, index) => (
            <option key={index} value={rubrique}>
              {rubrique}
            </option>
          ))}
        </select>
      </div>

      {/* Ligne 2 : Sélection du préfixe (si nécessaire) */}
      {filteredPrefixes.length > 1 && (
        <div style={{ marginBottom: "20px" }}>
          <label htmlFor="prefixe">Préfixe :</label>
          <select
            id="prefixe"
            value={selectedPrefixe}
            onChange={(e) => setSelectedPrefixe(e.target.value)}
            style={{ padding: "5px", width: "100%", marginTop: "5px" }}
          >
            <option value="">-- Sélectionner un préfixe --</option>
            {filteredPrefixes.map((prefixe, index) => (
              <option key={index} value={prefixe}>
                {prefixe}
              </option>
            ))}
          </select>
        </div>
      )}
      {filteredPrefixes.length === 1 && (
        <div style={{ marginBottom: "20px" }}>
          <p>
            Préfixe sélectionné automatiquement : <strong>{filteredPrefixes[0]}</strong>
          </p>
        </div>
      )}

      {/* Ligne 3 : Sélection de l'utilisateur */}
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="utilisateur">À qui adresser la demande :</label>
        <SelectUtilisateurByTiersId onSelect={setSelectedUtilisateur} />
        {selectedUtilisateur && (
          <div style={{ marginTop: "10px" }}>
            Destinataire sélectionné : {selectedUtilisateur.prenom} {selectedUtilisateur.nom}
          </div>
        )}
      </div>

      {/* Ligne 4 : Champ de note */}
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="note">Note :</label>
        <textarea
          id="note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Ajouter une note pour la demande..."
          style={{
            width: "100%",
            padding: "10px",
            marginTop: "5px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            boxSizing: "border-box",
          }}
        />
      </div>

      {/* Ligne 5 : Bouton de téléchargement */}
      <div>
        <BoutonDownLoad
          etape={{
            rubrique: selectedRubrique,
            prefixe: selectedPrefixe,
            utilisateur: selectedUtilisateur,
            note,
          }}
        />
      </div>
    </div>
  );
});
