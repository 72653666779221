import React, { useContext, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import { AuthContext } from "../../contexts/AuthProvider";
import useFetchHelpers from "../Utilities/GetModeles";
import { useCreateNewProjet } from "../Utilities/GetProjets";
import { useProjet } from "../../contexts/ProjetContext";
import { useGetTiers } from "../Utilities/GetTiers";

export function NouveauProjet({typeTiers}) {
  const { createNewProjet } = useCreateNewProjet();
  const { fetchModeles } = useFetchHelpers();
  const { getTypeTiersByTiersId } = useGetTiers();
  const { refreshProjects } = useProjet();
  const { userId, tiersId, agenceId, handleProjetId, handleModeleId } = useContext(AuthContext);

  const [listeModeles, setListeModeles] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const chargerModeles = async () => {
      try {
        const tousLesModeles = await fetchModeles(agenceId);
        console.log("NouveauProjet tousLesModeles", tousLesModeles);
        const typeTiers = await getTypeTiersByTiersId(tiersId);
        console.log("NouveauProjet typeTiers", typeTiers);

        // Filtrer les modèles actifs correspondant au type de tiers
        const modelesFiltres = tousLesModeles.filter(
          (modele) => modele.cible === typeTiers && modele.isActif === true
        );

        console.log("NouveauProjet modelesFiltres", modelesFiltres);
        setListeModeles(modelesFiltres);
      } catch (error) {
        console.error("Erreur lors du chargement des modèles :", error);
      }
    };

    chargerModeles();
  }, [tiersId]);

  const handleModeleClick = async (modeleId) => {
    try {
      const accesTiers = [{ utilisateur: userId, etat: "ouvert", dateArchivage: null }];
      const nouveauProjet = await createNewProjet(modeleId, typeTiers, tiersId, userId, "A définir", accesTiers);

      refreshProjects();
      handleProjetId(nouveauProjet._id);
      handleModeleId(modeleId);
      console.log("Nouveau projet créé :", nouveauProjet);
    } catch (error) {
      console.error("Erreur lors de la création du projet :", error);
    }
    setShowDropdown(false);
  };

  return (
    <div className="nouveau-projet text-center">
      <Dropdown onToggle={() => setShowDropdown(!showDropdown)} show={showDropdown}>
        <Dropdown.Toggle as={CustomToggle} className="btn btn-primary d-flex align-items-center">
          <BsPlusCircle size="1.4em" className="me-2" />
          Créer un projet
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-center">
          {/* ✅ Ajout d'un titre en haut du menu */}
          <Dropdown.Header className="text-center fw-bold text-primary">
            Choisir un type de projet
          </Dropdown.Header>

          {/* ✅ Ajout d'un séparateur pour plus de clarté */}
          <Dropdown.Divider />

          {listeModeles.length > 0 ? (
            listeModeles.map((modele) => (
              <Dropdown.Item key={modele._id} onClick={() => handleModeleClick(modele._id)}>
                {modele.nom}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item disabled>Aucun modèle disponible</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

// Personnalisation du bouton du dropdown
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="text-decoration-none"
  >
    {children}
  </a>
));