import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";

// Création du contexte WebSocket
const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Permet de stocker ou remplacer le socket
  const setSocketHandle = (newSocket) => {
    console.log("WebSocketContext setSocketHandle", newSocket);

    if (socket) {
      console.log("Fermeture de l'ancien socket :", socket.id);
      socket.disconnect();
    }
    setSocket(newSocket);
    if (newSocket) {
      newSocket.on("connect", () => {
        console.log("WebSocketProvider: WebSocket connecté :", newSocket.id);
        setIsInitialized(true);
      });

      newSocket.on("disconnect", (reason) => {
        console.log("WebSocketProvider: WebSocket déconnecté :", reason);
        setIsInitialized(false);
      });

      newSocket.on("connect_error", (error) => {
        console.error("WebSocketProvider: Erreur de connexion WebSocket :", error.message);
      });
    }
  };

  return (
    <WebSocketContext.Provider value={{ socket, isInitialized, setSocketHandle }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Renommer la fonction pour éviter le conflit
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket doit être utilisé dans un WebSocketProvider");
  }
  return context;
};