import React, { useContext, useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";

import {
  BsPlusCircle,
  BsSearch,
  BsX,
  BsBug,
  BsEye,
  BsEyeSlash,
} from "react-icons/bs"; // Ajout de l'icône Bug

import "./css/Footer.css";
import "../css/Global.css";
import { AuthContext } from "../../contexts/AuthProvider";
import { CheckServeurStatus } from "../Serveur/CheckServeurStatus";
import { CheckProxyStatus } from "../Proxy/CheckProxyStatus";

import Version from "./../Version/Version"; // Importer le composant Version
import { useCreateNewProjet } from "../Utilities/GetProjets";
import { useProjet } from "../../contexts/ProjetContext";
import { useGetTiers } from "../Utilities/GetTiers";

import useFetchHelpers from "../Utilities/GetModeles";

export function Footer() {
  const { createNewProjet } = useCreateNewProjet();
  const { getTypeTiersByTiersId } = useGetTiers();
  const {
    isAdmin,
    isManager,
    isAgence,
    isPartenaire,
    isPrestataire,
    isClient,
    handleSelectedMenu,
    selectedMenu,
    userId,
    tiersId,
    projetId,
    handleProjetId,
    handleModeleId,
    agenceId,
    handleAddNewCorpsDeMetier,
    handleAddNewAction,
    debugAffichage, // Récupérer l'état debug
    handleDebugAffichage, // Méthode pour activer/désactiver le debug
    toggleSearch,
    showSearch,
    handleSearchTerm,
    searchTerm,
    handleShowSearch,
    isVueManager,
    handleVueManager,
  } = useContext(AuthContext);

  const [listeModelesDétaillés, setListeModelesDétaillés] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState(null);
  const [typeTiers, setTypeTiers] = useState(null);
  const { refreshProjects } = useProjet();
  const { fetchModeles } = useFetchHelpers();

  const toggleVueManager = () => {
    handleVueManager(!isVueManager); // Inverse l'état de isVueManager
  };

  const toggleDebug = () => {
    handleDebugAffichage(!debugAffichage); // Inverse l'état du mode debug
  };

  useEffect(() => {
    setMenuOptions(getMenuOptions()); // Mise à jour des options du menu quand selectedMenu change
  }, [
    selectedMenu,
    isAdmin,
    isManager,
    isAgence,
    isPartenaire,
    isPrestataire,
    isClient,
    tiersId,
    projetId,
  ]);

  useEffect(() => {
    const chargerTousLesModeles = async () => {
      try {
        const tousLesModeles = await fetchModeles(agenceId);
        // logger.log("Footer tousLesModeles", tousLesModeles);
        // On filtre les modeles adapté au typeTiers
        // On récupère le typeTiers de tiersId
        const typeTiers = await getTypeTiersByTiersId(tiersId);
        setTypeTiers(typeTiers);
        // On filtre les modeles adapté au typeTiers et actif
        const modelesFiltres = tousLesModeles.filter(
          (modele) => modele.cible === typeTiers && modele.isActif === true
        );
        // logger.log("Footer setListeModelesDétaillés", tousLesModeles);
        setListeModelesDétaillés(modelesFiltres);
      } catch (erreur) {
        console.error("Erreur lors de la récupération des modèles :", erreur);
        // Gérer l'erreur comme il se doit
      }
    };

    chargerTousLesModeles();
  }, [userId, menuOptions]);

  useEffect(() => {
    if (selectedMenu) {
      handleClearSearch();
      handleShowSearch();
    }
  }, [selectedMenu]);

  const handleMenuClick = (menuKey) => {
    logger.log(`Footer Menu clicked: ${menuKey}`);
    // Fermer le dropdown après la sélection
    setSelectedMenuOption(menuKey);
    setShowDropdown(false);

    switch (menuKey) {

      case "NEW_PROJECT":
        setShowDropdown(!showDropdown); // Ou toute autre logique spécifique à "NEW PROJECT"
        break;

      case "NEW_METIER":
        handleAddNewCorpsDeMetier(true); // Supposons que cela ajoute un nouvel échéancier
        break;

      case "NEW_ACTION":
        handleAddNewAction(true); // Supposons que cela ajoute un nouvel échéancier
        break;

      default:
        setSelectedMenuOption(menuKey);
        // Autres actions par défaut ou rien
        handleSelectedMenu(menuKey);
        handleProjetId(null);
        handleModeleId(null);
        setShowDropdown(false);
        break;
    }
  };

  const handleSelectionModeleClick = (modeleId) => {
    logger.log(`Menu handleSelectionModeleClick: ${modeleId}`);

    const createProjet = async () => {
      try {
        const descriptionProjet = "A définir";

        const accesTiers = [
          {
            utilisateur: userId, // Utilisateur à partir de l'id
            etat: "ouvert", // État par défaut
            dateArchivage: null, // Date d'archivage nulle par défaut
          },
        ];

        const nouveauProjet = await createNewProjet(
          modeleId,
          typeTiers,
          tiersId,
          userId,
          null, // Supposé que cela représente un paramètre requis par createNewProjet
          descriptionProjet,
          accesTiers
        );

        refreshProjects();
        // Logique de suivi ou de mise à jour de l'état avec le nouveau projet créé
        logger.log("Nouveau projet créé :", nouveauProjet);
        // Par exemple, mettre à jour l'état du composant ou naviguer vers une nouvelle vue
      } catch (erreur) {
        console.error("Erreur lors de la création du nouveau projet :", erreur);
        // Gérer l'erreur, par exemple, en affichant un message à l'utilisateur
      }
    };

    // Appel de la fonction asynchrone interne
    createProjet();
    setShowDropdown(false);
  };

  const getMenuOptions = () => {
    let options = [];
    let role = "";

    if (isAdmin) role = "admin";
    else if (isManager) role = "manager";
    else if (isPrestataire || isAgence) role = "agence";
    else if (isPartenaire) role = "partenaire";
    else if (isClient) role = "client";

    logger.log(
      "Footer getMenuOptions listeModelesDétaillés",
      listeModelesDétaillés
    );
    // eslint-disable-next-line default-case
    switch (selectedMenu) {
      case "CLIENTS":
        if (tiersId) {
          options.push({ key: "NEW PROJECT", label: "NOUVEAU PROJET" });
        }
        break;


      case "PARTENAIRES":
        if (tiersId) {
          options.push({ key: "NEW_CONTACT", label: "NOUVEAU CORRESPONDANT" });
        }
        break;
      case "ADMIN AGENCES":
        if (tiersId) {
          options.push({ key: "NEW_CONTACT", label: "NOUVEL UTILISATEUR" });
        }
        break;

      case "REQUÊTES":
        options.push({ key: "NEW_REQUETE", label: "NOUVELLE REQUÊTES" });
        break;

      case "ADMIN MÉTIERS":
        options.push({ key: "NEW_METIER", label: "NOUVEAU CORPS DE MÉTIER" });
        break;

      case "ADMIN ACTIONS":
        options.push({ key: "NEW_ACTION", label: "NOUVELLE ACTION" });
        break;

      // Ajoutez plus de cas au besoin
    }

    // eslint-disable-next-line default-case
    switch (role) {
      case "admin":
        options.push({ key: "NEW AGENCE", label: "NOUVELLE AGENCE" });
        options.push({ key: "NEW MODÈLE", label: "NOUVEAU MODÈLE" });
        break;

      case "manager":
        // options.push({ key: "NEW CLIENT", label: "NOUVEAU CLIENT" });
        options.push({ key: "NEW PARTENAIRE", label: "NOUVEAU PARTENAIRE" });
        options.push({ key: "NEW PRESTATAIRE", label: "NOUVEAU PRESTATAIRE" });
        break;

      // Utilisateur Lambda
      case "agence":
        // options.push({ key: "NEW CLIENT", label: "NOUVEAU CLIENT" });
        options.push({ key: "NEW PARTENAIRE", label: "NOUVEAU PARTENAIRE" });
        break;
      // Ajoutez plus de cas spécifiques aux rôles au besoin
    }

    return options;
  };

  const handleMouseOver = (key) => {
    setSelectedMenuOption(key);
  };

  const handleMouseLeave = () => {
    setSelectedMenuOption("");
  };

  const handleClearSearch = () => {
    handleSearchTerm("");
  };

  return (
    <footer className="custom-footer">
      {/* Dropdown avec BsPlusCircle au centre */}
      <div
        className="icon-center"
        style={{ flex: 1, display: "flex", justifyContent: "left" }}
      >
        <Dropdown
          onToggle={() => setShowDropdown(!showDropdown)}
          show={showDropdown}
        >
          <Dropdown.Toggle as={CustomToggle}>
            <BsPlusCircle
              size="1.5em"
              style={{
                color: "var(--texte-fond)",
              }}
              className="icon-style"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              position: "absolute",
              transform: "translateX(-50%)", // Décalage pour centrage précis
              zIndex: 1050,
              borderRadius: "var(--border-radius-cadre)",
              backgroundColor: "var(--bg-zone)",
              // padding: "15px",
              color: "var(--texte-fond)",

              border: "var(--border-zone)",
              // outline: "var(--outline-zone)",
              boxShadow: "var(--box-shadow-zone)",
            }}
          >
            {menuOptions.map((option, index) => (
              <div
                key={index}
                onMouseOver={() =>
                  option.key === "NEW PROJECT"
                    ? handleMouseOver(option.key)
                    : null
                }
                onMouseLeave={handleMouseLeave}
              >
                <Dropdown.Item
                  key={`${option.key}-${index}`}
                  style={{
                    fontSize: "var(--fs-normale)",
                    borderRadius: "var(--border-radius-cadre-interieur)",
                    color: "var(--texte-zone)",
                    backgroundColor:
                      selectedMenuOption === option.key
                        ? "var(--bg-gris-tres-fonce)"
                        : "transparent",
                  }}
                  onClick={() => handleMenuClick(option.key)}
                >
                  {option.label}
                </Dropdown.Item>
                {selectedMenuOption === "NEW PROJECT" &&
                  option.key === "NEW PROJECT" && (
                    <div
                      style={{
                        position: "absolute",
                        left: "100%",
                        top: 0,
                        backgroundColor: "white",
                        padding: "10px",
                        boxShadow: "0 0 5px rgba(0,0,0,.2)",
                      }}
                    >
                      {listeModelesDétaillés.map((modele) => (
                        <div
                          key={modele._id}
                          className="modele-item" // Ajoutez cette classe à chaque élément
                          onClick={() => handleSelectionModeleClick(modele._id)}
                        >
                          {modele.nom}
                        </div>
                      ))}

                      {listeModelesDétaillés.map((modele) => (
                        <div
                          key={modele.id}
                          onClick={() => handleMenuClick(modele.nom)}
                        >
                          {modele.nom}
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Icône de recherche entre le dropdown et CheckServeurStatus */}
      <div
        style={{
          display: "flex",
          color: "var(--texte-fond)",
          justifyContent: "right",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <BsSearch
          size="1.2em"
          onClick={toggleSearch}
          style={{
            cursor: "pointer",
            color: "var(--texte-fond)",
            margin: "0 20px", // Espacement entre les éléments
            flex: 1,
            textAlign: "center", // Centrer l'icône dans sa zone
          }}
        />
        {showSearch && (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              color: "var(--texte-fond)",
            }}
          >
            <input
              autoFocus
              placeholder="Rechercher..."
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearchTerm(e.target.value)}
              style={{
                padding: "2px 30px 2px 8px",
                width: "200px",
                border: "none",
                outline: "none",
              }}
            />
            {searchTerm && (
              <BsX
                size="1.3em"
                onClick={handleClearSearch}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            )}
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "15px",
        }}
      >

{isManager && (
  <>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={toggleVueManager}
    >
      {isVueManager ? (
        <BsEyeSlash
          size="1.3em"
          style={{ color: "var(--texte-important)" }}
        />
      ) : (
        <BsEye size="1.3em" style={{ color: "var(--texte-fond)" }} />
      )}
      <span style={{ marginLeft: "5px", color: "var(--texte-fond)" }}>
        {isVueManager ? "Vue Manager Activée" : "Vue Manager Désactivée"}
      </span>
    </div>

    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={toggleDebug} // Active/Désactive le mode debug
    >
      <BsBug
        size="1.3em"
        style={{
          color: debugAffichage
            ? "var(--texte-important)"
            : "var(--texte-fond)",
        }}
      />
      <span style={{ marginLeft: "5px", color: "var(--texte-fond)" }}>
        {debugAffichage ? "Debug Activé" : "Debug Désactivé"}
      </span>
    </div>
  </>
)}

        <Version />
        <CheckProxyStatus />
        <CheckServeurStatus />
      </div>
    </footer>
  );
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ display: "flex", justifyContent: "center", width: "100%" }}
  >
    {children}
  </a>
));
