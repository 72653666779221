import React, { useContext, useState, useEffect } from "react";
import { ProjetByProjetId } from "./Projets/ProjetByProjetId";
import { ProjetNomByProjetId } from "./Projets/ProjetNomByProjetId";

import { GetModeleById } from "./Modeles/GetModeleById";
import { AfficheTiersByTiersId } from "./Tiers/AfficheTiersByTiersId";
import { CreateNewTiers } from "./Tiers/CreateNewTiers";
import "./css/Global.css";
import "./css/Zone3.css";

import {Siret} from "./Siret/Siret"
import { AuthContext } from "../contexts/AuthProvider";
import { SyntheseGlobale } from "./Synthèses/SyntheseGlobale";
import { ListeDestinataires } from "./Destinataires/ListeDestinataires";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";


export function Zone3() {
  const {
    selectedMenu,
    projetId,
    modeleId,
    tiersId,
    debugAffichage,
    isAgence,
    isPrestataire,
    isDestinatairesOpen,
    handleToggleDestinataires,
    isCreateNewTiersOpen, typeTiersToCreate,
    closeCreateNewTiers,
    handleProjetId,
    handleTiersId
  } = useContext(AuthContext);

  logger.log("Zone3 selectedMenu", selectedMenu);

  useEffect(() => {
    console.log("Zone3 monté");
    return () => {
      console.log("Zone3 démonté");
    };
  }, []);

  useEffect(() => {
    console.log("Zone 3: 🔄 Changement de menu détecté :", selectedMenu);
    handleTiersId(null);

    closeCreateNewTiers();
    }, [selectedMenu]);

    useEffect(() => {
        console.log("Zone 3: 🔄 Changement de tiers détecté :", tiersId);
        if (tiersId) { // ✅ Fermer `CreateNewTiers` seulement si un tiers est sélectionné
          closeCreateNewTiers();
        }
    }, [tiersId]);

  const toggleDestinataires = () => {
    handleToggleDestinataires(!isDestinatairesOpen);
  };

  // logger.log("Zone3 selectedMenu", selectedMenu, "selectedArchive",selectedArchive, " projetId ", projetId);

  return (
      <div className={`zone-3  marge-zones }`}>
      {/* Juste pour savoir où sont les zones */}
      {debugAffichage && <h4 className="zone-title">Zone 3</h4>}

      {projetId &&
        (isAgence || isPrestataire) &&  
        (selectedMenu === "PROJETS CLIENTS" || selectedMenu === "ALL PROJETS" ||  
          selectedMenu === "PROJETS PARTENAIRES" ||  selectedMenu === "PROJETS FOURNISSEURS"  || 
          selectedMenu === "PROJETS PRESTATAIRES" || selectedMenu === "PROJET AGENCE" )  && 
        (
          <div>
            {/* On récupère toutes les informations du projets */}
            <ProjetByProjetId />
            <ProjetNomByProjetId />  
          </div>
        )}

        {selectedMenu.includes("PROJET") && !projetId && (
          <div className="form-group cadre-zone3">
            <h5 className="titre-zone3" style={{ textAlign: "center" }}>
              Sélectionner un projet pour afficher les détails
            </h5>
          </div>
        )}


      { selectedMenu === "NEW AGENCE"  && (
                <>
                {/* Test Siret */}
                  <Siret />
                </>
              )}

 

        {selectedMenu === "REQUÊTES" && 
        (
          <>
            <div  className="cadre-zone3 d-flex justify-content-center align-items-center section-header">
              <div className="section-title" onClick={toggleDestinataires}
               style={{fontWeight: "400"}}>
                    VOIR DESTINATAIRES
              </div>
              <div
                onClick={toggleDestinataires}
                className="icon-size"
                data-tooltip-id="TooltipGDestinataires"
                data-tooltip-content="Cliquez pour Sélectionner les Destinataires. Sélectionner une étape éligible."
              >
                {isDestinatairesOpen ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronRight />
                )}
              </div>
            </div>
            <ListeDestinataires typeTiers={[
              // "CLIENT", 
              "PARTENAIRE", "AGENCE",
              // "PRESTATAIRE",
              // "FOURNISSEUR"
              ]} />
          </>
        )}



      {selectedMenu === "SYNTHÈSES" && (
        <>
          {/* Synthèse juste sur les projets de userId*/}
          <SyntheseGlobale AgenceAuComplet={false} />
        </>
      )}
      {selectedMenu === "ALL SYNTHÈSES" && (
        <>
          {/* Synthèse sur tous les projets de l'agence */}
          <SyntheseGlobale AgenceAuComplet={true} />
        </>
      )}

      {/* {selectedMenu === "NOUVEAU CLIENT" &&   <CreateNewTiers typeTiers="CLIENT" />} */}
      {/* {selectedMenu === "NOUVEAU PARTENAIRE" &&   <CreateNewTiers typeTiers="PARTENAIRE" />}
      {selectedMenu === "NOUVEAU PRESTATAIRE" &&   <CreateNewTiers typeTiers="PRESTATAIRE" />}
      {selectedMenu === "NOUVELLE AGENCE" &&   <CreateNewTiers typeTiers="AGENCE" />}
      {selectedMenu === "NOUVEAU FOURNISSEUR" &&   <CreateNewTiers typeTiers="FOURNISSEUR" />} */}


      {modeleId && selectedMenu === "ADMIN MODÈLES" && (
        <div className="custom-heading">
          <h5>
            <GetModeleById />
          </h5>
        </div>
      )}

       {/* ✅ Gestion de l'affichage des Tiers */}
    {(() => {
      if (isCreateNewTiersOpen) {
        return <CreateNewTiers key={typeTiersToCreate} typeTiers={typeTiersToCreate} />;
      }


      if (tiersId && !["REQUÊTES", "PROJETS CLIENTS", "ALL PROJETS"].includes(selectedMenu)) {
        return <AfficheTiersByTiersId />;
      }

      const shouldShowTiersList = [
        "PARTENAIRES", "CLIENTS", "ALL CLIENTS", "ALL PARTENAIRES",
        "ADMIN PARTENAIRES", "ALL PRESTATAIRES", "ADMIN AGENCES", "ADMIN GÉNÉRAL"
      ].includes(selectedMenu);

      if (shouldShowTiersList) {
        return <AfficheTiersByTiersId />;
      }

      return null; // Ne rien afficher par défaut
    })()}


    </div>
  );
}
