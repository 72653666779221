import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useMemo,
  } from "react";
  import "bootstrap/dist/css/bootstrap.min.css";
  import "./../css/Global.css";
  import { OverlayTrigger, Tooltip } from "react-bootstrap";
  
  import { AuthContext } from "../../contexts/AuthProvider";
  import customAxios from "../Utilities/getAxiosToken";
  import logger from "../Utilities/Logger";
  
  export const PartageDestinataires = React.memo(() => {
    const [tiersByType, setTiersByType] = useState({});
    const [clientDetails, setClientDetails] = useState(null); // Stocke le client concerné
    const {
      userId,
      projetId,
      etapeId,
      selectedFilesIds,
      isDestinatairesOpen,
   
      isVueDocument,
    } = useContext(AuthContext);
    
    logger.log("PartageDestinataires isVueDocument", isVueDocument);
    logger.log("PartageDestinataires selectedFilesIds", selectedFilesIds);
  
    const sectionOrder = [
      "CLIENT",
      "AGENCE",
      "PARTENAIRE",
      "PRESTATAIRE",
      "FOURNISSEUR",
    ];
  
    const stableSectionOrder = useMemo(() => ["CLIENT", "AGENCE", "PARTENAIRE", "PRESTATAIRE", "FOURNISSEUR"], []);
  
    /**
     * Fetch data for tiers and group them by type.
     */
    const fetchAndUpdateTiersData = useCallback(async () => {
      if ( !userId || !projetId || (!(selectedFilesIds instanceof Set) || selectedFilesIds.size === 0)) return;
  
      try {
        const groupedTiers = {};
  
        // Récupération des tiers pour chaque type
        for (const type of sectionOrder) {
          const requestBodyTiers = { userId, typeTiers: type };
          logger.log("PartageDestinataires fetchAndUpdateTiersData requestBodyTiers", requestBodyTiers);
          const response = await customAxios.post(
            `/api/tiers/byUserIdAndTypeTiers`,
            requestBodyTiers
          );
          groupedTiers[type] = response.data.tiers || [];
        }
  
        logger.log("PartageDestinataires fetchAndUpdateTiersData groupedTiers", groupedTiers);

  
        setTiersByType(groupedTiers);
      } catch (error) {
        console.error("Erreur lors de la récupération des tiers :", error);
      }
    }, [ userId, projetId, selectedFilesIds]);
  
    useEffect(() => {
      if (stableSectionOrder && userId && projetId && selectedFilesIds) {
        fetchAndUpdateTiersData();
      }
    }, [stableSectionOrder, userId, projetId, selectedFilesIds, fetchAndUpdateTiersData]);
  
    /**
     * Handle checkbox change.
     */
    const handleCheckboxChange = async (tiersId, checked) => {
      if (checked) {
        await addDestinataire(tiersId);
      } else {
        await removeDestinataire(tiersId);
      }
    };
  
    const addDestinataire = async (tiersId) => {
      try {
        await customAxios.patch(`/api/projets/addDestinataire`, {
          projetId,
          etapeId,
          tiersId,
        });
        
      } catch (error) {
        console.error("Erreur lors de l'ajout du destinataire :", error);
      }
    };
  
    const removeDestinataire = async (tiersId) => {
      try {
        await customAxios.patch(`/api/projets/removeDestinataire`, {
          projetId,
          etapeId,
          tiersId,
        });
     
      } catch (error) {
        console.error("Erreur lors de la suppression du destinataire :", error);
      }
    };
  
    /**
     * Render the client section.
     */
    const renderClientSection = () => {
      if (!clientDetails) return null;
  
      return (
        <div className="mb-3">
          <h5 className="text-uppercase">CLIENT</h5>
          <ul className="liste-sans-puces">
            <li>
              <div className="form-check d-flex align-items-center">
                <input
                  className="checked-item ms-2 me-2"
                  type="checkbox"
                  id={`flexCheckDefault${clientDetails._id}`}
                  checked={clientDetails.checked ?? false}
                  onChange={(e) =>
                    handleCheckboxChange(clientDetails._id, e.target.checked)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor={`flexCheckDefault${clientDetails._id}`}
                >
                  {`${clientDetails.prenom || ""} ${clientDetails.nom}`}
                </label>
              </div>
            </li>
          </ul>
        </div>
      );
    };
  
    /**
     * Render a group of tiers with sorting.
     */
    const renderTiersGroup = (type, tiers) => {
      if (!tiers || tiers.length === 0) return null;
  
      const sortedTiers = [...tiers].sort((a, b) => {
        // Trier d'abord par case cochée (les cochés en premier)
        if (a.checked && !b.checked) return -1;
        if (!a.checked && b.checked) return 1;
  
        // Si les deux ont le même état pour `checked`, trier par nom
        const titreA = a.titre?.toLowerCase() || "";
        const titreB = b.titre?.toLowerCase() || "";
        if (titreA !== titreB) return titreA.localeCompare(titreB);
        return a.nom.localeCompare(b.nom);
      });
  
      return (
        <div key={type} className="ms-2 mb-3">
          <h6 className="text-uppercase" style={{ fontWeight: "400" }}>
            {type !== "AGENCE" ? `${type}s` : type}
          </h6>
          <ul className="liste-sans-puces ">
            {sortedTiers.map((tier) => (
              <li key={tier._id}
              className="ligne-des-destinataires">   
                  <div className="form-check d-flex align-items-center">
                    <input
                      className="checked-item ms-2 me-2"
                      type="checkbox"
                      id={`flexCheckDefault${tier._id}`}
                      checked={tier.checked ?? false}
                      onChange={(e) =>
                        handleCheckboxChange(tier._id, e.target.checked)
                      }
                    />
                  <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${tier._id}`}>
                          {"Cliquer pour sélectionner/désélectionner"}
                        </Tooltip>
                      }
                    >
                    <label
                      className="form-check-label souris-pointeur "
                      htmlFor={`flexCheckDefault${tier._id}`}
                    >
                      {tier.titre ? `${tier.titre} - ${tier.nom}` : tier.nom}
                    </label>
                    </OverlayTrigger>
                  </div>
             
              </li>
            ))}
          </ul>
        </div>
      );
    };
  
    return (
      // <div className="fenetre-liste-tiers">
      <div className="cadre-zone4">
        {isDestinatairesOpen && (
          <>
            {/* {sectionOrder.includes("CLIENT") && renderClientSection()}{" "} */}
            {/* Afficher la section CLIENT seulement si sectionOrder inclut CLIENT */}
            {Object.keys(tiersByType).length > 0 ? (
              sectionOrder
                // .filter((type) => type !== "CLIENT" && tiersByType[type])
                .map((type) => renderTiersGroup(type, tiersByType[type]))
            ) : (
              <p>Aucun destinataire trouvé.</p>
            )}
          </>
        )}
      </div>
    );
  });
  