import { useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";


import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";

import { useProjet } from "../../contexts/ProjetContext";

//
// Appelé depuis Zone 3
// Pour récupere toutes les information du projet et les mettre dans le contexte
//
export function ProjetByProjetId() {

  const {  handleLogout, projetId, handleTiersId, handleToggleDestinataires, handleprojetCible } =
    useContext(AuthContext);
  const { setProjetData } = useProjet();
 
  if (!projetId) {
    <div className="form-group cadre-zone3">
    <h5 className="titre-zone3" style={{ textAlign: "center" }}>
    Sélectionner un projet pour afficher les détails
    </h5>
  </div>
  }

  useEffect(() => {
    const fetchProjetNomByProjetId = async () => {
      try {
        if (projetId) {
          logger.log(
            "ProjetNomByProjetId: DANS useEffect - Type de projet:",
            "projetId:",
            projetId,
          );
          const response = await customAxios.get(
            `/api/projets/alone/${projetId}`
          );
          logger.log(
            "ProjetNomByProjetId: fetchProjetNomByProjetId response.data:",
            response.data
          );

          logger.log(
            `ProjetNomByProjetId: handleToggleDestinataires: false`
          );
          handleToggleDestinataires(false); // On affiche pas les Destinataires
          
          // On définit le tiersId de ce projet pour afficher le contact correspondant
          handleTiersId(response.data.tiers[0]);
          handleprojetCible(response.data.modeleCible);
          setProjetData(response.data); // On assigne les données du projet dans le contexte
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logger.log(
            `ProjetNomByProjetId: erreur 401 pas d'accès Token expiré`
          );
          // Déconnexion de l'utilisateur et redirection vers la page de login
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };
    fetchProjetNomByProjetId();
  }, [ projetId]);


}
