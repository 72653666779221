import React, { useState, useEffect, useContext } from "react";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import customAxios from "../Utilities/getAxiosToken";
import { AuthContext } from "../../contexts/AuthProvider";
import FilePreviewModal from "./FilePreviewModal";
import logger from "../Utilities/Logger";
import useHandleFileClick from "../Utilities/GetFiles";
import { useWebSocket } from "../../contexts/WebSocketProvider";


export const ListeFichiersClient = () => {
  const { userId, tiersId } = useContext(AuthContext); // Utilisateur actuel
  const [fichiers, setFichiers] = useState([]);
  const [activeRubriques, setActiveRubriques] = useState({});
  const [activePrefixes, setActivePrefixes] = useState({});
  const [filePreviewId, setFilePreviewId] = useState(null);
  const { downloadFile } = useHandleFileClick();

  // Ouvre la prévisualisation d'un fichier
  const handlePreviewFile = async (fileId) => {
    await markFileAsRead(fileId); // Marque comme lu
    setFilePreviewId(fileId); // Ouvre la modal
  };

  const closeModal = () => setFilePreviewId(null);

  // Marque le fichier comme lu par l'utilisateur actuel
  const markFileAsRead = async (fileId) => {
    try {
      await customAxios.patch(`/api/files/markAsRead/${fileId}`, {
        userId,
        readDate: new Date().toISOString(),
      });
      fetchFichiers(); // Rafraîchit la liste
    } catch (error) {
      console.error("Erreur lors de la mise à jour de lastReadDate :", error);
    }
  };

  // Récupère les fichiers pour un client spécifique
  const fetchFichiers = async () => {
    try {
      const response = await customAxios.get(`/api/files/client/${tiersId}`);

      logger.log("ListeFichiersClient Fichiers du client", response.data);

      // Si accesFileClient est true, on affiche le fichier
      const filteredFiles = response.data.filter(
        (file) => file.metadata.accesFileClient
      );

      logger.log("ListeFichiersClient Fichiers filtrés", filteredFiles);

      setFichiers(groupByRubriqueAndPrefixe(filteredFiles));
    } catch (error) {
      console.error("Erreur lors de la récupération des fichiers :", error);
    }
  };

  const groupByRubriqueAndPrefixe = (data) => {
    const grouped = data.reduce((acc, fichier) => {
      const rubrique = fichier.metadata.rubriqueEtape || "Sans rubrique";
      const prefixe = fichier.metadata.prefixeEtape || "Sans préfixe";

      acc[rubrique] = acc[rubrique] || {};
      acc[rubrique][prefixe] = acc[rubrique][prefixe] || [];
      acc[rubrique][prefixe].push(fichier);
      return acc;
    }, {});

    return grouped;
  };

  const toggleRubrique = (rubrique) => {
    setActiveRubriques((prev) => ({ ...prev, [rubrique]: !prev[rubrique] }));
  };

  const togglePrefixe = (rubrique, prefixe) => {
    setActivePrefixes((prev) => ({
      ...prev,
      [rubrique]: { ...prev[rubrique], [prefixe]: !prev[rubrique]?.[prefixe] },
    }));
  };

  const countUnreadFiles = (files) => {
    return files.filter(
      (file) =>
        !file.metadata.lastReadDate ||
        !file.metadata.lastReadDate.some((entry) => entry.utilisateur === userId)
    ).length;
  };

    const {socket} = useWebSocket();
   useEffect(() => {
    if (!tiersId) {
      logger.warn("ListeFichiers: tiersId non défini, sortie anticipée.");
      return;
    }
  
    const initialize = async () => {
      try {
        logger.log("ListeFichiers: Initialisation - Chargement des fichiers.");
        await fetchFichiers();
      } catch (error) {
        console.error("ListeFichiers: Erreur lors du chargement des fichiers :", error);
      }
    };
  
    initialize();
  
    if (socket) {
      const handleUpdateFile = () => {
        logger.log("ListeFichiers: Événement WebSocket reçu, mise à jour des fichiers.");
        fetchFichiers();
      };
  
      socket.on("updateFile", handleUpdateFile);
      logger.log("ListeFichiers: Abonnement WebSocket pour 'updateFile' configuré.");
    } else {
      logger.warn("ListeFichiers: Socket non initialisé.");
    }
  
    return () => {
      logger.log("ListeFichiers: Nettoyage des abonnements WebSocket.");
      if (socket) {
        socket.off("updateFile");
      }
    };
  }, [tiersId, fetchFichiers]);

  if (!tiersId) {
    return <h5 className="titre-zone3">Aucun client sélectionné</h5>;
  }

  return (
    <div className="cadre-zone3-note" style={{ marginBottom: "40px" }}>
      <h6 style={{ textAlign: "center", color: "var(--texte-zone)" }}>
        DOCUMENTS DU CLIENT
      </h6>
      {Object.keys(fichiers).length > 0 ? (
        Object.entries(fichiers).map(([rubrique, prefixeMap]) => {
          // Compte des fichiers non lus pour cette rubrique
          const totalUnreadRubrique = Object.values(prefixeMap)
            .flat()
            .filter(
              (file) =>
                !file.metadata.lastReadDate ||
                !file.metadata.lastReadDate.some(
                  (entry) => entry.utilisateur === userId
                )
            ).length;

          return (
            <div key={rubrique}>
              <div
                onClick={() => toggleRubrique(rubrique)}
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {activeRubriques[rubrique] ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronRight />
                )}
                <span style={{ marginLeft: "5px" }}>
                  {rubrique}
                  {totalUnreadRubrique > 0 && ` (${totalUnreadRubrique} non lus)`}
                </span>
              </div>
                    {/* <div
                        onClick={() => toggleRubrique(rubrique)}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {activeRubriques[rubrique] ? (
                          <FiChevronDown />
                        ) : (
                          <FiChevronRight />
                        )}
                        <span style={{ marginLeft: "5px", fontWeight: "bold" }}>{rubrique}</span>
                        {totalUnreadRubrique > 0 && (
                          <span className="badge badge-info compteur">{totalUnreadRubrique} </span> 
                        )}
                      </div> */}
              
              {activeRubriques[rubrique] &&
                Object.entries(prefixeMap).map(([prefixe, files]) => {
                  const unreadFilesInPrefixe = countUnreadFiles(files);

                  return (
                    <div key={prefixe} style={{ paddingLeft: "20px" }}>
                      <div
                        onClick={() => togglePrefixe(rubrique, prefixe)}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {activePrefixes[rubrique]?.[prefixe] ? (
                          <FiChevronDown />
                        ) : (
                          <FiChevronRight />
                        )}
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: unreadFilesInPrefixe > 0 ? "bold" : "normal",
                          }}
                        >
                          {prefixe}
                          {unreadFilesInPrefixe > 0 &&
                            ` (${unreadFilesInPrefixe} non lus)`}
                        </span>
                      </div>
                      {activePrefixes[rubrique]?.[prefixe] &&
                        files.map((file) => {
                          const isUnread =
                            !file.metadata.lastReadDate ||
                            !file.metadata.lastReadDate.some(
                              (entry) => entry.utilisateur === userId
                            );

                          return (
                            <div
                              key={file._id}
                              style={{
                                paddingLeft: "40px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                onClick={() => handlePreviewFile(file._id)}
                                style={{
                                  cursor: "pointer",
                                  fontWeight: isUnread ? "bold" : "normal",
                                }}
                              >
                                {file.nomOwnerTiers}{" - "}
                                {new Date(
                                  file.metadata.uploadDate
                                ).toLocaleString()}
                              </span>
                              <BsDownload
                                size="1.2em"
                                onClick={() => {
                                  downloadFile(file._id);
                                  markFileAsRead(file._id); // Marque comme lu après téléchargement
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          );
        })
      ) : (
        <p style={{ textAlign: "center" }}>Aucun document trouvé.</p>
      )}
      {filePreviewId && (
        <FilePreviewModal fileId={filePreviewId} closeModal={closeModal} />
      )}
    </div>
  );
};