import React, { useEffect, useState, useContext, useCallback } from "react";
import { BsCalendar2Date } from "react-icons/bs";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import { AuthContext } from "../../contexts/AuthProvider";

import customAxios from "../Utilities/getAxiosToken";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useWebSocket } from "../../contexts/WebSocketProvider";
import { iconRendering } from "../Utilities/iconRendering"; // Ajustez le chemin d'accès selon votre structure de dossiers
import {
  getFontColor,
  getBackgroundClass,
  formatDate,
  calculerDifferenceJours,
  getTooltipTypeActionContent,
  cleanString,
} from "../Utilities/attentesEtapes";
import { useProjet } from "../../contexts/ProjetContext";
import { ModalCheckProjet } from "../Modal/ModalCheckProjet"; // Importez le composant modal
import {
  activateProject,
  deactivateProject,
} from "../Utilities/ActivateProjet";
import { checkProjetAcces } from "../Utilities/CheckProjetAcces"; // Importez la fonction de vérification de l'accès au projet

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



export function ListeEtapes() {


  const [nbEtapes, setNbEtapes] = useState(0);

  const {
    isManager,
    isVueManager,
    userId,
    agenceId,
    projetId,
    handleEtapeId,
    handleToggleDestinataires,
    handleProjetId,
  } = useContext(AuthContext);

  const { refreshProjects } = useProjet();
  const [showArchived, setShowArchived] = useState(false); // Ajouté pour contrôler l'affichage des étapes enArchives
  const [editingEcheance, setEditingEcheance] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [allEtapes, setAllEtapes] = useState([]);
  const [selectedEtapeId, setSelectedEtapeId] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    body: "",
  });
  const [previousProjetId, setPreviousProjetId] = useState(projetId);
  const [projetAcces, setProjetAcces] = useState(null);

  // Fonction pour montrer le DatePicker
  const showDatePicker = (etapeId, currentEcheance) => {
    setEditingEcheance(etapeId); // Définir l'édition pour l'étape actuelle dans les deux cas
    refreshProjects();
    if (currentEcheance) {
      setSelectedDate(new Date(currentEcheance)); // Utiliser l'échéance actuelle si elle est définie
    } else {
      setSelectedDate(new Date()); // Utiliser la date actuelle si aucune échéance n'est définie
    }
  };

  // Fonction pour réinitialiser les sélections
  const resetSelections = () => {
    setSelectedEtapeId(null);
    handleEtapeId(null);
    handleToggleDestinataires(false);
  };

  const fetchEtapes = useCallback(
    async (validProjetId) => {
      if (!validProjetId) return;
      try {
        const requestBody = {
          projetId: validProjetId,
        };
        const response = await customAxios.post(
          `/api/projets/etapesByprojetId`,
          requestBody
        );

        logger.log("ListeEtapes fetchEtapes ", response.data);
        const etapesData = response.data || [];
        setAllEtapes(etapesData);
        setNbEtapes(etapesData.length);
      } catch (error) {
        console.error("Erreur lors du chargement des étapes:", error);
      }
    },
    [editingEcheance]
  );

  const validateProject = useCallback(async (currentProjetId) => {
    if (!currentProjetId) return;
    try {
      const response = await customAxios.get(
        `/api/projets/isValid/${currentProjetId}`
      );
      const { isValid, nomProjet, invalidSteps } = response.data;

      logger.log(
        "ListeEtapes validateProject ",
        nomProjet,
        isValid,
        invalidSteps
      );
      if (!isValid) {
        const formattedSteps = invalidSteps
          .map((step) => `<li>${step}</li>`)
          .join("");
        setModalInfo({
          show: true,
          title: `Projet ${nomProjet} Non Valide`,
          body: `Les étapes suivantes posent problème:<ul>${formattedSteps}</ul>`,
        });
        deactivateProject(currentProjetId); // Désactiver le projet si non valide
        return false;
      } else {
        setModalInfo({ show: false, title: "", body: "" });
        activateProject(currentProjetId); // Activer le projet si valide
        return true;
      }
    } catch (error) {
      console.error("Erreur lors de la validation du projet:", error);
      return false;
    }
  }, []);

  // On vérifie si le projet est valide et on le met à jour dans la base si nécessaire isValide
  const upDateIsValidProject = useCallback(async (currentProjetId) => {
    if (!currentProjetId) return;
    try {
      const response = await customAxios.get(
        `/api/projets/isValid/${currentProjetId}`
      );
      const { isValid, nomProjet, invalidSteps } = response.data;

      logger.log(
        "ListeEtapes upDateIsValidProject ",
        nomProjet,
        isValid,
        invalidSteps
      );
      if (!isValid) {
        logger.log("ListeEtapes upDateIsValidProject deactivateProject");
        deactivateProject(currentProjetId); // Désactiver le projet si non valide
        return false;
      } else {
        activateProject(currentProjetId); // Activer le projet si valide
        return true;
      }
    } catch (error) {
      console.error("Erreur lors de la validation du projet:", error);
      return false;
    }
  }, []);

  // Vérifie et valide le projet uniquement si on sélectionne un nouveau projetId
  useEffect(() => {
    const checkAndValidateProject = async () => {
      logger.log(
        "ListeEtapes checkAndValidateProject de previousProjetId",
        previousProjetId
      );
      const isValid = await validateProject(previousProjetId);
      logger.log(
        "ListeEtapes checkAndValidateProject de previousProjetId",
        isValid
      );
      if (!isValid) {
        // Réinitialiser le projetId à l'ancien si l'ancien projet est invalide
        handleProjetId(previousProjetId);
      } else {
        setPreviousProjetId(projetId);
      }
    };

    // Si on clique sur un autre projet, on vérifie et on valide le projet précédent
    // On s'assure que le projet doit être ouvert pour être véerifié et validé
    // S'il s'agit d'un projet archivé on ne pas de test

    // IMPERATIF DE GARDER !isVueManager c'est pour le cas où on est pas manager 
    // et pour lister tous les projets ouverts
    // isVueManager c'est pour le cas où on est manager et on veut lister les projets ouverts
    if (!isVueManager && projetId !== previousProjetId && projetAcces.acces === "ouvert") {
      logger.log(
        "ListeEtapes checkAndValidateProject projetId !== previousProjetId"
      );
      checkAndValidateProject();
    } else {
      logger.log(
        "ListeEtapes PAS DE checkAndValidateProject projetId !== previousProjetId"
      );
      setPreviousProjetId(projetId);
    }
  }, [projetId, validateProject, previousProjetId, handleProjetId]);

  // Vérifier l'accès au projet
  const checkAccess = async (projetParamId) => {
    try {
      const accessProjet = await checkProjetAcces(userId, projetParamId); // Ajoutez `userId` si nécessaire
      setProjetAcces(accessProjet);
      logger.log(
        "ListeEtapes checkProjetAcces accessProjet",
        projetParamId,
        accessProjet
      );
    } catch (error) {
      logger.error(
        "Erreur lors de la vérification de l'accès au projet:",
        error
      );
    }
  };

  // Pour afficher des messages en fonction de l'accès au projet
  useEffect(() => {
    if (!projetAcces) return;

    if (projetAcces.acces === "ouvert") {
      logger.log(
        "ListeEtapes Le projet est ouvert.",
        projetId,
        previousProjetId
      );
      // Ajoutez ici votre logique pour un projet "ouvert"
    } else if (projetAcces.acces === "archivé") {
      logger.log(
        "ListeEtapes Le projet est archivé.",
        projetId,
        previousProjetId
      );
      // Ajoutez ici votre logique pour un projet "archivé"
    }
  }, [projetAcces]);

   const {socket} = useWebSocket();

   const handleUpdateEtape = useCallback(
    (data) => {
      console.log("ListeEtapes: Événement WebSocket: updateEtape reçu", data);
      fetchEtapes(previousProjetId);
    },
    [fetchEtapes, previousProjetId]
  );
  
  const handleUpdateProjet = useCallback(
    (data) => {
      console.log("ListeEtapes: Événement WebSocket: updateProjet reçu", data);
      fetchEtapes(previousProjetId);
    },
    [fetchEtapes, previousProjetId]
  );

  useEffect(() => {
    if (!previousProjetId) {
      logger.warn(
        "ListeEtapes: previousProjetId non défini, sortie anticipée."
      );
      return;
    }

    const initialize = async () => {
      try {
        logger.log(
          "ListeEtapes: Initialisation - Chargement des étapes et vérification d'accès."
        );
        await fetchEtapes(previousProjetId); // Charger les étapes du projet
        resetSelections(); // Réinitialiser les sélections
        await checkAccess(previousProjetId); // Vérifier l'accès au projet
      } catch (error) {
        console.error("ListeEtapes: Erreur lors de l'initialisation :", error);
      }
    };

    initialize();

    if (socket) {
      socket.on("updateEtape", handleUpdateEtape);
      socket.on("updateProjet", handleUpdateProjet);

      logger.log("ListeEtapes: Abonnements WebSocket configurés.");
    } else {
      logger.warn("ListeEtapes: Socket non initialisé.");
    }

    return () => {
      logger.log("ListeEtapes: Nettoyage des abonnements WebSocket.");
      if (socket) {
        socket.off("updateEtape", handleUpdateEtape);
        socket.off("updateProjet", handleUpdateProjet);
      }
    };
  }, [fetchEtapes, previousProjetId, socket]); // Ajout de `socket` dans les dépendances

  const toggleArchived = () => setShowArchived((prev) => !prev);

  const handleEtapeSelection = (event, etapeId) => {
    event.preventDefault();
    logger.log("ListeEtapes handleEtapeSelection", etapeId);
    const isActive = selectedEtapeId === etapeId;
    setSelectedEtapeId(isActive ? null : etapeId);
    handleEtapeId(isActive ? null : etapeId);
    handleToggleDestinataires(!isActive);
  };

  const onDateChange = (nouvelleEcheance, etapeId) => {
    const updateEcheance = async () => {
      try {
        // Vérifier si une échéance existait déjà pour cette étape
        const etape = allEtapes.find((etape) => etape.etapeId === etapeId);
        const etapeAvaitEcheance = !!etape.echeanceEtape;
  
        const nouvelEtat = nouvelleEcheance ? "enAttente" : "enCours";
  
        const updatedDestinataires =
          nouvelleEcheance && !etapeAvaitEcheance
            ? etape.destinatairesEtape.map((destinataire) => ({
                ...destinataire,
                etat: "enAttente", // Passer tous les destinataires à "enAttente"
              }))
            : etape.destinatairesEtape;
  
        // Ajout du log pour indiquer si les destinataires sont mis à jour
        if (!etapeAvaitEcheance && nouvelleEcheance) {
          toast.info("Une nouvelle échéance pour cette étape.");
          console.log("Une nouvelle échéance mis à jour.");
        }

        // On vérifie si on peut changer l'echeance de cette étape
        // Si cette étape est bien modifiée par l'agence qui a modifié l'échéance
        if (etapeAvaitEcheance && etape.agenceId === agenceId) {
          toast.info("L'échéance de cette étape a été mise à jour.");
        }

        const response = await customAxios.patch(
          `/api/projets/projet/${projetId}/etapeId/${etapeId}`,
          {
            etat: nouvelEtat,
            echeanceEtape: nouvelleEcheance,
            destinatairesEtape: updatedDestinataires,
          }
        );
  
        if (response.status === 200) {
          setSelectedDate(nouvelleEcheance ? new Date(nouvelleEcheance) : null);
          setEditingEcheance(null);
          refreshProjects();
          await upDateIsValidProject(projetId); // Valider le projet après la mise à jour de la date
          fetchEtapes(previousProjetId); // Mettre à jour les étapes localement
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'échéance", error);
      }
    };
  
    updateEcheance();
  };
  // const onDateChange = (nouvelleEcheance, etapeId) => {
  //   let nouvelEtat;
  //   const updateEcheance = async () => {
  //     try {
  //       if (nouvelleEcheance === null) {
  //         nouvelEtat = "enCours";
  //       } else {
  //         nouvelEtat = "enAttente";
  //       }

  //       const response = await customAxios.patch(
  //         `/api/projets/projet/${projetId}/etapeId/${etapeId}`,
  //         {
  //           etat: nouvelEtat,
  //           echeanceEtape: nouvelleEcheance,
  //         }
  //       );

  //       if (response.status === 200) {
  //         setSelectedDate(nouvelleEcheance ? new Date(nouvelleEcheance) : null);
  //         setEditingEcheance(null);
  //         refreshProjects();
  //         await upDateIsValidProject(projetId); // Valider le projet après la mise à jour de la date
  //       }
  //     } catch (error) {
  //       console.error("Erreur lors de la mise à jour de l'échéance", error);
  //     }
  //   };

  //   updateEcheance();
  //   refreshProjects();
  // };

  const handleCheckboxChange = (etape, index) => {
    toggleEtapeEtat(etape);
  };

  const toggleEtapeEtat = async (etapeCliquee) => {
    try {
      let etapesAMettreAJour = [];

      if (etapeCliquee.etapeEtat === "enArchive") {
        const nouvelEtat = "enCours";
        const indexClique = allEtapes.findIndex(
          (etape) => etape.etapeId === etapeCliquee.etapeId
        );
        if (indexClique === -1) return;

        etapesAMettreAJour = allEtapes
          .slice(indexClique)
          .filter((etape) => etape.etapeEtat === "enArchive")
          .map((etape) => ({
            etapeId: etape.etapeId,
            nouvelEtat: nouvelEtat,
            echeanceEtape: null,
          }));
      } else {
        etapesAMettreAJour.push({
          etapeId: etapeCliquee.etapeId,
          nouvelEtat: "enArchive",
          echeanceEtape: null,
        });
      }

      const updatedEtapes = allEtapes.map((etape) => {
        const estAMettreAJour = etapesAMettreAJour.find(
          (e) => e.etapeId === etape.etapeId
        );
        return estAMettreAJour
          ? {
              ...etape,
              etapeEtat: estAMettreAJour.nouvelEtat,
              echeanceEtape: estAMettreAJour.echeanceEtape,
            }
          : etape;
      });

      setAllEtapes(updatedEtapes);

      await customAxios.patch(`/api/projets/updateEtapesBatch`, {
        etapesUpdates: etapesAMettreAJour,
      });
      logger.log("ListeEtapes toggleEtapeEtat", etapesAMettreAJour);
      logger.log("ListeEtapes toggleEtapeEtat upDateIsValidProject", projetId);
      await upDateIsValidProject(projetId); // Valider le projet après la mise à jour de l'étape
      refreshProjects();
    } catch (error) {
      console.error("Erreur lors de la mise à jour des étapes:", error);
    }
  };

  const handleDestinataireClick = (destinataire, etape) => {
    const nouvelEtat =
      destinataire.etat === "aRépondu" ? "enAttente" : "aRépondu";

    const updatedDestinataires = etape.destinatairesEtape.map((d) =>
      d.nom === destinataire.nom ? { ...d, etat: nouvelEtat } : d
    );

    updateDestinataireEtape(
      etape.projetId,
      etape.etapeId,
      updatedDestinataires
    );
  };

  const updateDestinataireEtape = async (
    projetId,
    etapeId,
    updatedDestinataires
  ) => {
    try {
      logger.log(
        "ListeEtapes updateDestinataireEtape",
        projetId,
        etapeId,
        updatedDestinataires
      );
      const response = await customAxios.patch(
        `/api/projets/projet/${projetId}/etapeId/${etapeId}`,
        {
          destinatairesEtape: updatedDestinataires,
        }
      );
      if (response.status === 200) {
        fetchEtapes(previousProjetId);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des destinataires", error);
    }
  };

  const renderDestinataires = (destinataires, etape) => {
    const sortedDestinataires = (destinataires || [])
      .filter((destinataire) => destinataire && destinataire.nom)
      .sort((a, b) => {
        // Priorité à "enAttente" et tri alphabétique ensuite
        if (a.etat === "aRépondu" && b.etat !== "aRépondu") return -1;
        if (a.etat !== "aRépondu" && b.etat === "aRépondu") return 1;
        return (a.nom || "").localeCompare(b.nom || "");
      });

    return sortedDestinataires.map((destinataire, destinataireIndex) => {
      const key = `${destinataire.nom || ""}-${destinataireIndex}-`;

      return (
        <React.Fragment key={key}>
          <OverlayTrigger
            placement="bottom" // Position du tooltip
            overlay={
              <Tooltip id={`infoDestinataire-${cleanString(destinataire.nom)}`}>
                {destinataire.etat === "enAttente"
                  ? "Document en Attente"
                  : destinataire.etat === "aRépondu"
                  ? "Document Reçu"
                  : ""}
              </Tooltip>
            }
          >
            <span
              style={{
                cursor:
                  destinataire.etat === "aRépondu" ? "pointer" : "default", // Curseur actif uniquement si état "aRépondu"
                borderRadius: "var(--border-radius-destinataires)",
                padding: "1px 10px",
              }}
              className={
                destinataire.etat === "enAttente"
                  ? "destinataire-enAttente"
                  : destinataire.etat === "aRépondu"
                  ? "destinataire-aRépondu"
                  : ""
              }
              onClick={() => {
                if (destinataire.etat === "aRépondu") {
                  handleDestinataireClick(destinataire, etape); // Exécuter l'action seulement si état "aRépondu"
                }
              }}
            >
              {`${destinataire.nom || ""}`}
            </span>
          </OverlayTrigger>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {projetId ? (
        <>
          {nbEtapes > 0 ? (
            <div className="cadre-zone3">
              <table>
                <tbody>
                  {allEtapes
                    .filter(
                      (etape) => showArchived || etape.etapeEtat !== "enArchive"
                    )
                    .map((etape, index) => {
                      const joursRestants = etape.echeanceEtape
                        ? calculerDifferenceJours(etape.echeanceEtape)
                        : "N/A";
                      const classeBackground =
                        getBackgroundClass(joursRestants);
                      const fontColor = getFontColor(classeBackground);
                      const key = etape.etapeId;
                      const rowClass =
                        index % 2 === 0 ? "bg-custom-requetes" : "";

                      return (
                        <tr
                          key={`${key}-${index}`}
                          className={`${rowClass} ${
                            selectedEtapeId === key
                              ? "selected-row"
                              : "annuler-selected-row"
                          }`}
                        >
                          <td
                            style={{
                              width: "2%",
                              borderRadius: "6px 0 0 6px",
                              paddingLeft: "8px",
                              cursor: "pointer",
                            }}
                          >
                            <OverlayTrigger
                              placement="bottom" // Position du tooltip
                              overlay={
                                <Tooltip
                                  id={`infoArchive-${etape.etapeId}-${index}`}
                                >
                                  {etape.etapeEtat === "enArchive"
                                    ? "Réactiver la tâche"
                                    : "Archiver la tâche"}
                                </Tooltip>
                              }
                            >
                              <input
                                className="checked-item me-1"
                                type="checkbox"
                                checked={etape.etapeEtat === "enArchive"}
                                onChange={() =>
                                  handleCheckboxChange(etape, index)
                                }
                              />
                            </OverlayTrigger>
                          </td>

                          <td
                            className="selection-description-col"
                            style={{
                              cursor: "pointer",
                              width: "40%",
                            }}
                            onClick={(event) =>
                              etape.destinatairesModifiablesEtape
                                ? handleEtapeSelection(event, etape.etapeId)
                                : undefined
                            }
                          >
                            <OverlayTrigger
                              placement="bottom" // Position du tooltip
                              overlay={
                                <Tooltip
                                  id={`infoDescription-${etape.etapeId}-${index}`}
                                >
                                  {etape.destinatairesModifiablesEtape
                                    ? "Cliquer pour modifier les destinataires >>>>"
                                    : "Sélection des destinataires impossible"}
                                </Tooltip>
                              }
                            >
                              <span>
                                {etape.ordreEtape} - {etape.descriptionEtape}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td
                            style={{
                              width: "44%",
                              paddingLeft: "5px",
                            }}
                          >
                            <span className="destination-col">
                              {renderDestinataires(
                                etape.destinatairesEtape,
                                etape
                              )}
                            </span>
                          </td>

                          <td
                            style={{
                              width: "6%",
                              textAlign: "center",
                            }}
                          >
                            <OverlayTrigger
                              placement="bottom" // Position du tooltip
                              overlay={
                                etape.echeanceEtape ? (
                                  <Tooltip
                                    id={`infoAction-${etape.etapeId}-${index}`}
                                  >
                                    {getTooltipTypeActionContent(
                                      etape.typeEtape
                                    )}
                                  </Tooltip>
                                ) : (
                                  <></> // Pas de tooltip si `echeanceEtape` est null ou false
                                )
                              }
                            >
                              <span className="action-col">
                                {etape.echeanceEtape && iconRendering(etape)}
                              </span>
                            </OverlayTrigger>
                          </td>

                          <td
                            className={`echeance-col ${classeBackground}`}
                            style={{
                              cursor: "pointer",
                              width: "8%",
                              fontSize: "var(--fs-beaucoup-plus-petite)",
                              color: fontColor,
                              textAlign: "center",
                            }}
                            onClick={() => {
                              if (etape.etapeEtat !== "enArchive") {
                                showDatePicker(
                                  etape.etapeId,
                                  etape.echeanceEtape
                                );
                              }
                            }}
                          >
                            <OverlayTrigger
                              placement="bottom" // Position du tooltip
                              overlay={
                                <Tooltip
                                  id={`infoEcheance-${etape.etapeId}-${index}`}
                                >
                                  {etape.etapeEtat !== "enArchive"
                                    ? "Modifier l'échéance"
                                    : "Échéance non modifiable"}
                                </Tooltip>
                              }
                            >
                              <span>
                                {editingEcheance === etape.etapeId ? (
                                  <>
                                    <ReactDatePicker
                                      key={etape.etapeId}
                                      selected={selectedDate}
                                      onChange={(date) =>
                                        onDateChange(
                                          date,
                                          etape.etapeId,
                                          etape.projetId
                                        )
                                      }
                                      inline
                                    />
                                    <button
                                      onClick={() =>
                                        onDateChange(
                                          null,
                                          etape.etapeId,
                                          etape.projetId
                                        )
                                      }
                                    >
                                      Réinitialiser la date
                                    </button>
                                  </>
                                ) : etape.echeanceEtape ? (
                                  formatDate(etape.echeanceEtape)
                                ) : (
                                  <BsCalendar2Date
                                    color="var(--texte-zone)"
                                    style={{ fontSize: "1.3em" }}
                                  />
                                )}
                              </span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <>Aucune étape à afficher.</>
          )}
          <button
            className="bouton-commun bouton-archived"
            onClick={toggleArchived}
            style={{
              fontSize: "var(--fs-plus-petite)",
              marginTop: "10px",
              marginLeft: "20px",
            }}
          >
            {showArchived ? "Masquer les archives" : "Afficher les archives"}
          </button>
        </>
      ) : (
        <p>Projet ID ou type de requête non défini.</p>
      )}

      <ModalCheckProjet
        show={modalInfo.show}
        handleClose={() => setModalInfo({ show: false, title: "", body: "" })}
        title={modalInfo.title}
        body={<div dangerouslySetInnerHTML={{ __html: modalInfo.body }} />}
      />
    </>
  );
}
