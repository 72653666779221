import axios from "axios";

const axiosInstance = axios.create({
  timeout: 120000, // Timeout en millisecondes (120 secondes)
});

/**
 * Délai entre les appels pour respecter les limites d'API.
 * @param {number} ms - Durée en millisecondes.
 */
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Fonction avec retry pour gérer les erreurs 429 et autres erreurs temporaires.
 * @param {Function} fn - Fonction à exécuter avec retry.
 * @param {number} retries - Nombre maximum de tentatives.
 * @param {number} delayMs - Délai initial entre les tentatives.
 */
const withRetry = async (fn, retries = 3, delayMs = 10000) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (error.response?.status === 429 && i < retries - 1) {
        console.warn(`⏳ Trop de requêtes. Attente de ${delayMs}ms avant une nouvelle tentative...`);
        await delay(delayMs);
        delayMs *= 2; // Augmente le délai de façon exponentielle
      } else {
        console.error("❌ Erreur dans la requête :", error);
        throw error; // Rejette l'erreur si autre ou si le nombre de tentatives est dépassé
      }
    }
  }
};

const fetchCompanyInfoBySiret = async (siret, setSirenValid, setError, setCompanyInfo, setValue) => {
  setError(null);
  setSirenValid(null);

  try {
    // ✅ Utilisation de `withRetry` pour gérer les erreurs 429
    const responseSiret = await withRetry(() => axiosInstance.get(`/proxy/siret/${siret}`));

    console.log("✅ fetchCompanyInfoBySiret :", responseSiret);
    setCompanyInfo(responseSiret.data);
    const etablissement = responseSiret.data.etablissement;

    if (etablissement) {
      const uniteLegale = etablissement.uniteLegale;
      const denomination =
        uniteLegale.denominationUniteLegale ||
        etablissement.periodesEtablissement[0]?.denominationUsuelleEtablissement ||
        uniteLegale.nomUniteLegale ||
        "N/A";

      // Mise à jour des champs avec les valeurs récupérées
      setValue("raisonSociale", denomination);

      setValue(
        "adresse",
        [
          etablissement.adresseEtablissement.numeroVoieEtablissement,
          etablissement.adresseEtablissement.typeVoieEtablissement,
          etablissement.adresseEtablissement.libelleVoieEtablissement,
        ]
          .filter(Boolean) // Filtrer les champs non définis ou null
          .join(" ") // Joindre les champs avec un espace
      );
      setValue("cp", etablissement.adresseEtablissement.codePostalEtablissement);
      setValue("ville", etablissement.adresseEtablissement.libelleCommuneEtablissement);

      // 🔹 Ajout des nouvelles informations
      const capitalSocial = uniteLegale.capitalSocialUniteLegale || "N/A";
      const codeNaf = uniteLegale.activitePrincipaleUniteLegale || "N/A";
      const trancheEffectifs = uniteLegale.trancheEffectifsUniteLegale || "N/A";
      const categorieJuridique = uniteLegale.categorieJuridiqueUniteLegale || "N/A";
      // const nomContact = uniteLegale?.nomUniteLegale || "";
      // const prenomContact = uniteLegale?.prenomUniteLegale || "";
      // const emailContact = etablissement?.contact?.email || "";

      // setValue("nomContact", nomContact);
      // setValue("prenomContact", prenomContact);
      // setValue("emailContact", emailContact);
      setValue("capitalSocial", capitalSocial);
      setValue("codeNaf", codeNaf !== "N/A" ? codeNaf : "Non disponible");
      setValue("trancheEffectifs", trancheEffectifs !== "N/A" ? trancheEffectifs : "Non disponible");
      setValue("categorieJuridique", categorieJuridique !== "N/A" ? categorieJuridique : "Non disponible");

      console.log("🔍 fetchCompanyInfoBySiret Capital social récupéré :", capitalSocial);
      console.log("🔍 fetchCompanyInfoBySiret Code NAF récupéré :", codeNaf);
      console.log("🔍 fetchCompanyInfoBySiret Tranche d'effectifs récupérée :", trancheEffectifs);
      console.log("🔍 fetchCompanyInfoBySiret Catégorie juridique récupérée :", categorieJuridique);
      console.log("🔍 fetchCompanyInfoBySiret Appel à fetchNafLibelle avec code NAF :", codeNaf);
      console.log("🔍 fetchCompanyInfoBySiret Appel à fetchLegalForm avec catégorie juridique :", categorieJuridique);

      // Récupérer le libellé du code NAF et la forme juridique
      if (codeNaf !== "N/A") await fetchNafLibelle(codeNaf, setValue, setError);
      if (categorieJuridique !== "N/A") await fetchLegalForm(categorieJuridique, setValue);

      setSirenValid(true);
    }
  } catch (err) {
    console.error("❌ Erreur fetchCompanyInfoBySiret:", err);
    setError("Erreur lors de la récupération des informations de l'entreprise. Vérifiez le numéro SIRET.");
    setSirenValid(false);
  }
};


const fetchCompanyInfoBySiren = async (siren, setSirenValid, setError, setCompanyInfo, setValue) => {
  setError(null);
  setSirenValid(null);

  try {
    const responseSiren = await withRetry(() => axiosInstance.get(`/proxy/siren/${siren}`));

    console.log("✅ fetchCompanyInfoBySiren :", responseSiren);
    const uniteLegale = responseSiren.data.uniteLegale;

    if (uniteLegale && uniteLegale.periodesUniteLegale.length > 0) {
      const periode = uniteLegale.periodesUniteLegale[0];
      const nic = periode.nicSiegeUniteLegale;
      const siret = siren + nic;

      await fetchCompanyInfoBySiret(siret, setSirenValid, setError, setCompanyInfo, setValue);
    } else {
      setError("Aucune information trouvée pour ce numéro SIREN.");
      setSirenValid(false);
    }
  } catch (err) {
    console.error("❌ Erreur fetchCompanyInfoBySiren:", err);
    setError("Erreur lors de la récupération des informations de l'entreprise. Vérifiez le numéro SIREN.");
    setSirenValid(false);
  }
};

// Fonction pour récupérer la forme juridique
const fetchLegalForm = async (categoryCode, setValue) => {
  try {
    const responseLegalForm = await withRetry(() => axiosInstance.get(`/proxy/legalForm/${categoryCode}`));

    console.log("✅ fetchLegalForm :", responseLegalForm);
    setValue("formeJuridique", responseLegalForm.data.intitule || "N/A");
  } catch (err) {
    console.error("❌ Erreur lors de la récupération de la forme juridique:", err);
    setValue("formeJuridique", "Non disponible");
  }
};

// Fonction pour récupérer le libellé NAF
const fetchNafLibelle = async (codeNaf, setValue, setError) => {
  try {

    console.log("✅ fetchNafLibelle codeNaf:", codeNaf);
    // const responseNaf = await withRetry(() => axiosInstance.get(`/proxy/nafr2/${codeNaf}`));
    const responseNaf = await axios.get(`/proxy/nafr2/${codeNaf}`);
   
    console.log("✅ fetchNafLibelle :", responseNaf.data.intitule );
    setValue("libelleNaf", responseNaf.data.intitule || "N/A");
  } catch (err) {
    console.error("❌ Erreur lors de la récupération du libellé NAF:", err);
    setError("Impossible de récupérer le libellé NAF.");
    setValue("libelleNaf", "Non disponible");
  }
};

export { fetchCompanyInfoBySiret, fetchCompanyInfoBySiren };