

import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import customAxios from "../Utilities/getAxiosToken";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import { useWebSocket } from "../../contexts/WebSocketProvider";

export function CountCatégories() {
 
  const { userId } = useContext(AuthContext);
  const [nbCategories, setNbCategories] = useState(0);

  const chargerCategories = useCallback(async () => {
    if (userId ) {
      try {
        const response = await customAxios.get(`/api/categories/nbcategories`);
        const { count } = response.data;
        setNbCategories(count);
      } catch (error) {
        console.error("Erreur lors du chargement des catégories:", error);
        setNbCategories(0); // Réinitialiser en cas d'erreur
      }
    }
  }, [userId]);
  
   const {socket} = useWebSocket();

  useEffect(() => {
    if (!socket) {
      console.warn("CountCatégories: Socket non initialisé.");
      return;
    }
  
    // Charger les catégories initialement
    chargerCategories();
  
    // Gestion des événements WebSocket
    socket.on('nouveauModèle', chargerCategories);
    socket.on('updateModèle', chargerCategories);
  
    // Nettoyage lors du démontage du composant
    return () => {
      logger.log("CountCatégories: Nettoyage des écouteurs WebSocket.");
      socket.off('nouveauModèle', chargerCategories);
      socket.off('updateModèle', chargerCategories);
  
      // Ne pas fermer la connexion WebSocket si elle est partagée globalement
      // socket.disconnect();
    };
  }, [socket, chargerCategories]);

  return userId ? <span className="badge badge-info">{nbCategories}</span> : null;
}
