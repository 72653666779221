import React from "react";
import { FormField, CpField, IbanField } from "./CreateNewTiersComponents";

const CompanyInfoForm = ({ register, errors, watch }) => {
  return (
    <>
      {/* Informations générales */}
      <FormField label="Raison Sociale" type="text" name="raisonSociale" placeholder="Raison Sociale" register={register} errors={errors} />
      <FormField label="Adresse" type="text" name="adresse" placeholder="Adresse" register={register} errors={errors} />
      <CpField register={register} errors={errors} />
      <FormField label="Ville" type="text" name="ville" placeholder="Ville" register={register} errors={errors} />
      <IbanField register={register} errors={errors} />

      {/* Champs récupérés après la recherche d'un SIREN */}
      <FormField label="Code NAF" type="text" name="codeNaf" placeholder="Code NAF" register={register} errors={errors}  />
      <FormField label="Domaine d'activité" type="text" name="libelleNaf" placeholder="Domaine d'activité" register={register} errors={errors}  />
      <FormField label="Tranche d'effectifs" type="text" name="trancheEffectifs" placeholder="Tranche d'effectifs" register={register} errors={errors}  />
      <FormField label="Catégorie juridique" type="text" name="categorieJuridique" placeholder="Catégorie juridique" register={register} errors={errors}  />
      <FormField label="Forme juridique" type="text" name="formeJuridique" placeholder="Forme juridique" register={register} errors={errors}  />
    </>
  );
};

export default CompanyInfoForm;