import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Global.css";
import customAxios from "../Utilities/getAxiosToken";

import { AuthContext } from "../../contexts/AuthProvider";

import { Tooltip } from "react-tooltip";
import { useWebSocket } from "../../contexts/WebSocketProvider";
import useGetModeles from "../Utilities/GetModeles";

export function CountSansAttentesProjetsByModeles({AllAgences, ModeleId}) {
  const { fetchSansAttentesProjetByUserIdAndModeleId }  = useGetModeles(); 
 
  const { userId, agenceId } = useContext(AuthContext);
  const [nbModeles, setNbModeles] = useState(0);

  const fetchProjetsSansAttentes = useCallback(async () => {
    if (!userId  || !agenceId) return;
    try {
      if (AllAgences && ModeleId) {
        const url = `/api/projets/projetsSansAttentes`;
        // const modeleIds = [ModeleId].filter(Boolean);

        const payload = { modeleIds: [ModeleId], userId: userId, 
          etatProjet:"ouvert" // C'est l'état du projet qui est à vérifier pour ce userId
        };
        
        const response = await customAxios.post(url, payload);
        logger.log("CountSansAttentesProjetsByModeles: response", response.data);
        const projetsUniques = new Set(response.data.map(projet => projet.projetId));
        setNbModeles(projetsUniques.size);
      } else {
        const projets = await fetchSansAttentesProjetByUserIdAndModeleId(userId, ModeleId);
        const projetsUniques = new Set(projets.map(projet => projet.projetId));
        setNbModeles(projetsUniques.size);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNbModeles(0); // Réinitialiser en cas d'erreur
    }
  }, [userId,  agenceId, AllAgences, ModeleId]);


   const {socket} = useWebSocket();
  useEffect(() => {

    if (!socket) {
      console.warn("CountSansAttentesProjetsByModeles: Socket non initialisé.");
      return;
    }
  
    const handleProjectUpdate = () => {
      logger.log("CountSansAttentesProjetsByModeles: WebSocket - Mise à jour reçue");
      fetchProjetsSansAttentes();
    };
  
    // Écoute des événements WebSocket
    socket.on("nouveauProjet", handleProjectUpdate);
    socket.on("updateProjet", handleProjectUpdate);
  
    // Nettoyage des écouteurs WebSocket lors du démontage
    return () => {
      logger.log("CountSansAttentesProjetsByModeles: Nettoyage des écouteurs WebSocket.");
      socket.off("nouveauProjet", handleProjectUpdate);
      socket.off("updateProjet", handleProjectUpdate);
  
      // Ne pas déconnecter le socket global
      // socket.disconnect();
    };
  }, [socket, fetchProjetsSansAttentes]);

  return (
    <>
      {nbModeles > 0 && (
        <span className="badge bg-rouge" data-tooltip-id="TooltipCompteurProjetsSansAttentes"
          data-tooltip-content="Nombre de Projets sans attentes">
          {nbModeles}
        </span>
      )}
      <>
        <Tooltip id="TooltipCompteurProjetsSansAttentes" place="bottom" effect="solid" className="custom-tooltip" />
      </>
    </>
  );
}
