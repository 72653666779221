import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { useWebSocket } from "../../contexts/WebSocketProvider";


export const CountAttentesTiers = ({ typeTiers }) => {
  const [etapesEnAttente, setEtapesEnAttente] = useState([]);
  const [aReponduCount, setAReponduCount] = useState(0);
  const [enAttenteCount, setEnAttenteCount] = useState(0);
  const { userId, userTiersId } = useContext(AuthContext);
 

  const fetchAllEtapesEnAttente = useCallback(async () => {
    try {
      let attentes = [];

      if (typeTiers !== "") {


        const requestBody = {
          tiersId: userTiersId,
        };
        const { data }  = await customAxios.post(
          `/api/projets/attentesByTiersId`,
          requestBody
        );

        attentes = data;
      }

      logger.log("CountAttentesTiers: attentes", attentes);
      let filteredItemsPartenaire = attentes.filter((etape) =>
        etape.reponseAction.some(action => action === typeTiers)
      );

      logger.log("CountAttentesTiers: filteredItemsPartenaire", filteredItemsPartenaire);
      const etatCounter = {
        aRepondu: 0,
        enAttente: 0,
      };

      filteredItemsPartenaire.forEach((etape) => {
        etape.destinatairesEtape.forEach((destinataire) => {
          if (destinataire.tiers === userTiersId) {
            if (destinataire.etat === "aRépondu") {
              etatCounter.aRepondu += 1;
            } else if (destinataire.etat === "enAttente") {
              etatCounter.enAttente += 1;
            }
          }
        });
      });

      logger.log("CountAttentesTiers: Count of aRépondu:", etatCounter.aRepondu);
      logger.log("CountAttentesTiers: Count of enAttente:", etatCounter.enAttente);

      setAReponduCount(etatCounter.aRepondu);
      setEnAttenteCount(etatCounter.enAttente);
      setEtapesEnAttente(filteredItemsPartenaire || []);
    } catch (error) {
      logger.error(
        "CountAttentesTiers: Erreur lors de la récupération des étapes en attente",
        error
      );
    }
  }, [userId, typeTiers, userTiersId]);

   const {socket} = useWebSocket();


  useEffect(() => {
    // Charger les étapes en attente lors du montage
    fetchAllEtapesEnAttente();
  
    // Vérifier si le socket est valide avant d'ajouter des événements
    if (socket) {
      const handleUpdateEtape = () => {
        logger.log("CountAttentesTiers: Réception de l'événement 'updateEtape'");
        fetchAllEtapesEnAttente();
      };
  
      socket.on("updateEtape", handleUpdateEtape);
  
      // Nettoyage : supprimer l'écouteur lors du démontage
      return () => {
        logger.log("CountAttentesTiers: Suppression de l'écouteur WebSocket 'updateEtape'");
        socket.off("updateEtape", handleUpdateEtape);
      };
    }
  }, [fetchAllEtapesEnAttente, socket]);


  const getTooltipText = (count, type) => {
    const pluriel = count > 1 ? "s" : "";
    return type === "aRepondu"
      ? `${count} Document${pluriel} transmis`
      : `${count} Document${pluriel} en attente`;
  };

  return (
    <div>
      <div
        className="badge bg-aRépondu bg-gradient"
        title={getTooltipText(aReponduCount, "aRepondu")}
      >
        <span>{aReponduCount}</span>
      </div>
      <div
        className="badge bg-enAttente bg-gradient"
        title={getTooltipText(enAttenteCount, "enAttente")}
      >
        <span>{enAttenteCount}</span>
      </div>
    </div>
  );
};
