import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeTiers.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";

// On récupère les tiers d'un tiers Agence en fonction de typeTiers
// Pour La zone 4 Depuis All Agence

export function ListeTiersByTypeTiersAndTiersId({ typeTiers }) {
  const [listeTiers, setListeTiers] = useState([]);
 
  const [selectedTiers, setSelectedTiers] = useState({});
  // On prend tiers parce
  const { userId, handleTiersId, tiersId, handleSelectedMenu} =
    useContext(AuthContext);

  logger.log(
    `ListeTiersByTypeTiersAndTiersId: AXIOS DEBUT /api/tiers/byTiersIdAndTypeTiers/${tiersId}/${typeTiers}`
  );

  const handleLabelClick = (tiersIdSelectionne) => {
    logger.log(`ListeTiersByTypeTiersAndTiersId Tiers: TiersID cliqué : ${tiersIdSelectionne}`);
    handleTiersId(tiersIdSelectionne);
    // handleUtilisateurId(""); // On reset l'utilisateurId en cours
    const routes = {
      PARTENAIRE: "ADMIN PARTENAIRES",
      PRESTATAIRE: "ADMIN PRESTATAIRES",
      CLIENT: "ADMIN CLIENT",
    };
    
    if (routes[typeTiers]) {
      handleSelectedMenu(routes[typeTiers]);
    }
  };

  useEffect(() => {
    const fetchListeTiers = async () => {
      try {
        if (typeTiers && userId && tiersId) {
          // handleTiersId(""); // On reset le TiersId

          logger.log(`ListeTiersByTypeTiersAndTiersId:  tiersId=${tiersId}`);
          //   On récupère tous les typeTiers de ce tiersId
          const responsedutiers = await customAxios.get(
            `/api/tiers/byTiersIdAndTypeTiers/${tiersId}/${typeTiers}`
          );

          logger.log(
            "ListeTiersByTypeTiersAndTiersId responsedutiers.data.tiersAttaches",
            responsedutiers.data
          );

          if (Array.isArray(responsedutiers.data.tiersAttaches)) {
            logger.log("ListeTiersByTypeTiersAndTiersId Array.isArray");
    
            // Trier le tableau par le nom de chaque tiersAttaché
            const listeTiersTries = responsedutiers.data.tiersAttaches.sort((a, b) => {
              const nomA = a.nom.toUpperCase(); // ignore la casse
              const nomB = b.nom.toUpperCase(); // ignore la casse
              if (nomA < nomB) {
                return -1;
              }
              if (nomA > nomB) {
                return 1;
              }
              // noms égaux
              return 0;
            });
    
            setListeTiers(listeTiersTries);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchListeTiers();
  }, [ userId, typeTiers, tiersId]);


  const nbRecords = listeTiers.length;
  logger.log("ListeTiersByTypeTiersAndTiersId listeTiers", listeTiers);
  logger.log("ListeTiersByTypeTiersAndTiersId nbRecords", nbRecords);

  return (
    <div className="fenetre-liste-tiers cadre-zone2">   
      {tiersId ? (
        <div>
          {nbRecords > 0 ? (
            <ul className="liste-sans-puces">
              {listeTiers.map((monTiers, index) => (
                <li
                  key={monTiers._id}
                  style={{ listStyleType: "none" }}
                >
                  <div className="d-flex ">
          
                    <label
                      className={`form-check-label ${
                        monTiers.labelHovered ? "label-semi-gras" : ""
                      }`}
                      htmlFor="flexCheckDefault"
                      onClick={() => handleLabelClick(monTiers._id)}
                      onMouseEnter={() => {
                        setListeTiers((prevTiers) =>
                          prevTiers.map((prevTiers) =>
                            prevTiers._id === monTiers._id
                              ? { ...prevTiers, labelHovered: true }
                              : prevTiers
                          )
                        );
                      }}
                      onMouseLeave={() => {
                        setListeTiers((prevTiers) =>
                          prevTiers.map((prevTiers) =>
                            prevTiers._id === monTiers._id
                              ? { ...prevTiers, labelHovered: false }
                              : prevTiers
                          )
                        );
                      }}
                      data-tooltip-id={`TooltipTiers-${monTiers._id}`}
                      data-tooltip-content={"Cliquez pour avoir les détails"}
                    >
                      {monTiers.nom} {monTiers.prenom}
                    </label>
                    <Tooltip
                      id={`TooltipTiers-${monTiers._id}`}
                      place="bottom"
                      effect="solid"
                      className="custom-tooltip"
                    ></Tooltip>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p></p>
          )}
        </div>
      ) : (
        <p>Pas Connecté</p>
      )}
    </div>
  );
}
