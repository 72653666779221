import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { fetchEnsembleAgences } from "../Utilities/utilities";
import "./css/UtilisateurSelecteur.css";
import logger from "../Utilities/Logger";

export function UtilisateurSelecteur() {
  const { agenceId, handleLogin, utilisateurManager, utilisateurAdmin } = useContext(AuthContext);
  const [utilisateurs, setUtilisateurs] = useState([]); // Initialise à un tableau vide
  const [utilisateurSelectionne, setUtilisateurSelectionne] = useState("");

  // Créez la fonction fetchUtilisateurs en tant que callback
  const fetchUtilisateurs = useCallback(async () => {
    if (!agenceId) {
      logger.log("agenceId is undefined or null, skipping fetch");
      return;
    }
    try {
      logger.log("UtilisateurSelecteur fetchUtilisateurs agenceId", agenceId);
      const data = await fetchEnsembleAgences(agenceId);
      logger.log("UtilisateurSelecteur utilisateursData", data);
      setUtilisateurs(data.TousLesUtilisateurs || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs :", error);
    }
  }, [agenceId]);

  // Appelez fetchUtilisateurs lors du premier rendu et chaque fois que agenceId change
  useEffect(() => {
    fetchUtilisateurs();
  }, [fetchUtilisateurs, agenceId]); // Déclenche uniquement si agenceId change

  const handleChange = async (event) => {
    setUtilisateurSelectionne(event.target.value);
    logger.log("UtilisateurSelecteur handleLogin utilisateurs", utilisateurs);
    const selectedUser = utilisateurs.find(user => user.nomPrenom === event.target.value);
    if (selectedUser) {
      logger.log("UtilisateurSelecteur handleLogin selectedUser", selectedUser);
      handleLogin(
        selectedUser.utilisateurId,
        selectedUser.nom,
        selectedUser.prenom,
        selectedUser.role,
        agenceId,
        selectedUser.typeUtilisateur
      );
    }
  };

  // Affiche le selecteur uniquement si l'utilisateur est Manager ou Admin
  if (!utilisateurManager && !utilisateurAdmin) return null;

  // // Retourne null si un seul utilisateur est présent
  // if (Array.isArray(utilisateurs) && utilisateurs.length === 1) return null;  

  return (
    <select
      value={utilisateurSelectionne}
      onChange={handleChange}
      className="utilisateurSelecteur" // Ajout de la classe CSS ici
    >
      {/* Option par défaut */}
      <option value="">
        Sélectionner un utilisateur
      </option>
      {Array.isArray(utilisateurs) && utilisateurs.length > 0 ? (
        utilisateurs.map((utilisateur) => (
          <option key={utilisateur.utilisateurId} value={utilisateur.nomPrenom}>
            {utilisateur.nomPrenom}
          </option>
        ))
      ) : (
        <option>Aucun utilisateur trouvé</option>
      )}
    </select>
  );
}

