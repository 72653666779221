import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import { AuthContext } from "../../contexts/AuthProvider";
import { useWebSocket } from "../../contexts/WebSocketProvider";
import useGetModeles from "../Utilities/GetModeles";

export function CountModeles() {
  const { userId, agenceId } = useContext(AuthContext);
  const [nbModeles, setNbModeles] = useState(0);
  const { fetchModeles } = useGetModeles();

  const chargerModeles = useCallback(async () => {
    if (userId && agenceId) {
      try {
        const tousLesModelesDetailles = await fetchModeles(agenceId);
        setNbModeles(tousLesModelesDetailles.length);
      } catch (error) {
        console.error("Erreur lors du chargement des modèles:", error);
        setNbModeles(0); // Réinitialiser en cas d'erreur
      }
    }
  }, [userId, agenceId]);

      const {socket} = useWebSocket();
     useEffect(() => {
      const initialize = async () => {
        try {
          logger.log("CountModeles: Chargement initial des modèles.");
          await chargerModeles();
        } catch (error) {
          console.error("CountModeles: Erreur lors du chargement des modèles :", error);
        }
      };
    
      initialize();
    
      if (socket) {
        const handleNewModel = () => {
          logger.log("CountModeles: Événement WebSocket reçu - Nouveau modèle.");
          chargerModeles();
        };
    
        socket.on("nouveauModèle", handleNewModel);
        logger.log("CountModeles: Abonnement WebSocket configuré.");
      } else {
        logger.warn("CountModeles: Socket non initialisé.");
      }
    
      return () => {
        logger.log("CountModeles: Nettoyage des abonnements WebSocket.");
        if (socket) {
          socket.off("nouveauModèle", chargerModeles);
        }
      };
    }, [chargerModeles]);

  return userId ? <span className="badge badge-info">{nbModeles}</span> : null;
}
