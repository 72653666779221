import React, { useEffect, useState } from "react";
import customAxios from "../Utilities/getAxiosToken";

const FilePreviewModal = ({ fileId, closeModal }) => {
    const [fileBlobUrl, setFileBlobUrl] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchFile = async () => {
            setLoading(true);
            try {
                const response = await customAxios.get(`/api/files/fichier/${fileId}`, {
                    responseType: "blob",
                });
                const blobUrl = window.URL.createObjectURL(response.data);
                setFileBlobUrl(blobUrl);
            } catch (error) {
                console.error("Erreur lors de la récupération du fichier :", error);
                alert("Erreur lors de la prévisualisation du fichier.");
            } finally {
                setLoading(false);
            }
        };

        if (fileId) {
            fetchFile();
        }

        return () => {
            if (fileBlobUrl) {
                window.URL.revokeObjectURL(fileBlobUrl);
            }
        };
    }, [fileId]);

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "8px",
                    width: "80%",
                    height: "80%",
                    position: "relative",
                }}
            >
                <button
                    onClick={closeModal}
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "5px 10px",
                        cursor: "pointer",
                    }}
                >
                    Fermer
                </button>
                {loading ? (
                    <p>Chargement en cours...</p>
                ) : (
                    fileBlobUrl && (
                        <iframe
                            src={fileBlobUrl}
                            style={{ width: "100%", height: "100%", border: "none" }}
                            title="Aperçu du fichier"
                        />
                    )
                )}
            </div>
        </div>
    );
};

export default FilePreviewModal;