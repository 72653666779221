import { useState } from "react";
import logger from "./Logger";

export const useValidateSiren = (setValue, fetchCompanyInfoBySiren) => {
  const [sirenValid, setSirenValid] = useState(null);
  const [error, setError] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);

  const validateSiren = async (siren) => {
    logger.log("🔍 Validation du SIREN en cours...", siren);
    if (!siren || siren.length !== 9) {
      setSirenValid(false);
      setError("Le SIREN doit contenir exactement 9 chiffres.");
      return;
    }

    // Appel API pour vérifier le SIREN
    try {
      const response = await fetchCompanyInfoBySiren(siren, setSirenValid, setError, setCompanyInfo, setValue);
      return response;
    } catch (err) {
      console.error("Erreur lors de la validation du SIREN :", err);
      setError("Erreur lors de la validation du SIREN.");
      setSirenValid(false);
    }
  };

  return { sirenValid, setSirenValid, error, setError, companyInfo, setCompanyInfo, validateSiren };
};
