import { useState, useEffect } from "react";
import customAxios from './getAxiosToken';

export const useFetchCategories = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCategories = async () => {
    setLoading(true); // Réinitialiser à chaque nouvelle requête
    try {
      const response = await customAxios.get(`/api/categories/allcategories`);
      // logger.log("CreateNewTiers: fetchCategories response.data", response.data);
      setCategories(response.data.categories);
      setError(null); // Réinitialise l'erreur si la requête réussit
    } catch (error) {
      console.error("CreateNewTiers: Erreur lors de la récupération des catégories:", error);
      setError("Erreur lors de la récupération des catégories.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return { categories, error, loading, fetchCategories: fetchCategories };
};
