import React, { useEffect, useState, useContext, useCallback } from "react";
import customAxios from "../Utilities/getAxiosToken";
import { AuthContext } from "../../contexts/AuthProvider";
import { useWebSocket } from "../../contexts/WebSocketProvider";

// typeTiers = "AGENCE"
export function CountAdminAgences(props) {
  const [CountAdminAgences, setCountAdminAgences] = useState([]);
  const { typeTiers } = props;
 
  const { userId } = useContext(AuthContext);

  const fetchListeAdminAgences = useCallback(async () => {
    logger.log(`CountAdminAgences: DEBUT`);
    try {
      // logger.log(`CountAdminAgences:  fetchListeTiers`);
      if (typeTiers && userId) {

        const response = await customAxios.get(
          `/api/tiers/CountAdminAgences/${typeTiers}`
        );

        logger.log(
          `CountAdminAgences nb ${typeTiers}: ${response.data.nbAgences}`
        );
        setCountAdminAgences(response.data.nbAgences);
      }
      // logger.log(`CountAdminAgences: FIN `);
    } catch (error) {
      console.error(error);
    }
    // logger.log(`CountAdminAgences: FIN`);
  }, []);


   const {socket} = useWebSocket();
  useEffect(() => {
    fetchListeAdminAgences();
  
    if (socket) {
      // Définir une fonction nommée pour l'écouteur
      const handleUpdateTiers = (data) => {
        logger.log("CountAdminAgences: WebSocket 'updateTiers' reçu", data);
        fetchListeAdminAgences(); // Rafraîchir les données
      };
  
      // Ajouter l'écouteur pour l'événement 'updateTiers'
      socket.on("updateTiers", handleUpdateTiers);
  
      // Nettoyage : retirer l'écouteur lors du démontage
      return () => {
        logger.log("CountAdminAgences: Suppression de l'écouteur 'updateTiers'");
        socket.off("updateTiers", handleUpdateTiers);
      };
    }
  }, [fetchListeAdminAgences, socket]);

  return (
    <span className="badge badge-info">{CountAdminAgences}</span>
  );
}
