import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeTiers.css";
import "./../css/Global.css";
import "./../css/Modal.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  HiOutlineArchiveBoxArrowDown,
  HiOutlineArchiveBoxXMark,
} from "react-icons/hi2";
import { BsPlusCircle } from "react-icons/bs";

export function ListeTiersByUserIdAndTypeTiers({ typeTiers, titre }) {
  const [tiers, setTiers] = useState([]);

  const {
    userId,
    handleTiersId,
    handleUtilisateurId,
    searchTerm,
    handleToggleCreateNewTiers,
    isCreateNewTiersOpen,
    closeCreateNewTiers,
  } = useContext(AuthContext);
  const [isToggled, setIsToggled] = useState(true);
  const [selectedTiersId, setSelectedTiersId] = useState("");
  const [etatTiers, setEtatTiers] = useState("ouvert"); // État local pour gérer l'état des tiers, par user
  const [showModal, setShowModal] = useState(false);
  const [projetsCount, setProjetsCount] = useState(0);
  const [tiersToArchive, setTiersToArchive] = useState(null); // ID du tiers à archiver
  const [hoveredTiersId, setHoveredTiersId] = useState(""); // État pour le survol

  const handleLabelClick = async (tiersId, e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Définir l'ID du tiers sélectionné
      handleTiersId(tiersId);
      handleUtilisateurId(""); // Réinitialiser l'utilisateur si nécessaire
      setSelectedTiersId(tiersId);

      logger.log(
        `ListeTiersByUserIdAndTypeTiers: Tiers sélectionné : ${tiersId}`
      );
    } catch (error) {
      console.error(
        "ListeTiersByUserIdAndTypeTiers: Erreur lors du clic sur le label :",
        error
      );
    }
  };

  useEffect(() => {
    console.log("ListeTiersByUserIdAndTypeTiers: Modification de l'état isCreateNewTiersOpen :", isCreateNewTiersOpen);
  }, [isCreateNewTiersOpen]);
  
  const handleToggleSelection = (tiersId) => {
    if (selectedTiersId === tiersId) {
      // Désélectionner si déjà sélectionné
      setSelectedTiersId("");
      handleTiersId(null);
    } else {
      // Sélectionner le tiers
      setSelectedTiersId(tiersId);
      handleTiersId(tiersId);
    }
  };

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
    const nouvelEtat = isToggled ? "archivé" : "ouvert";
    setEtatTiers(nouvelEtat);
    logger.log(
      `ListeTiersByUserIdAndTypeTiers: Changement d'état : ${nouvelEtat}`
    );
  };

  const handleShowModal = (count) => {
    setProjetsCount(count);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTiersToArchive(null); // Réinitialiser l'ID si l'utilisateur annule
  };

  const handleConfirmArchive = async () => {
    if (!tiersToArchive) {
      console.error("Aucun tiers sélectionné pour la mise à jour de l'état.");
      return;
    }

    setShowModal(false);

    try {
      // Déterminer le nouvel état en fonction de l'état actuel du tiers
      const leTiers = tiers.find((tiers) => tiers._id === tiersToArchive);
      const accesTiers = leTiers.accesTiers.find(
        (acces) => acces.utilisateur === userId
      );

      if (!accesTiers) {
        console.error(
          "ListeTiersByUserIdAndTypeTiers: Accès tiers pour l'utilisateur introuvable."
        );
        return;
      }

      logger.log(
        `ListeTiersByUserIdAndTypeTiers: handleConfirmArchive accesTiers.etat (${accesTiers.etat})`
      );

      const ancienEtat = accesTiers.etat === "ouvert" ? "archivé" : "ouvert";
      // Récupérer tous les projets liés au tiers
      const tousLesProjets = await fetchListeProjets(
        tiersToArchive,
        ancienEtat
      );

      const nouvelEtat = accesTiers.etat;

      for (const projet of tousLesProjets) {
        // Mise à jour de l'état du projet
        logger.log(
          `ListeTiersByUserIdAndTypeTiers: handleConfirmArchive mise à jour de  (${projet._id} ${nouvelEtat} ${userId})`,
          tousLesProjets
        );
        const responseProjet = await customAxios.put(
          `/api/projets/${projet._id}`,
          { etatAccessProjet: nouvelEtat, userId: userId }
        );

        logger.log(
          `ListeTiersByUserIdAndTypeTiers: handleConfirmArchive mise à jour projet ${projet._id}`,
          responseProjet.data
        );

        if (nouvelEtat === "archivé") {
          // Archiver les étapes du projet
          const responseEtapes = await customAxios.patch(
            `/api/projets/projet/${projet._id}/archiverEtapes`
          );
          logger.log(
            `ListeTiersByUserIdAndTypeTiers: handleConfirmArchive étapes archivées projet ${projet._id}`,
            responseEtapes.data
          );
        }
      }

      // Mettre à jour l'état du tiers dans la liste locale
      setTiers((prevTiers) =>
        prevTiers.map((tiers) =>
          tiers._id === tiersToArchive
            ? {
                ...tiers,
                accesTiers: tiers.accesTiers.map((acces) =>
                  acces.utilisateur === userId
                    ? {
                        ...acces,
                        etat: nouvelEtat,
                        dateArchivage:
                          nouvelEtat === "archivé"
                            ? new Date().toISOString()
                            : null,
                      }
                    : acces
                ),
              }
            : tiers
        )
      );

      logger.log(
        `ListeTiersByUserIdAndTypeTiers: handleConfirmArchive Le tiers ${tiersToArchive} a été mis à jour.`
      );
      setTiersToArchive(null);
    } catch (error) {
      console.error(
        "ListeTiersByUserIdAndTypeTiers: handleConfirmArchive Erreur lors de la mise à jour des projets :",
        error.response || error.message
      );
    }
  };

  const handleCheckClick = async (tiersId) => {
    try {
      // Trouver le tiers correspondant
      const leTiers = tiers.find((tiers) => tiers._id === tiersId);

      if (!leTiers) {
        console.error(
          "ListeTiersByUserIdAndTypeTiers: handleCheckClick Tiers introuvable"
        );
        return;
      }

      // Trouver l'accès correspondant à l'utilisateur
      const accesTiers = leTiers.accesTiers.find(
        (acces) => acces.utilisateur === userId
      );

      if (!accesTiers) {
        console.error(
          "ListeTiersByUserIdAndTypeTiers: handleCheckClick Accès tiers pour l'utilisateur introuvable"
        );
        return;
      }

      // Déterminer le nouvel état
      const nouvelEtat = accesTiers.etat === "ouvert" ? "archivé" : "ouvert";

      // Mettre à jour l'état du tiers
      const response = await customAxios.put(`/api/tiers/${tiersId}`, {
        userId,
        etatAccessTiers: nouvelEtat,
      });

      logger.log(
        "ListeTiersByUserIdAndTypeTiers: handleCheckClick Mise à jour réussie :",
        response.data
      );

      if (response.data && response.data._id) {
        // Mettre à jour localement
        setTiers((prevTiers) =>
          prevTiers.map((tiers) =>
            tiers._id === tiersId ? response.data : tiers
          )
        );

        if (nouvelEtat === "archivé") {
          // Récupérer les projets associés pour les archiver
          const projetsActifs = await fetchListeProjets(tiersId, "ouvert");

          for (const projet of projetsActifs) {
            const projetResponse = await customAxios.put(
              `/api/projets/${projet._id}`,
              {
                etatAccessProjet: "archivé",
                userId: userId,
              }
            );

            logger.log(
              `ListeTiersByUserIdAndTypeTiers: Projet ${projet._id} archivé`,
              projetResponse.data
            );

            // Archiver les étapes du projet que si c'est un CLIENT
            if (typeTiers === "CLIENT") {
              const responseEtapes = await customAxios.patch(
                `/api/projets/projet/${projet._id}/archiverEtapes`
              );
              logger.log(
                `ListeTiersByUserIdAndTypeTiers: Étapes archivées projet ${projet._id}`,
                responseEtapes.data
              );
            }
          }

          console.log(
            `Tous les projets liés au tiers ${tiersId} ont été archivés.`
          );
        } else if (nouvelEtat === "ouvert") {
          // Vérifier s'il y a des projets archivés pour afficher une modale
          const projetsArchives = await fetchListeProjets(tiersId, "archivé");

          if (projetsArchives.length > 0) {
            setTiersToArchive(tiersId);
            setProjetsCount(projetsArchives.length);
            handleShowModal();
          }
        }
      } else {
        console.error(
          "ListeTiersByUserIdAndTypeTiers: La mise à jour du tiers a échoué."
        );
      }
    } catch (error) {
      console.error(
        "ListeTiersByUserIdAndTypeTiers: Erreur lors de la mise à jour du tiers :",
        error.response || error.message
      );
    }
  };

  const fetchListeProjets = useCallback(
    async (tiersId, etatProjet) => {
      try {
        const modeleIsActif = true; // Tous les modèles isActifs
        const response = await customAxios.get(
          `/api/projets/All/${tiersId}/${modeleIsActif}/${userId}/${etatProjet}`
        );

        const tousLesProjets = response.data.projects;
        logger.log(
          "ListeTiersByUserIdAndTypeTiersAndTypeTiers: fetchListeProjets",
          { tiersId, etatProjet },
          tousLesProjets
        );

        return tousLesProjets;
      } catch (error) {
        console.error("Erreur lors du fetch des projets :", error);
        return [];
      }
    },
    [userId]
  );

  const fetchTiersByUserIdAndTypeTiers = useCallback(async () => {
    try {
      const requestBodyTiers = {
        userId: userId,
        typeTiers: typeTiers,
        etat: etatTiers,
      };

      const response = await customAxios.post(
        `/api/tiers/byUserIdAndTypeTiers`,
        requestBodyTiers
      );
      setTiers(response.data.tiers);
    } catch (error) {
      console.error("Erreur lors de la récupération des tiers :", error);
    }
  }, [userId, typeTiers, etatTiers]);

  // closeCreateNewTiers();

  useEffect(() => {
    fetchTiersByUserIdAndTypeTiers();
  }, [typeTiers, userId, etatTiers, isToggled, isCreateNewTiersOpen]);

  const ToggleSwitch = () => (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isToggled}
        onChange={handleToggleChange}
      />
      <span className="switch-slider round">
        <span className="switch-state">
          {isToggled ? "Actifs" : "En Archive"}
        </span>
      </span>
    </label>
  );

  useEffect(() => {
    handleTiersId(null);
    setIsToggled(true);
    setEtatTiers("ouvert");
  }, [typeTiers]);

  const getTiersMessage = (tiers, typeTiers) => {
    const accesTiers = tiers.accesTiers.find(
      (acces) => acces.utilisateur === userId
    );

    if (!accesTiers) {
      return `Pas d'accès défini pour ce ${typeTiers.toLowerCase()}.`;
    }

    return accesTiers.etat === "ouvert" // ouvert ou archivé
      ? `Ne plus travailler avec ce ${typeTiers.toLowerCase()}.`
      : `Réactiver le ${typeTiers.toLowerCase()}.`;
  };

  const filteredListeTiers = useMemo(() => {
    const tiersFiltrésParEtat = tiers.filter((tiersDeTravail) => {
      const accesTiers = tiersDeTravail.accesTiers.find(
        (acces) => acces.utilisateur === userId
      );
      return accesTiers && accesTiers.etat === etatTiers;
    });

    logger.log(
      "ListeTiersByUserIdAndTypeTiersAndTypeTiers: Tiers filtrés :",
      tiersFiltrésParEtat
    );

    const tiersFiltrésParNom = tiersFiltrésParEtat.filter((tiersDeTravail) =>
      tiersDeTravail.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    tiersFiltrésParNom.sort((a, b) => a.nom.localeCompare(b.nom));

    return tiersFiltrésParNom;
  }, [tiers, userId, etatTiers, searchTerm]);

  const nbRecords = filteredListeTiers.length;

  return (
    <>
      <div>
        <div
          className="d-flex align-items-center w-100 p-2"
          style={{ borderBottom: "2px solid #ddd", gap: "10px" }} // ✅ Ajoute un espacement uniforme
        >
          {/* Titre (centré avec flex-grow) */}
          <h6 className="mb-0 flex-grow-1 text-start">{titre}</h6>{" "}
          {/* ✅ Remplacé `text-center` par `text-start` */}
          {/* Toggle */}
          <ToggleSwitch />
          {/* Icône + (poussée à droite) */}
          <BsPlusCircle
            size="1.3em"
            className="ms-auto me-2"
            onClick={() => handleToggleCreateNewTiers(typeTiers)}
          />
          
          {" "}
          {/* ✅ `ms-auto` pousse à droite */}
        </div>

        <div className="cadre-zone2">
          {nbRecords > 0 ? (
            <ul className="liste-sans-puces">
              {filteredListeTiers.map((monTiers, index) => (
                <li key={monTiers._id}>
                  <div className="form-check d-flex ms-3 me-2">
                    <label
                      className={`form-check-label souris-pointeur ${
                        hoveredTiersId === monTiers._id ||
                        selectedTiersId === monTiers._id
                          ? "label-semi-gras"
                          : ""
                      }`}
                      htmlFor={`flexCheckDefault${monTiers._id}`}
                      onClick={(e) => handleLabelClick(monTiers._id, e)}
                      onMouseEnter={() => setHoveredTiersId(monTiers._id)}
                      onMouseLeave={() => setHoveredTiersId("")}
                    >
                      {monTiers.nom}{" "}
                        {typeTiers === "CLIENT" &&
                          (monTiers.clientParticulier ? monTiers.prenom : <strong>Pro</strong>)}
                      
                    </label>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          {/* {monTiers.etat === "tiersClientOuvert" && typeTiers === "CLIENT"
                            ? `Ne plus travailler avec ce ${typeTiers.toLowerCase()}.`
                            : `Réactiver le ${typeTiers.toLowerCase()}.`} */}
                          {getTiersMessage(monTiers, typeTiers)}
                        </Tooltip>
                      }
                    >
                      <span>
                        {isToggled ? (
                          <HiOutlineArchiveBoxArrowDown
                            size="1.2em"
                            color="var(--bg-gris-clair)"
                            onClick={() => handleCheckClick(monTiers._id)}
                          />
                        ) : (
                          <HiOutlineArchiveBoxXMark
                            size="1.2em"
                            color="var(--bg-gris-clair)"
                            onClick={() => handleCheckClick(monTiers._id)}
                          />
                        )}
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p></p>
          )}
        </div>
      </div>

      {showModal && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <h3>Confirmation</h3>
            <p>
              Vous allez désarchiver <strong>{projetsCount}</strong>{" "}
              {projetsCount === 1 ? "projet" : "projets"}. Cette action est
              irréversible. Voulez-vous continuer ?
            </p>
            <div className="modal-actions">
              <button onClick={handleCloseModal}>Annuler</button>
              <button onClick={handleConfirmArchive}>Continuer</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
