import React from 'react';
import { BsSend, BsEye, BsCalendar2Date, BsCheckCircle } from 'react-icons/bs';
import BoutonDownLoad from '../Actions/BoutonDownLoad'; // Assurez-vous que le chemin est correct


// iconRendering
export const iconRendering = (etape) => {
 const style = { fontSize: "1.3em" };

  switch (etape.typeEtape) {
    case "ENVOYER":
      return <BsSend style={style} />;
    case "N/A":
      return <BsEye style={{ fontSize: "1.4em" }} />;
    case "FICHIER":
      return etape.destinatairesEtape.length > 0 && <BoutonDownLoad style={style} etape={etape} />;
    case "DATE":
      return <BsCalendar2Date style={{ fontSize: "1.3em"}} />;
    case "OUI/NON":
      return <BsCheckCircle style={style} />;
    default:
      return <span style={style}>{etape.typeEtape}</span>;
  }
};
