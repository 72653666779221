// ContactInfoForm.js
import React from "react";
import { FormField, EmailField, PhoneField } from "./CreateNewTiersComponents";

const ContactInfoForm = ({ register, errors}) => {
  return (
    <>
      <FormField label="Civilité" type="text" name="civilite" placeholder="Mr / Mme" register={register} errors={errors} />
      <FormField label="Nom" type="text" name="nom" placeholder="Nom" register={register} errors={errors} />
      <FormField label="Prénom" type="text" name="prenom" placeholder="Prénom" register={register} errors={errors} />
      <EmailField register={register} errors={errors} />
      <PhoneField register={register} errors={errors} />
    </>
  );
};

export default ContactInfoForm; // ✅ Vérifiez bien cette ligne