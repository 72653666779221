import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { CountSansAttentesProjetsByModeles } from "../Projets/CountSansAttentesProjetsByModeles";
import { CountProjetsByModeles } from "../Projets/CountProjetsByModeles";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { fetchEnsembleAgences } from '../Utilities/utilities';
import { Tooltip } from "react-tooltip";
import { useWebSocket } from "../../contexts/WebSocketProvider";

import { BsTrash } from "react-icons/bs";
import useGetModeles from "../Utilities/GetModeles";
import { useFetchAgences } from "../Utilities/fetchAgences"; // Corrected here
import useFetchHelpers from "../Utilities/GetModeles";
import { FaInfoCircle } from "react-icons/fa";

export function ListeModeles({
  AllAgences,
  Affichage,
  ModeGestion = "modeStandard",
}) {
  const { fetchAgences } = useFetchAgences();

  const [listeModelesDétaillés, setListeModelesDétaillés] = useState([]);
  const [checkedModeles, setCheckedModeles] = useState({});
  const [selectedModeleId, setSelectedModeleId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [listeDesTiers, setListeDesTiers] = useState([]);
  const [selectedAgenceId, setSelectedAgenceId] = useState(null);
  const [nomEnseigne, setNomEnseigne] = useState(null);
  const { fetchProjetByUserIdAndModeleId }  = useFetchHelpers(); 

  const {
    handleModeleId,
    selectedMenu,
    tiersId,
    userId,
    agenceId,
    triggerAddModele,
    handleTriggerAddAction,
  } = useContext(AuthContext);

  const { fetchModeles } = useGetModeles();
  const [showInfo, setShowInfo] = useState(false); // État pour la fenêtre d'explication
  const [modelesAvecProjets, setModelesAvecProjets] = useState([]);
 

  // Fonction pour gérer le clic sur l'icône et afficher/masquer la fenêtre d'explication
  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };

  const chargerTousLesModeles = useCallback(async (localParametreId) => {
    try {
      const tousLesModelesDetailles = await fetchModeles(localParametreId);
      logger.log(
        "ListeModeles fetchModeles tous les modèles",
        tousLesModelesDetailles
      );

      setListeModelesDétaillés(tousLesModelesDetailles);

      // Trouver et sélectionner le modèle par défaut (s'il existe)
      const modeleParDefaut = tousLesModelesDetailles.find(
        (modele) => modele.isDefault
      );
      if (modeleParDefaut) {
        setSelectedModeleId(modeleParDefaut._id); // Sélectionner le modèle par défaut
        handleModeleId(modeleParDefaut._id); // Mettre à jour le contexte si nécessaire
      }
    } catch (error) {
      console.error("Erreur lors du chargement des modèles:", error);
    }
  }, []); // Ajoutez toutes les dépendances nécessaires ici

  useEffect(() => {
    if (triggerAddModele) {
      logger.log("ListeModeles: useEffect triggerAddModele", triggerAddModele);

      // Réinitialisez triggerAddAction pour qu'il soit prêt pour le prochain clic
      handleTriggerAddAction(false);
    }
  }, [triggerAddModele, tiersId]);

  const fetchAgencesCallback = useCallback(async () => {
    try {
      const uniqueTiers = await fetchAgences();
      setListeDesTiers(uniqueTiers);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des agences et administrateurs :",
        error
      );
    }
  }, []);

  const handleLabelClick = (ModeleId) => {
    logger.log("ListeModeles handleLabelClick", ModeleId);

    handleModeleId(ModeleId);
    setSelectedModeleId(ModeleId); // Mise à jour avec le dernier ID sélectionné
    setErrorMessage(""); // Effacer le message d'erreur
    setCheckedModeles((prevState) => ({
      ...prevState,
      [ModeleId]: !prevState[ModeleId], // bascule l'état coché
    }));
  };

  const handleSocketEvent = useCallback(() => {
    logger.log("ListeModeles handleSocketEvent ModeGestion", ModeGestion);
    if (ModeGestion === "modeAdministration") {
      fetchAgencesCallback();
      setSelectedAgenceId(null); // On initialise aucun modele
    } else {
      chargerTousLesModeles(agenceId);
    }
  }, [ModeGestion, chargerTousLesModeles, agenceId]);

  const chargerModelesAvecProjets = useCallback(async () => {
    if (listeModelesDétaillés.length === 0) return;

    try {
      if (AllAgences) {
        // Récupère tous les utilisateurs de l'agence
        const data = await fetchEnsembleAgences(agenceId);
        const listeUtilisateurs = data.TousLesUtilisateurs;

        // Récupère les modèles avec les projets pour chaque utilisateur de l'agence
        const modelesAvecProjetsData = await Promise.all(
          listeModelesDétaillés.map(async (modele) => {
            // Compte le nombre total de projets pour ce modèle et chaque utilisateur
            const totalProjects = await Promise.all(
              listeUtilisateurs.map(async (utilisateur) => {
                const projects = await fetchProjetByUserIdAndModeleId(utilisateur.utilisateurId, modele._id);
                return projects.length;
              })
            );

            // Vérifie si le modèle a au moins un projet
            const hasProjects = totalProjects.some(count => count > 0);
            return hasProjects ? modele : null;
          })
        );

        // Met à jour les modèles ayant des projets
        setModelesAvecProjets(modelesAvecProjetsData.filter(Boolean));

      } else {
        // Récupère les modèles avec les projets pour l'utilisateur en cours
        const modelesAvecProjetsData = await Promise.all(
          listeModelesDétaillés.map(async (modele) => {
            const projectCount = await fetchProjetByUserIdAndModeleId(userId, modele._id);
            return projectCount.length > 0 ? modele : null;
          })
        );
        
        logger.log("ListeModeles chargerModelesAvecProjets modelesAvecProjetsData", modelesAvecProjetsData);
        setModelesAvecProjets(modelesAvecProjetsData.filter(Boolean));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des projets par modèle:", error);
    }
}, [listeModelesDétaillés, AllAgences, agenceId, userId]);



useEffect(() => {
  if (Affichage === "Léger" ) {
    chargerModelesAvecProjets();
  }
}, [Affichage, chargerModelesAvecProjets]);


 const {socket} = useWebSocket();
useEffect(() => {
  logger.log("ListeModeles useEffect déclenché", {
    AllAgences,
    Affichage,
    ModeGestion,
    userId,
    agenceId,
    tiersId,
  });

  // Si `ModeGestion` est `modeAdministration`, agenceId est défini à null
  // if (ModeGestion === "modeAdministration") {
  //   handleAgenceId(null);
  // }

  // Déclenche la gestion de l'événement initial
  handleSocketEvent();

  if (!socket) {
    logger.warn("ListeModeles: Socket non initialisé.");
    return;
  }

  // Abonnement à l'événement WebSocket
  socket.on("updateModèle", handleSocketEvent);

  // Nettoyage des abonnements WebSocket
  return () => {
    logger.log("ListeModeles: Nettoyage des abonnements WebSocket.");
    socket.off("updateModèle", handleSocketEvent);
  };
}, [
  userId,
  Affichage,
  AllAgences,
  tiersId,
  ModeGestion,
  agenceId,
  handleSocketEvent,
]);

  const handleAgenceChange = (event) => {
    const selectedAgenceId = event.target.value;
    setSelectedAgenceId(selectedAgenceId);

    if (selectedAgenceId) {
      logger.log(
        "ListeModeles handleAgenceChange: selectedAgenceId",
        selectedAgenceId
      );
      chargerTousLesModeles(selectedAgenceId);
    }
  };

  const handleModelChange = async (selectedModelId, type) => {
    try {
      const updatedModels = listeModelesDétaillés.map((modele) => {
        if (modele._id === selectedModelId) {
          // Si le modèle est le modèle sélectionné, mettez à jour isDefault ou isCheck basé sur le type
          return {
            ...modele,
            isDefault: type === "isDefault" ? true : modele.isDefault, // Change uniquement si le type est 'radio'
            isCheck: type === "isCheck" ? !modele.isCheck : modele.isCheck, // Bascule si le type est 'checkbox'
            isActif: type === "isActif" ? !modele.isActif : modele.isActif, // Bascule si le type est 'checkbox'
            afficheDestinataires:
              type === "AfficheDestinataires"
                ? !modele.afficheDestinataires
                : modele.afficheDestinataires,
          };
        } else if (type === "isDefault" && modele.isDefault) {
          // Pour les boutons radio, assurez-vous qu'un seul modèle est marqué comme par défaut
          return {
            ...modele,
            isDefault: false,
          };
        } else {
          return modele;
        }
      });

      logger.log(
        "handleModelChange: updatedModels",
        updatedModels,
        selectedModelId
      );
      setListeModelesDétaillés(updatedModels);

      // Créez un tableau de promesses pour les requêtes PATCH, envoyant uniquement les données pertinentes
      const patchPromises = updatedModels.map((modele) => {
        const updateData = {
          ...(type === "isDefault" && { isDefault: modele.isDefault }),
          ...(type === "isCheck" && { isCheck: modele.isCheck }),
          ...(type === "isActif" && { isActif: modele.isActif }),
          ...(type === "AfficheDestinataires" && {
            afficheDestinataires: modele.afficheDestinataires,
          }),
        };

        logger.log(
          "handleModelChange: updateData",
          updateData,
          selectedModelId
        );

        return customAxios.patch(
          `/api/modeles/ModifieOptionsDuModele/${modele._id}`,
          updateData
        );
      });

      const responses = await Promise.all(patchPromises);
      responses.forEach((response, index) => {
        if (response.status === 200) {
          // logger.log(`Mise à jour réussie pour le modèle ${updatedModels[index]._id}`);
        } else {
          console.error(
            `Erreur lors de la mise à jour pour le modèle ${updatedModels[index]._id}`
          );
        }
      });
    } catch (error) {
      console.error(error);
      alert("Une erreur est survenue lors de la mise à jour sur le serveur.");
    }
  };

  const handleDeleteModele = async (modeleId) => {
    try {
      const response = await customAxios.post(`/api/projets/ByModeleId`, {
        modeleIds: [modeleId],
      });
      if (response.data.length > 0) {
        setErrorMessage(
          "Impossible de supprimer le modèle. Des projets lui sont encore attachés."
        );
        return;
      }

      logger.log(
        "ListeModeles selectedAgenceId",
        selectedAgenceId,
        "modeleId",
        modeleId
      );
      await customAxios.delete(`/api/modeles/${modeleId}`);
      setListeModelesDétaillés((prevModeles) =>
        prevModeles.filter((modele) => modele._id !== modeleId)
      );

      if (selectedAgenceId) {
        await customAxios.patch(
          `/api/tiers/${selectedAgenceId}/delete-modele`,
          { modeleId }
        );
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du modèle:", error);
    }
  };

  // Fonction pour rendre l'en-tête du tableau
  const renderTableHeader = () => {
    return (
      <>
        <th></th>
        {Affichage === "Complet" && (
          <>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Nom du contrat`}
              data-tooltip-content={"Type de contrat."}
              data-tooltip-place="bottom"
            >
              CONTRATS
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Cible du Projet`}
              data-tooltip-content={"A qui s'adresse ce projet?"}
              data-tooltip-place="bottom"
            >
              CIBLE
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Modèle par défaut`}
              data-tooltip-content={
                "Détermine le modèle par défaut, à la création d'un nouveau projet et dans la liste des synthèses."
              }
              data-tooltip-place="bottom"
            >
              DÉFAUT
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Modèle actif`}
              data-tooltip-content={"Détermine si le modèle est isActif ou pas pour cette agence. Si oui, les projets sont visibles."}
              data-tooltip-place="bottom"
            >
              ACTIF
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`A vérifier`}
              data-tooltip-content={
                "Vérifie si les projets sont à jour dans la Synthèse et DashBoard"
              }
              data-tooltip-place="bottom"
            >
              CHECK
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Destinataires`}
              data-tooltip-content={
                "Affiche les destinataires dans les attentes et requêtes."
              }
              data-tooltip-place="bottom"
            >
              DESTINATAIRES
            </th>
            <th
              style={{ textAlign: "left", fontWeight: 600 }}
              data-tooltip-id={`Supprimer modèle`}
              data-tooltip-content={"Supprimer ce modèle"}
              data-tooltip-place="bottom"
            >
              SUPPRIMER
            </th>
          </>
        )}
      </>
    );
  };

  const renderTableBody = () => {

    const modelesAAfficher = Affichage === "Léger" ? modelesAvecProjets : listeModelesDétaillés;

    return modelesAAfficher.map((modele, index) => (

    // return listeModelesDétaillés.map((modele, index) => (
      <tr key={modele._id} className={index % 2 === 0 ? "bg-custom" : ""}>
        <td
          className="ps-1"
          style={{
            width: "1%",
            borderRadius: "6px 0 0 6px",
            paddingLeft: "5px",
            cursor: "pointer",
          }}
        >
          <input
            className="checked-item me-2"
            type="checkbox"
            id={`flexCheckDefault${modele._id}`}
            checked={modele._id === selectedModeleId}
            onChange={() => handleLabelClick(modele._id)}
          />
        </td>
        <td>
          <label
            className={`${
              modele._id === selectedModeleId ? "label-semi-gras" : ""
            }`}
            htmlFor={`flexCheckDefault${modele._id}`}
            style={{ cursor: "pointer" }}
            onClick={() => handleLabelClick(modele._id)}
          >
            <span style={{ marginLeft: "5px" }}>{modele.nom}</span>
            <span style={{ marginLeft: "6px" }}>
              <CountProjetsByModeles
                AllAgences={AllAgences}
                ModeleId={modele._id}
              />
              {Affichage === "Léger" && (
                <>
                  <CountSansAttentesProjetsByModeles
                    AllAgences={AllAgences}
                    ModeleId={modele._id}
                  />
                </>
              )}

              {(!modele.etapes || modele.etapes.length === 0) && (
                <>
                  <span
                    style={{
                      color: "green",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                    data-tooltip-id={`noStep-${modele._id}`}
                    title="Aucune étape rattachée"
                  >
                    ▲
                  </span>
                  <Tooltip
                    id={`noStep-${modele._id}`}
                    place="bottom"
                    effect="solid"
                    className="custom-tooltip"
                  >
                    Aucune étape rattachée
                  </Tooltip>
                </>
              )}
            </span>
          </label>
        </td>
        {Affichage === "Complet" && (
          <>
            <td>
              <label>{modele.cible}</label>
            </td>
            <td style={{ textAlign: "center" }}>
              <input
                type="radio"
                name="isDefault"
                id={`radioDefault${modele._id}`}
                checked={modele.isDefault}
                onChange={() => handleModelChange(modele._id, "isDefault")}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                name="isActif"
                id={`checkboxIsActif${modele._id}`}
                checked={modele.isActif}
                onChange={() => handleModelChange(modele._id, "isActif")}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                name="isCheck"
                id={`checkboxCheck${modele._id}`}
                checked={modele.isCheck}
                onChange={() => handleModelChange(modele._id, "isCheck")}
              />
            </td>
            <td
              style={{
                textAlign: "center",
                width: "1%",
                borderRadius: "0 6px 6px 0",
                paddingLeft: "5px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                name="afficheDestinataires"
                id={`checkboxDestinataires${modele._id}`}
                checked={modele.afficheDestinataires}
                onChange={() =>
                  handleModelChange(modele._id, "AfficheDestinataires")
                }
              />
            </td>
            <td style={{ textAlign: "center" }}>
              {/* On peut supprimer ce modèle que si aucun projet n'est rattaché.
              Affihage en gris si pas possible sinon en rouge
              */}
              <BsTrash
                style={{ color: "grey", cursor: "pointer" }}
                onClick={() => handleDeleteModele(modele._id)}
                data-tooltip-id={`delete-${modele._id}`}
                title="Supprimer ce modèle"
              />
              <Tooltip
                id={`delete-${modele._id}`}
                place="bottom"
                effect="solid"
                className="custom-tooltip"
              >
                Supprimer ce modèle
              </Tooltip>

         
            </td>
          </>
        )}
      </tr>
    ));
  };

  const renderContent = () => {
    if (!userId) return <p>Non connecté</p>;

    return (
      <>
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
        <table>
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>

        {Affichage === "Complet" && (
          <>
            <Tooltip
              id="Nom du contrat"
              place="bottom"
              effect="solid"
              className="custom-tooltip"
            />
            <Tooltip
              id="Cible du Projet"
              place="bottom"
              effect="solid"
              className="custom-tooltip"
            />
            <Tooltip
              id="Modèle par défaut"
              place="bottom"
              effect="solid"
              className="custom-tooltip"
            />
            
            <Tooltip
              id="Modèle actif"
              place="bottom"
              effect="solid"
              className="custom-tooltip"
            />
            <Tooltip
              id="A vérifier"
              place="bottom"
              effect="solid"
              className="custom-tooltip"
            />
            <Tooltip
              id="Destinataires"
              place="bottom"
              effect="solid"
              className="custom-tooltip"
            />
          </>
        )}
      </>
    );
  };

  const renderInfoWindow = () => {
    if (!showInfo) return null;

    return (
      <div className="info-window">
        <p>
          Voici une explication détaillée sur la fonctionnalité des modèles et
          des contrats.
          <br />
          Il s'agit de choisir une agence, et la liste des modèles définis pour
          cette agence apparaîtra.
          <br />
          L'agence ADMIN IAKKA répertorie tous les modèles disponibles par
          défaut lors de la création d'une agence.
          <br />
          Ces modèles sont dupliqués à la création de la nouvelle agence.
          <br />
          Pour chaque modèle, la cible détermine à quel type de population il
          est destiné (CLIENT, PARTENAIRE, etc.).
          <br />
          Le type "Check" indique que les projets de ce modèle n'apparaissent
          pas dans le tableau de bord (DashBoard).
          <br />
          Le type "Destinataire" signifie que les destinataires d'une attente ou
          d'une requête ne sont pas affichés.
          <br />
          Par exemple, si j'attends un RIB du partenaire XXXX, il n'est pas
          pertinent d'afficher ce destinataire dans la liste des attentes.
          <br />
        </p>

        <button
          className="btn btn-secondary"
          onClick={() => setShowInfo(false)}
        >
          Fermer
        </button>
      </div>
    );
  };

  return (
    <>
      {ModeGestion === "modeAdministration" && (
        <div className="form-group">
          <label htmlFor="selectAgence">
            <FaInfoCircle
              onClick={handleInfoClick}
              style={{ cursor: "pointer", marginRight: "10px" }}
            />
            Sélectionner une agence :
          </label>
          {renderInfoWindow()}
          <select
            id="selectAgence"
            className="form-control"
            onChange={handleAgenceChange}
          >
            <option value="">Sélectionner une agence</option>
            {listeDesTiers.map((agence) => (
              <option key={agence._id} value={agence._id}>
                {agence.nom}
              </option>
            ))}
          </select>
        </div>
      )}

      <h6 className="titre-zone2">
        {selectedMenu === "ADMIN MODÈLES" ? "MODÈLES " : "CONTRATS "}{" "}
        {nomEnseigne}
      </h6>

      <div className="cadre-zone2">{renderContent()}</div>
    </>
  );
}
